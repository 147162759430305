@import url(https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
@tailwind base;
@tailwind components;
@tailwind utilities;
 
 
 :root {
  --dl-size-size-large: 144px;
  --dl-size-size-small: 48px;
  --dl-color-gray-black: #252424ff;
  --dl-color-gray-white: #FFFFFF;
  --dl-size-size-medium: 96px;
  --dl-size-size-xlarge: 192px;
  --dl-size-size-xsmall: 16px;
  --dl-space-space-unit: 16px;
  --dl-color-colors-cyan: #00adb5ff;
  --dl-color-colors-grey: #b6b6b6ff;
  --dl-color-colors-pink: #da0f75ff;
  --dl-color-colors-skin: #ff8e9eff;
  --dl-size-size-xxlarge: 288px;
  --dl-size-size-maxwidth: 1400px;
  --dl-color-colors-orange: #ffab00ff;
  --dl-color-colors-purple: #9a20bdff;
  --dl-radius-radius-round: 50%;
  --dl-space-space-halfunit: 8px;
  --dl-space-space-sixunits: 96px;
  --dl-space-space-twounits: 32px;
  --dl-radius-radius-radius2: 2px;
  --dl-radius-radius-radius4: 4px;
  --dl-radius-radius-radius8: 8px;
  --dl-space-space-fiveunits: 80px;
  --dl-space-space-fourunits: 64px;
  --dl-color-colors-neongreen: #80ff44ff;
  --dl-space-space-threeunits: 48px;
  --dl-space-space-oneandhalfunits: 24px;
}

@font-face {
  font-family: 'DRAGON_HUNTER';
  src: url('/playground_assets/fonts/DRAGONHUNTER.eot');
  src: url('/playground_assets/fonts/DRAGONHUNTER.eot?#iefix') format('embedded-opentype'),
      url('/playground_assets/fonts/DRAGONHUNTER.woff2') format('woff2'),
      url('/playground_assets/fonts/DRAGONHUNTER.woff') format('woff'),
      url('/playground_assets/fonts/DRAGONHUNTER.ttf') format('truetype'),
      url('/playground_assets/fonts/DragonHunter-9Ynxj.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

.dragonFont{
  font-family: 'DRAGON_HUNTER';
  letter-spacing: 3px;
}

.dragonFont2{
  font-family: 'DRAGON_HUNTER';
  /* letter-spacing: 3px; */
}

.button {
  color: var(--dl-color-gray-black);
  display: inline-block;
  padding: 0.5rem 1rem;
  border-color: var(--dl-color-gray-black);
  border-width: 1px;
  border-radius: 4px;
  background-color: var(--dl-color-gray-white);
}
.input {
  color: var(--dl-color-gray-black);
  cursor: auto;
  padding: 0.5rem 1rem;
  border-color: var(--dl-color-gray-black);
  border-width: 1px;
  border-radius: 4px;
  background-color: var(--dl-color-gray-white);
}
.textarea {
  color: var(--dl-color-gray-black);
  cursor: auto;
  padding: 0.5rem;
  border-color: var(--dl-color-gray-black);
  border-width: 1px;
  border-radius: 4px;
  background-color: var(--dl-color-gray-white);
}
.list {
  width: 100%;
  margin: 1em 0px 1em 0px;
  display: block;
  padding: 0px 0px 0px 1.5rem;
  list-style-type: none;
  list-style-position: outside;
}
.list-item {
  display: list-item;
}
.teleport-show {
  display: flex !important;
  transform: none !important;
}
.colortext {
  color: tranparent;
  -webkit-background-clip: text;
}
.cursor_pointer {
  cursor: pointer;
}
.HeaderOnHoverTextColorful {
  transition: 0.3s ease-in;
}
.HeaderOnHoverTextColorful:hover {
  color: transparent;
  transition: 0.3s ease-out;
  background-image: url('https://media.tenor.com/lveDtTE8Ek8AAAAM/rainbow-swirls-relaxing.gif');
  -webkit-background-clip: text;
}
.HeaderOnHoverTextColorful:active {
  color: transparent;
  background-image: url('https://media.tenor.com/lveDtTE8Ek8AAAAM/rainbow-swirls-relaxing.gif');
  -webkit-background-clip: text;
}
.FadeInUp {
  animation-name: fadeInUp;
  animation-duration: 1s;
  animation-fill-mode: both;
  -webkit-animation-name: fadeInUp;
  -webkit-animation-duration: 1s;
  -webkit-animation-fill-mode: both;
}
.on-hover-pink{
  color: #da0f75ff;
}
.fadeInRight{
  animation-name: fadeInRight;
  animation-duration: 0.3s;
  animation-fill-mode: both;
  -webkit-animation-name: fadeInRight;
  -webkit-animation-duration: 0.3s;
  -webkit-animation-fill-mode: both;
}
.fadeInLeft{
  animation-name: fadeInLeft;
  animation-duration: 0.3s;
  animation-fill-mode: both;
  -webkit-animation-name: fadeInLeft;
  -webkit-animation-duration: 0.3s;
  -webkit-animation-fill-mode: both;
}
.social {
  width: 24px;
  cursor: pointer;
  height: 24px;
  object-fit: cover;
  transition: 0.3s;
  text-decoration: none;
}
.social:hover {
  opacity: 0.5;
}
.footer-header {
  color: rgb(255, 255, 255);
  width: 100%;
  font-size: 20px;
  font-style: normal;
  font-family: Poppins;
  font-weight: 500;
  line-height: 30px;
}
.footer-link {
  color: rgb(204, 204, 204);
  font-size: 14px;
  font-family: Poppins;
  line-height: 21px;
}
.feature {
  grid-gap: var(--dl-space-space-halfunit);
  gap: var(--dl-space-space-halfunit);
  flex: 1 1;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  padding-bottom: var(--dl-space-space-oneandhalfunits);
  justify-content: center;
}
.feature-active {
  border-color: #80FF44;
  border-bottom-width: 4px;
}
.feature-active2{
  border-color: #80FF44;
  border-bottom-width: 4px;
}
.feature-active3{
  border-color: #80FF44;
  border-bottom-width: 4px;
}
.colortexter {
  color: transparent;
  background-size: cover;
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-image: url('https://media.tenor.com/lveDtTE8Ek8AAAAM/rainbow-swirls-relaxing.gif');
  -webkit-background-clip: text;
}
.section-head {
  color: rgb(128, 255, 68);
  font-style: normal;
  font-family: Poppins;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 2px;
  text-transform: uppercase;
}
.section-heading h2 {
  color: rgb(255, 255, 255);
  font-size: 40px;
  font-style: normal;
  font-family: Poppins;
  font-weight: 600;
  line-height: 48px;
}
.section-description {
  color: rgb(204, 204, 204);
  width: 100%;
  max-width: 600px;
  font-family: Poppins;
  line-height: 28px;
}
.section-heading1 {
  color: rgb(255, 255, 255);
  font-size: 40px;
  font-style: normal;
  font-family: Poppins;
  font-weight: 600;
  line-height: 48px;
}
.side {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  -webkit-backdrop-filter: blur(130px);
          backdrop-filter: blur(130px);
}
.outliner {
  color: transparent;
  -webkit-text-stroke: 2px #DA0F75;
}
.outlineColorChanger {
  animation: outline-color 10s linear 1s infinite;
}

.shadowcolorchanger_hover:hover {
  animation: shadow-color 10s infinite linear;
}
.shadowcolorchanger {
  animation: shadow-color 10s infinite linear;
}
.border-image-idle {
  border-image: url(https://media.tenor.com/lveDtTE8Ek8AAAAM/rainbow-swirls-relaxing.gif) 1 stretch;
}
.white-shadow {
  text-shadow: 2px 2px white;
}
.color-card-hover {
  transition: 0.3s ease-out;
}
.color-card-hover:hover {
  transition: 0.3s ease-in;
  background-color: rgba(0, 0, 0, 0.3);
}
.green-outliner {
  color: transparent;
  -webkit-text-stroke: 2px #80FF44;
}
.pink-outliner {
  color: transparent;
  -webkit-text-stroke: 2px #DA0F75;
}
.cyan-outliner {
  color: transparent !important;
  -webkit-text-stroke: 2px #00ADB5 !important;
}
.orange-outliner {
  color: transparent;
  -webkit-text-stroke: 2px #FFAB00;
}
.cursor-default {
  cursor: default;
}
.imagezoomer {
  transition: 0.2s ease-in;
}
.imagezoomer:hover {
  transform: scale(1.2);
  transition: 0.2s ease-out;
}
.border-image {
  border-image: url(https://media.tenor.com/lveDtTE8Ek8AAAAM/rainbow-swirls-relaxing.gif) 1 stretch;
}
.Heading {
  font-size: 42px;
  font-family: Poppins;
  font-weight: 700;
  text-transform: none;
  text-decoration: none;
}
.Content {
  font-size: 20px;
  font-family: Poppins;
  font-weight: 300;
  text-transform: none;
  text-decoration: none;
}
.H2 {
  font-size: 40px;
  font-family: Poppins;
  font-weight: 600;
  text-transform: none;
  text-decoration: none;
}

.rotateLeft{
  rotate: 4deg;
}
.rotateRight{
  rotate: -4deg;
}
.littleDown{
  margin-top: 30px;
}


.hideMax{
  display: none !important;
}
.showMax{
  display: initial !important;
}
@media(max-width: 992px){
.hide992{
  display: none !important;
}
.show992{
  display: initial !important;
}
}
@media(max-width: 768px){
  .topNotch{
    font-size: 30px;
  }
  .hide768{
    display: none !important;
  }
  .show768{
    display: initial !important;
  }
  .rotateLeft{
    rotate: 0deg;
  }
  .rotateRight{
    rotate: 0deg;
  }
  .littleDown{
    margin-top: 0px;
  }
}
/* style setter ============================ */
@media(max-width: 479px) {
  .h2er{
    font-size: 24px !important;
  }
  .texter{
    font-size: 16px !important;
  }
  .h2erc{
    font-size: 22px !important;
  }
  .h1er{
    font-size: 50px !important;
  }
  .px30{
    font-size: 30px !important;
  }
  .px35{
    font-size: 35px !important;
  }
  .pc40{
    font-size: 40px !important;
  }
  .gtw{
    color: white !important;
  }
  .wtg{
    color: #80FF44 !important;
  }
  .px28{
    font-size: 24px !important;
  }
  .px15{
    font-size: 15px;
  }
}

.doh{
  display: flex;
  flex-direction: column;
  position: absolute;
  width: auto;
  height: auto;
  top: 35px;
  left: 10px;
  background-color: black;
  padding: 10px;
  border-radius: 10px;
  align-items: flex-start;
}

.li_elements {
  display: none;
  width: 100%;
  height: 100px;
}

.header-container {
  width: 100%;
  height: 100px;
  display: flex;
  padding: var(--dl-space-space-halfunit);
  z-index: 100;
  position: absolute;
  max-width: 100%;
  align-items: center;
  border-radius: 0px;
  flex-direction: row;
  justify-content: center;
  border-bottom-left-radius: var(--dl-radius-radius-radius8);
  border-bottom-right-radius: var(--dl-radius-radius-radius8);
  /* position: fixed;
  background-color: #292929; */
}

.header-container1 {
  width: 100%;
  height: 100%;
  display: flex;
  padding: var(--dl-space-space-halfunit);
  max-width: 1600px;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}

.header-navlink {
  display: contents;
}

.header-image {
  width: 277px;
  height: 100%;
  object-fit: cover;
  text-decoration: none;
}

.header-nav-container {
  width: 1240px;
  height: 100%;
  display: flex;
  padding: var(--dl-space-space-halfunit);
  align-items: flex-end;
  flex-direction: column;
  justify-content: center;
  font-weight: 100 !important;
}

.header-nav {
  grid-gap: var(--dl-space-space-fourunits);
  gap: var(--dl-space-space-fourunits);
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-items: center;
  padding-left: var(--dl-space-space-unit);
  padding-right: var(--dl-space-space-unit);
  justify-content: flex-end;
  font-weight: 100 !important;
}

.header-navlink01 {
  font-size: 18px;
  font-style: normal;
  text-align: center;
  transition: 0.3s;
  font-weight: 600;
  text-decoration: none;
  font-weight: 100 !important;

  
}

.header-navlink02 {
  font-size: 18px;
  font-style: normal;
  text-align: center;
  font-weight: 600;
  font-weight: 100 !important;
  text-decoration: none;
}

.header-navlink03 {
  font-size: 18px;
  font-style: normal;
  text-align: center;
  font-weight: 600;
  text-decoration: none;
  font-weight: 100 !important;

}

.header-navlink04 {
  font-size: 18px;
  font-style: normal;
  text-align: center;
  font-weight: 600;
  text-decoration: none;
  font-weight: 100 !important;

}

.header-navlink05 {
  font-size: 18px;
  font-style: normal;
  text-align: center;
  font-weight: 600;
  text-decoration: none;
  font-weight: 100 !important;

}

.header-text {
  font-size: 18px;
  font-style: normal;
  text-align: center;
  font-weight: 600;
  text-decoration: none;
  font-weight: 100 !important;

}

.header-menu-icon {
  flex: 0 0 auto;
  width: 200px;
  /* border: 4px solid transparent; */
  display: none;
  align-items: flex-start;
  flex-direction: column;
}

.header-thq-dropdown {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}

.header-dropdown-toggle {
  fill: #595959;
  color: #595959;
  width: 100%;
  display: inline-flex;
  align-items: center;
  padding-top: var(--dl-space-space-halfunit);
  padding-left: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-radius2);
  padding-right: var(--dl-space-space-unit);
  padding-bottom: var(--dl-space-space-halfunit);
}

.header-icon {
  width: 24px;
  height: 24px;
}

.header-dropdown-list {
  left: 0%;
  width: -webkit-max-content;
  width: max-content;
  display: none;
  z-index: 100;
  position: absolute;
  min-width: 100%;
  transition: 0.3s;
  align-items: stretch;
  border-color: #D9D9D9;
  border-width: 1px;
  border-radius: var(--dl-radius-radius-radius4);
  flex-direction: column;
  list-style-type: none;
  background-color: var(--dl-color-gray-white);
  list-style-position: inside;
}

.header-dropdown {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
  text-decoration: none;
}

.header-dropdown-toggle1 {
  fill: #595959;
  color: #595959;
  width: 100%;
  display: inline-flex;
  transition: 0.3s;
  align-items: center;
  padding-top: var(--dl-space-space-halfunit);
  padding-left: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-radius4);
  padding-right: var(--dl-space-space-unit);
  padding-bottom: var(--dl-space-space-halfunit);
}

.header-dropdown-toggle1:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}

.header-text1 {
  width: 100%;
  cursor: pointer;
  display: flex;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}

.header-dropdown1 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
  text-decoration: none;
}

.header-dropdown-toggle2 {
  fill: #595959;
  color: #595959;
  width: 100%;
  display: inline-flex;
  transition: 0.3s;
  align-items: center;
  padding-top: var(--dl-space-space-halfunit);
  padding-left: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-radius4);
  padding-right: var(--dl-space-space-unit);
  padding-bottom: var(--dl-space-space-halfunit);
}

.header-dropdown-toggle2:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}

.header-text2 {
  width: 100%;
  cursor: pointer;
  display: flex;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}

.header-dropdown2 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
  text-decoration: none;
}

.header-dropdown-toggle3 {
  fill: #595959;
  color: #595959;
  width: 100%;
  display: inline-flex;
  transition: 0.3s;
  align-items: center;
  padding-top: var(--dl-space-space-halfunit);
  padding-left: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-radius4);
  padding-right: var(--dl-space-space-unit);
  padding-bottom: var(--dl-space-space-halfunit);
}

.header-dropdown-toggle3:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}

.header-text3 {
  width: 100%;
  cursor: pointer;
  display: flex;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}

.header-dropdown3 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
  text-decoration: none;
}

.header-dropdown-toggle4 {
  fill: #595959;
  color: #595959;
  width: 100%;
  display: inline-flex;
  transition: 0.3s;
  align-items: center;
  padding-top: var(--dl-space-space-halfunit);
  padding-left: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-radius4);
  padding-right: var(--dl-space-space-unit);
  padding-bottom: var(--dl-space-space-halfunit);
}

.header-dropdown-toggle4:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}

.header-text4 {
  width: 100%;
  cursor: pointer;
  display: flex;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}

.header-dropdown4 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
  text-decoration: none;
}

.header-dropdown-toggle5 {
  fill: #595959;
  color: #595959;
  width: 100%;
  display: inline-flex;
  transition: 0.3s;
  align-items: center;
  padding-top: var(--dl-space-space-halfunit);
  padding-left: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-radius4);
  padding-right: var(--dl-space-space-unit);
  padding-bottom: var(--dl-space-space-halfunit);
}

.header-dropdown-toggle5:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}

.header-text5 {
  width: 100%;
  cursor: pointer;
  display: flex;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}

.header-dropdown5 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
  text-decoration: none;
}

.header-dropdown-toggle6 {
  fill: #595959;
  color: #595959;
  width: 100%;
  display: inline-flex;
  transition: 0.3s;
  align-items: center;
  padding-top: var(--dl-space-space-halfunit);
  padding-left: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-radius4);
  padding-right: var(--dl-space-space-unit);
  padding-bottom: var(--dl-space-space-halfunit);
}

.header-dropdown-toggle6:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}

.header-text6 {
  width: 100%;
  cursor: pointer;
  display: flex;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}

.header-container2 {
  top: 0;
  left: 0;
  right: 0;
  width: 1046px;
  bottom: 0;
  height: 671px;
  margin: auto auto;
  padding: 30px;
  display: flex;
  z-index: 200;
  position: fixed;
  align-items: center;
  justify-content: center;
  /* border-color: #80ff44ff; */
  border-radius: 10px;
  /* border-style: solid; */
  /* border-width: 2px; */
  flex-direction: column;
  /* background-color: rgba(53, 54, 53, 1); */
  background-color: rgba(0, 0, 0, 0.9);
}

.header-close-icon {
  top: 22px;
  right: 18px;
  width: 50px;
  height: 50px;
  position: absolute;
}

.header-root-class-name {
  top: 0px;
  left: 0px;
  right: 0px;
  margin: auto;
  position: absolute;
  /* position: fixed;
  background-color: #292929; */
}

.header-root-class-name1 {
  top: 0px;
  left: 0px;
  position: absolute;
  /* position: fixed;
  background-color: #292929; */
}

.header-root-class-name3 {
  top: 0px;
  left: 0px;
  position: absolute;
  /* position: fixed;
  background-color: #292929; */
}

.header-root-class-name4 {
  top: 0px;
  left: 0px;
  /* position: fixed;
  background-color: #292929; */
}

.header-root-class-name5 {
  top: 0px;
  left: 0px;
  position: absolute;
  /* position: fixed;
  background-color: #292929; */
}

.header-root-class-name6 {
  top: 0px;
  left: 0px;
  position: absolute;
  /* position: fixed;
  background-color: #292929; */
}

.form-div {
  width: 70%;
  height: 70%;
  /* border: 2px solid black; */
  /* background-color: black; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  grid-gap: 20px;
  gap: 20px;
}

.form-control {
  width: 100%;
  height: 100%;
  /* background-color: black; */
  /* border: 2px solid black; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  grid-gap: 20px;
  gap: 20px;
}

.former {
  width: 100%;
  border-radius: 10px;
  color: white;
  padding: 10px;
  background-color: rgba(0, 0, 0, 0.6);
  border: 2px solid #fff;
  border-image: url(/playground_assets/rainbow-swirls.gif) 1 stretch !important;
  transition: 0.3s ease-out;
  font-weight: 600;
}

.former::placeholder {
  color: white;
}

.former:focus {
  outline: none;
}

/* .former:hover{
  background-color: rgba(127, 255, 68, 0.3);
  transition: 0.3s ease-in;
} */
.form-btn {
  color: white;
  font-weight: 600;
  /* border: 2px solid #80ff44ff; */
  border: 2px solid white;
  border-image: url(/playground_assets/rainbow-swirls.gif) 1 stretch;
  width: 20%;
  height: 8%;
  /* border-radius: 20px; */
  /* background-color: rgba(127, 255, 68, 0.6); */
  background-color: black;
  transition: 0.3s ease-in;
}
.texter {
  font-size: 20px;
  color: transparent;
  background-size: cover;
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-image: url(/playground_assets/rainbow-swirls.gif);
  -webkit-background-clip: text;
}
.error {
  color: red;
  line-height: 0.1;
  font-size: initial;
  width: 100%;
  font-weight: 600;
}
.success {
  color: green;
  line-height: 0.1;
  font-size: initial;
  width: -webkit-max-content;
  width: max-content;
  font-weight: 600;
}

.form-btn:hover{
  background-color: rgba(123, 126, 121, 0.3);
  transition: 0.3s ease-out;
}
.form-btn:visited{
  background-color: black !important;
  transition: 0.3s ease-out;
}

@media(max-width: 1400px) {
  .header-image {
    height: 66px;
  }

  .header-nav-container {
    align-items: flex-end;
  }

  .header-nav {
    grid-gap: var(--dl-space-space-twounits);
    gap: var(--dl-space-space-twounits);
  }

  .header-navlink01 {
    font-size: 16px;
  }

  .header-navlink02 {
    font-size: 16px;
  }

  .header-navlink03 {
    font-size: 16px;
  }

  .header-navlink04 {
    font-size: 16px;
  }

  .header-navlink05 {
    font-size: 16px;
  }

  .header-text {
    font-size: 16px;
  }
}

@media (max-width: 1076px) {
  .header-container2 {
    width: 100vw;
    height: 100vh;
  }
}

@media(max-width: 991px) {
  .header-image {
    height: 70px;
  }

  .header-nav {
    grid-gap: var(--dl-space-space-unit);
    gap: var(--dl-space-space-unit);
    display: none;
  }

  .header-navlink01 {
    font-size: 14px;
  }

  .header-navlink02 {
    font-size: 14px;
  }

  .header-navlink03 {
    font-size: 14px;
  }

  .header-navlink04 {
    font-size: 14px;
  }

  .header-navlink05 {
    font-size: 14px;
  }

  .header-text {
    font-size: 14px;
  }

  .header-menu-icon {
    width: auto;
    height: 70%;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: center;
  }

  .header-thq-dropdown {
    width: auto;
    height: auto;
  }

  .header-dropdown-toggle {
    color: #ffffff;
    width: auto;
    height: 50px;
    padding-top: 0px;
    padding-bottom: 0px;
  }

  .header-icon {
    fill: #ffffff;
    width: 100%;
    height: 100%;
  }

  .header-dropdown-list {
    top: 42px;
    left: -101px;
    width: auto;
    height: auto;
    border-width: 0px;
    background-color: var(--dl-color-gray-black);
  }

  .header-text1 {
    color: var(--dl-color-gray-white);
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
  }

  .header-text2 {
    color: var(--dl-color-gray-white);
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
  }

  .header-text3 {
    color: var(--dl-color-gray-white);
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
  }

  .header-text4 {
    color: var(--dl-color-gray-white);
    font-size: 14px;
  }

  .header-text5 {
    color: var(--dl-color-gray-white);
    font-size: 14px;
  }

  .header-text6 {
    color: var(--dl-color-gray-white);
    font-size: 14px;
  }
}

@media(max-width: 767px) {

  .form-div {
    width: 100%;
    height: 70%;
  }

  .header-nav {
    display: none;
  }

  .header-menu-icon {
    display: flex;
  }
}

@media(max-width: 479px) {
  .header-image {
    /* width: 263px; */
    width: 100%;
    /* height: 62px; */
    height: 100%;
  }

  .form-btn {
    height: 8%;
    width: 40%;
  }

  .header-dropdown-list {
    top: 41px;
    left: -121px;
    width: auto;
    height: auto;
  }

  .header-text1 {
    font-size: 16px;
  }

  .header-text2 {
    font-size: 16px;
  }

  .header-text3 {
    font-size: 16px;
  }

  .header-text4 {
    font-size: 16px;
  }

  .header-text5 {
    font-size: 16px;
  }

  .header-text6 {
    font-size: 16px;
  }
}
.mobile_navigation_menu {
    width: 70%;
    height: 100vh;
    /* padding: 10px; */
    /* padding-top: 40px; */
    position: fixed;
    display: flex;
    margin: auto auto;
    top: 0;
    bottom: 0;
    right: 0;
    left: 20;
    flex-direction: column;
    /* justify-content: flex-start; */
    /* align-items: center; */
    z-index: 101;
    /* background-color: #000; */
    /* background-image: url('https://media3.giphy.com/media/3o7qDPxorBbvpB1Pby/200.webp?cid=ecf05e47w2ta6yfzpz3n94tvzs0unclq0rtc9ff3rcmohtm6&rid=200.webp&ct=g'); */
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    /* text-transform: uppercase; */
}

.mob_nav_social{
    display: flex;
    flex-direction: row;
    grid-gap: 30px;
    gap: 30px;
}

.socialIcon{
    color: white;
    transition: 0.3s ease-out;
}

.socialIcon:hover{
    color: blue;
    transition: 0.3s ease-in;
}

.close_svg_wrapper {
    width: 30px;
    height: 30px;
    position: absolute;
    top: 60px;
    right: 15px;
    /* border: 2px solid white; */
}

.actual_nav_mob {
    width: 100%;
    height: 100%;
    padding: 10px;
    padding-top: 30px;
    /* border: 2px solid white; */
    /* border-image: url(https://media.tenor.com/lveDtTE8Ek8AAAAM/rainbow-swirls-relaxing.gif)       1 stretch; */
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.9);
    grid-gap: 20px;
    gap: 20px;
}
.mober-naver{
    /* border: 2px solid white; */
    padding: 20px;
    /* text-align: center; */
    display: flex;
    flex-direction: column;
    grid-gap: 20px;
    gap: 20px;
    font-size: 5vw;
    font-weight: 700;
    width: 100%;
}
@media (max-width: 767px) {
    .mober-naver{
        font-size: 6vw;
    }
}
@media (max-width: 479px) {
    .mober-naver{
        font-size: 5vw;
        font-weight: 700;
    }
}
.footer-footer {
  grid-gap: var(--dl-space-space-fiveunits);
  gap: var(--dl-space-space-fiveunits);
  flex: 1 1;
  width: 100%;
  display: flex;
  position: relative;
  align-items: center;
  padding-top: 120px;
  padding-left: var(--dl-space-space-oneandhalfunits);
  padding-right: var(--dl-space-space-oneandhalfunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-threeunits);
  justify-content: center;
  background-color: #000;
}

.footer-content {
  width: 100%;
  display: flex;
  max-width: 1600px;
  align-items: flex-start;
  flex-direction: row;
  justify-content: space-between;
}
.footer-textinput::placeholder {
  color: white;
}
.footer-main-content {
  grid-gap: var(--dl-space-space-fiveunits);
  gap: var(--dl-space-space-fiveunits);
  flex: 1 1;
  height: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}

.footer-content1 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: flex-start;
}

.footer-main {
  grid-gap: var(--dl-space-space-threeunits);
  gap: var(--dl-space-space-threeunits);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}

.footer-header {
  grid-gap: var(--dl-space-space-unit);
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}

.footer-branding {
  width: 120px;
  object-fit: cover;
}

.footer-text {
  color: rgb(255, 255, 255);
  width: 100%;
  font-size: 14px;
  max-width: 260px;
  font-family: Poppins;
  line-height: 21px;
}

.footer-socials {
  grid-gap: var(--dl-space-space-unit);
  gap: var(--dl-space-space-unit);
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: flex-start;
}

.footer-icon {
  fill: #ffffff;
  width: 24px;
  height: 24px;
  transition: 0.3s;
}

.footer-icon:hover {
  fill: var(--dl-color-colors-neongreen);
}

.footer-icon2 {
  fill: #ffffff;
  width: 24px;
  height: 24px;
  transition: 0.3s;
}

.footer-icon2:hover {
  fill: var(--dl-color-colors-neongreen);
}

.footer-icon4 {
  fill: #ffffff;
  width: 24px;
  height: 24px;
  transition: 0.3s;
}

.footer-icon4:hover {
  fill: var(--dl-color-colors-neongreen);
}

.footer-categories {
  padding-left: 75px;
  grid-gap: var(--dl-space-space-fourunits);
  gap: var(--dl-space-space-fourunits);
  flex: 1 1;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: center;
}

.footer-category {
  grid-gap: var(--dl-space-space-unit);
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}

.footer-header1 {
  grid-gap: var(--dl-space-space-unit);
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}

.footer-text01 {
  font-family: Poppins;
}

/* .footer-links {
  gap: var(--dl-space-space-unit);
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
} */
.footer-links {
  grid-gap: var(--dl-space-space-unit);
  grid-gap: var(--dl-space-space-unit);
  gap: var(--dl-space-space-unit);
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  /* Default stacked layout */
  justify-content: flex-start;
}

/* Media query for screens greater than 600px */
@media (min-width: 600px) {
  .footer-links {
    flex-direction: row;
    /* Side by side layout */
    flex-wrap: wrap;
    /* Allow wrapping of links */
  }

  .footer-links .footer-link {
    width: 40%;
    /* 4 links in one row, so each link takes up 25% of the width */
  }
}

.footer-text02 {
  font-family: Poppins;
  text-decoration: none;
}

.footer-text05 {
  font-family: Poppins;
  text-decoration: none;
}

.footer-text06 {
  font-family: Poppins;
  text-decoration: none;
}

.footer-text07 {
  font-family: Poppins;
  text-decoration: none;
}

.footer-text08 {
  font-family: Poppins;
  text-decoration: none;
}

.footer-text11 {
  font-family: Poppins;
  text-decoration: none;
}

.footer-copyright {
  grid-gap: var(--dl-space-space-fiveunits);
  gap: var(--dl-space-space-fiveunits);
  flex: 1 1;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.footer-text14 {
  color: rgb(196, 196, 196);
  width: 100%;
  font-size: 14px;
  font-family: Poppins;
  line-height: 21px;
}

.footer-subscribe {
  grid-gap: var(--dl-space-space-unit);
  gap: var(--dl-space-space-unit);
  height: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}

.footer-main1 {
  grid-gap: var(--dl-space-space-oneandhalfunits);
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}

.footer-heading {
  color: rgb(255, 255, 255);
  font-size: 20px;
  max-width: 275px;
  font-style: normal;
  text-align: left;
  font-family: Poppins;
  font-weight: 500;
  line-height: 30px;
}

.footer-input-field {
  grid-gap: var(--dl-space-space-oneandhalfunits);
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  padding: var(--dl-space-space-halfunit);
  align-items: center;
  border-radius: 50px;
  flex-direction: row;
  justify-content: center;
  background-color: #000;
  margin-left: -35px !important;
}

.footer-textinput {
  flex: 1 1;
  color: #ffffff;
  height: 24px;
  font-family: Poppins;
  line-height: 24px;
  border-width: 0px;
  padding-left: var(--dl-space-space-oneandhalfunits);
  outline-style: none;
  background-color: rgba(217, 217, 217, 0);
}

.footer-buy {
  cursor: pointer;
  display: flex;
  transition: 0.3s;
  padding-top: var(--dl-space-space-unit);
  padding-left: var(--dl-space-space-oneandhalfunits);
  border-radius: 58px;
  padding-right: var(--dl-space-space-oneandhalfunits);
  padding-bottom: var(--dl-space-space-unit);
  background-color: #80FF44;
}

.footer-buy:hover {
  background-color: var(--dl-color-colors-orange);
}

.footer-text15 {
  color: rgb(12, 16, 12);
  display: none;
  font-size: 16px;
  font-style: normal;
  font-family: Poppins;
  font-weight: 500;
  line-height: 24px;
}

.footer-text16 {
  color: rgb(12, 16, 12);
  display: flex;
  font-size: 16px;
  font-style: normal;
  font-family: Poppins;
  font-weight: 500;
  line-height: 24px;
}

.footer-notice {
  color: rgb(255, 255, 255)686868;
  font-size: 14px;
  max-width: 400px;
  font-style: normal;
  text-align: left;
  font-family: Poppins;
  font-weight: 400;
  line-height: 21px;
}
.footer-notice-red{
  color: red;
  font-size: 14px;
  max-width: 400px;
  font-style: normal;
  text-align: left;
  font-family: Poppins;
  font-weight: 400;
  line-height: 21px;
}

.footer-copyright1 {
  grid-gap: var(--dl-space-space-fiveunits);
  gap: var(--dl-space-space-fiveunits);
  flex: 1 1;
  width: 100%;
  display: none;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.footer-text19 {
  color: #C4C4C4;
  width: 100%;
  font-size: 14px;
  font-family: Poppins;
  line-height: 21px;
}






@media(max-width: 1115px) {
  .footer-content {
    grid-gap: var(--dl-space-space-threeunits);
    gap: var(--dl-space-space-threeunits);
    align-items: flex-start;
    flex-direction: column;
    justify-content: flex-start;
  }

  .footer-content1 {
    grid-gap: var(--dl-space-space-oneandhalfunits);
    gap: var(--dl-space-space-oneandhalfunits);
  }

  .footer-copyright {
    display: none;
  }

  .footer-copyright1 {
    display: flex;
  }

  .footer-text19 {
    font-family: Poppins;
  }
}

@media(max-width: 767px) {
  .footer-content1 {
    grid-gap: var(--dl-space-space-threeunits);
    gap: var(--dl-space-space-threeunits);
    flex-direction: column;
  }

  .footer-main {
    grid-gap: var(--dl-space-space-twounits);
    gap: var(--dl-space-space-twounits);
  }

  .footer-categories {
    grid-gap: var(--dl-space-space-twounits);
    gap: var(--dl-space-space-twounits);
    flex-direction: column;
  }

  .footer-text16 {
    color: rgb(12, 16, 12);
    font-size: 16px;
    font-style: normal;
    font-family: Poppins;
    font-weight: 500;
    line-height: 24px;
  }

  .footer-text19 {
    color: rgb(196, 196, 196);
    font-size: 14px;
    font-family: Poppins;
    line-height: 21px;
  }
}

@media(max-width: 1115px) {
  .footer-subscribe {
    margin-left: 30px;
  }
}

@media(max-width: 479px) {
  /* .footer-subscribe {
    display: none;
  } */

  .footer-input-field {
    width: 311px;
  }

  .footer-textinput {
    margin-left: 100px;
    flex: 1 1;
    width: 211px;
  }

  .footer-text15 {
    color: rgb(12, 16, 12);
    display: flex;
    font-size: 24px;
    font-style: normal;
    font-family: Inter;
    font-weight: 700;
    line-height: 24px;
    white-space: nowrap;
  }

  .footer-text16 {
    color: rgb(12, 16, 12);
    display: none;
    font-size: 24px;
    font-style: normal;
    font-family: Inter;
    font-weight: 700;
    line-height: 24px;
  }
}

@media(max-width: 375px) {
  .footer-input-field {
    width: 275px;
  }

  .footer-textinput {
    width: 147px;
    height: 42px;
  }
}
.game-art-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  background-color: #000000;
}

.game-art-container01 {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  background-size: contain;
  background-image: url('https://iili.io/H78Yaob.png');
  background-repeat: repeat;
  background-position: center;
  background-attachment: fixed;
}

.game-art-container02 {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: rgba(0, 0, 0, 0.65);
}

.game-art-container03 {
  flex: 0 0 auto;
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  flex-direction: column;
  background-size: cover;
  justify-content: center;
  background-image: url('https://media1.giphy.com/media/kHghhXmj13WrX1hJe0/giphy.gif?cid=ecf05e47291iccu9uwtixvbe39h4ht4sn6l8l6wb1dfpy373&rid=giphy.gif&ct=g');
  background-repeat: no-repeat;
  background-position: center;
  background-attachment: fixed;
}

.game-art-container04 {
  flex: 0 0 auto;
  width: 100%;
  height: 100%;
  display: flex;
  padding: var(--dl-space-space-unit);
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.59);
}

.game-art-container05 {
  grid-gap: var(--dl-space-space-unit);
  gap: var(--dl-space-space-unit);
  width: 82%;
  height: 100%;
  display: flex;
  align-items: left;
  flex-direction: column;
  justify-content: center;
}

.game-art-text {
  font-size: 55px;
  font-style: normal;
  font-weight: 600;
  -webkit-text-stroke: 1px #FF8E9E;
}

.game-art-text04 {
  color: rgba(255, 255, 255, 0.79);
  font-size: 22px;
}

.game-art-container06 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  padding: var(--dl-space-space-unit);
  position: relative;
  max-width: 1600px;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}

.game-art-container07 {
  grid-gap: var(--dl-space-space-unit);
  gap: var(--dl-space-space-unit);
  flex: 0 0 auto;
  width: 50%;
  height: 100%;
  display: flex;
  padding: var(--dl-space-space-unit);
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.game-art-text10 {
  width: 100%;
  height: auto;
  font-size: 55px;
  font-style: normal;
  text-align: left;
  font-weight: 900;
  text-transform: capitalize;
  background-size: cover;
  background-image: url('https://images.unsplash.com/photo-1548268770-66184a21657e?ixid=Mnw5MTMyMXwwfDF8c2VhcmNofDIwfHxza3l8ZW58MHx8fHwxNjc1MzQwNDIy&ixlib=rb-4.0.3&w=1500');
  background-repeat: no-repeat;
  background-position: center;
}
.game-art-text10 h1 {
  font-size: 55px;
  font-weight: 900;
}

.game-art-text16 {
  width: 100%;
  font-size: 22px;
  font-style: normal;
  text-align: justify;
  font-weight: 300;
}

.game-art-container08 {
  flex: 0 0 auto;
  width: 50%;
  height: 100%;
  display: flex;
  padding: var(--dl-space-space-unit);
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.game-art-container09 {
  grid-gap: var(--dl-space-space-twounits);
  gap: var(--dl-space-space-twounits);
  width: 500px;
  height: 652px;
  display: flex;
  padding: var(--dl-space-space-unit);
  align-items: center;
  border-color: var(--dl-color-gray-white);
  border-width: 1px;
  flex-direction: column;
  justify-content: center;
}

.game-art-container10 {
  grid-gap: var(--dl-space-space-unit);
  gap: var(--dl-space-space-unit);
  width: 100%;
  height: 115px;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}

.game-art-icon {
  fill: var(--dl-color-colors-neongreen);
  width: 100px;
  height: 100px;
}

.game-art-icon02 {
  fill: var(--dl-color-colors-cyan);
  width: 100px;
  height: 100px;
}

.game-art-icon04 {
  fill: var(--dl-color-colors-orange);
  width: 100px;
  height: 100px;
}

.game-art-icon06 {
  fill: var(--dl-color-colors-pink);
  width: 100px;
  height: 100px;
}

.game-art-container11 {
  width: 100%;
  height: 475px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.game-art-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-color: var(--dl-color-gray-white);
  border-width: 1px;
}

.game-art-container12 {
  grid-gap: var(--dl-space-space-twounits);
  gap: var(--dl-space-space-twounits);
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  padding: var(--dl-space-space-unit);
  position: relative;
  max-width: 1600px;
  align-items: flex-start;
  flex-direction: row;
  justify-content: center;
}

.game-art-container13 {
  grid-gap: var(--dl-space-space-unit);
  gap: var(--dl-space-space-unit);
  flex: 0 0 auto;
  width: 30%;
  height: 70%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}

.game-art-text19 {
  color: var(--dl-color-colors-cyan);
  width: 100%;
  font-size: 35px;
  font-style: normal;
  text-align: left;
  font-weight: 700;
}

.game-art-text23 {
  color: var(--dl-color-gray-white);
  width: 100%;
  font-size: 20px;
  align-self: center;
  font-style: normal;
  text-align: left;
  font-weight: 400;
}

.game-art-container14 {
  grid-gap: var(--dl-space-space-unit);
  gap: var(--dl-space-space-unit);
  flex: 0 0 auto;
  width: 30%;
  height: 70%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}

.game-art-text24 {
  color: var(--dl-color-colors-orange);
  width: 100%;
  font-size: 35px;
  font-style: normal;
  text-align: left;
  font-weight: 700;
}

.game-art-text28 {
  color: var(--dl-color-gray-white);
  width: 100%;
  font-size: 20px;
  align-self: center;
  font-style: normal;
  text-align: left;
  font-weight: 400;
}

.game-art-container15 {
  grid-gap: var(--dl-space-space-unit);
  gap: var(--dl-space-space-unit);
  flex: 0 0 auto;
  width: 30%;
  height: 70%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}

.game-art-text29 {
  color: var(--dl-color-colors-neongreen);
  width: 100%;
  font-size: 35px;
  font-style: normal;
  text-align: left;
  font-weight: 700;
}

.game-art-text33 {
  color: var(--dl-color-gray-white);
  width: 100%;
  font-size: 20px;
  align-self: center;
  font-style: normal;
  text-align: left;
  font-weight: 400;
}

.game-art-container16 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  padding: var(--dl-space-space-unit);
  position: relative;
  max-width: 1600px;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}

.game-art-container17 {
  flex: 0 0 auto;
  width: 50%;
  height: 100%;
  display: flex;
  padding: var(--dl-space-space-unit);
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.game-art-container18 {
  grid-gap: var(--dl-space-space-twounits);
  gap: var(--dl-space-space-twounits);
  width: 500px;
  height: 652px;
  display: flex;
  padding: var(--dl-space-space-unit);
  align-items: center;
  border-color: var(--dl-color-gray-white);
  border-width: 1px;
  flex-direction: column;
  justify-content: center;
}

.game-art-container19 {
  grid-gap: var(--dl-space-space-unit);
  gap: var(--dl-space-space-unit);
  width: 100%;
  height: 115px;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}

.game-art-icon08 {
  fill: var(--dl-color-colors-neongreen);
  width: 100px;
  height: 100px;
}

.game-art-icon10 {
  fill: var(--dl-color-colors-cyan);
  width: 100px;
  height: 100px;
}

.game-art-icon12 {
  fill: var(--dl-color-colors-orange);
  width: 100px;
  height: 100px;
}

.game-art-icon14 {
  fill: var(--dl-color-colors-pink);
  width: 100px;
  height: 100px;
}

.game-art-container20 {
  width: 100%;
  height: 475px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.game-art-image1 {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-color: var(--dl-color-gray-white);
  border-width: 1px;
}

.game-art-container21 {
  grid-gap: var(--dl-space-space-unit);
  gap: var(--dl-space-space-unit);
  flex: 0 0 auto;
  width: 50%;
  height: 100%;
  display: flex;
  padding: var(--dl-space-space-unit);
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.game-art-text34 {
  width: 100%;
  height: auto;
  font-size: 55px;
  font-style: normal;
  text-align: left;
  font-weight: 900;
  text-transform: capitalize;
  background-size: cover;
  background-image: url('https://images.unsplash.com/photo-1548268770-66184a21657e?ixid=Mnw5MTMyMXwwfDF8c2VhcmNofDIwfHxza3l8ZW58MHx8fHwxNjc1MzQwNDIy&ixlib=rb-4.0.3&w=1500');
  background-repeat: no-repeat;
  background-position: center;
}

.game-art-text38 {
  width: 100%;
  font-size: 22px;
  font-style: normal;
  text-align: justify;
  font-weight: 300;
}

.game-art-container22 {
  grid-gap: var(--dl-space-space-twounits);
  gap: var(--dl-space-space-twounits);
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  padding: var(--dl-space-space-unit);
  position: relative;
  max-width: 1600px;
  align-items: flex-start;
  flex-direction: row;
  justify-content: center;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-left-radius: var(--dl-radius-radius-round);
  border-bottom-right-radius: var(--dl-radius-radius-round);
}

.game-art-container23 {
  grid-gap: var(--dl-space-space-unit);
  gap: var(--dl-space-space-unit);
  flex: 0 0 auto;
  width: 30%;
  height: 70%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}

.game-art-text42 {
  color: var(--dl-color-colors-cyan);
  width: 100%;
  font-size: 35px;
  font-style: normal;
  text-align: left;
  font-weight: 700;
}

.game-art-text43 {
  color: var(--dl-color-gray-white);
  width: 100%;
  font-size: 20px;
  align-self: center;
  font-style: normal;
  text-align: left;
  font-weight: 400;
}

.game-art-container24 {
  grid-gap: var(--dl-space-space-unit);
  gap: var(--dl-space-space-unit);
  flex: 0 0 auto;
  width: 30%;
  height: 70%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}

.game-art-text44 {
  color: var(--dl-color-colors-orange);
  width: 100%;
  font-size: 35px;
  font-style: normal;
  text-align: left;
  font-weight: 700;
}

.game-art-text48 {
  color: var(--dl-color-gray-white);
  width: 100%;
  font-size: 20px;
  align-self: center;
  font-style: normal;
  text-align: left;
  font-weight: 400;
}

.game-art-container25 {
  grid-gap: var(--dl-space-space-unit);
  gap: var(--dl-space-space-unit);
  flex: 0 0 auto;
  width: 30%;
  height: 70%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}

.game-art-text49 {
  color: var(--dl-color-colors-neongreen);
  width: 100%;
  font-size: 35px;
  font-style: normal;
  text-align: left;
  font-weight: 700;
}

.game-art-text50 {
  color: var(--dl-color-gray-white);
  width: 100%;
  font-size: 20px;
  align-self: center;
  font-style: normal;
  text-align: left;
  font-weight: 400;
}

.game-art-container26 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-attachment: fixed;
}

.game-art-container27 {
  flex: 0 0 auto;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.6);
}

.game-art-container28 {
  flex: 0 0 auto;
  width: 100%;
  height: 100%;
  display: flex;
  padding: var(--dl-space-space-threeunits);
  max-width: 1600px;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.game-art-text51 {
  width: 100%;
  height: auto;
  font-size: 10vw;
  font-style: normal;
  text-align: left;
  font-weight: 900;
  text-transform: capitalize;
  background-size: cover;
  background-image: url("https://images.unsplash.com/photo-1509114397022-ed747cca3f65?ixid=Mnw5MTMyMXwwfDF8c2VhcmNofDcyfHxza3l8ZW58MHx8fHwxNjc1MzQ0OTg4&ixlib=rb-4.0.3&w=1500");
  background-repeat: no-repeat;
  background-position: center;
}

.game-art-gallery {
  width: 100%;
  height: auto;
  display: grid;
  grid-gap: var(--dl-space-space-unit);
  max-width: 1600px;
  align-items: flex-start;
  padding-top: var(--dl-space-space-twounits);
  padding-left: var(--dl-space-space-threeunits);
  padding-right: var(--dl-space-space-threeunits);
  padding-bottom: var(--dl-space-space-twounits);
  grid-template-rows: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-template-areas: "a b b" "a b b" "a c d" "a c d" "e e d" "e e f" "e e f";
  grid-template-columns: 1fr 1fr 1fr;
}

.game-art-container29 {
  flex: 0 0 auto;
  width: 100%;
  height: 100%;
  display: flex;
  opacity: 1;
  grid-area: a;
  transition: 0.3s;
  align-items: flex-start;
  border-radius: var(--dl-radius-radius-radius8);
  flex-direction: column;
  background-size: cover;
  /* zing */
  background-image: url("https://iili.io/HEnBWuf.png");
  background-repeat: no-repeat;
  background-position: center;
}

.game-art-container29:hover {
  transform: scale(0.98);
}

.game-art-container30 {
  width: 100%;
  height: 100%;
  display: flex;
  padding: var(--dl-space-space-unit);
  align-items: flex-start;
  flex-direction: column;
}

.game-art-text54 {
  color: var(--dl-color-gray-white);
  font-size: 1.5rem;
  font-weight: 600;
}

.game-art-text55 {
  color: var(--dl-color-gray-white);
  margin-top: var(--dl-space-space-halfunit);
}

.game-art-container31 {
  flex: 0 0 auto;
  width: 100%;
  height: 100%;
  display: flex;
  grid-area: b;
  transition: 0.3s;
  align-items: flex-start;
  border-radius: var(--dl-radius-radius-radius8);
  flex-direction: column;
  background-size: cover;
  /* cannon crush */
  background-image: url("https://iili.io/HEnBwn2.png");
  background-repeat: no-repeat;
  background-position: center;
}

.game-art-container31:hover {
  transform: scale(0.98);
}

.game-art-container32 {
  width: 100%;
  height: 100%;
  display: flex;
  padding: var(--dl-space-space-unit);
  align-items: flex-start;
  flex-direction: column;
}

.game-art-text57 {
  color: var(--dl-color-gray-white);
  font-size: 1.5rem;
  font-weight: 600;
}

.game-art-text58 {
  color: var(--dl-color-gray-white);
  margin-top: var(--dl-space-space-halfunit);
}

.game-art-container33 {
  flex: 0 0 auto;
  width: 100%;
  height: 100%;
  display: flex;
  grid-area: c;
  transition: 0.3s;
  align-items: flex-start;
  border-radius: var(--dl-radius-radius-radius8);
  flex-direction: column;
  background-size: cover;
  /* dashing dino */
  background-image: url("https://iili.io/HEnBEan.png");
  background-repeat: no-repeat;
  background-position: center;
}

.game-art-container33:hover {
  transform: scale(0.98);
}

.game-art-container34 {
  width: 100%;
  height: 100%;
  display: flex;
  padding: var(--dl-space-space-twounits);
  align-items: flex-start;
  flex-direction: column;
}

.game-art-text60 {
  color: var(--dl-color-gray-white);
  font-size: 1.5rem;
  font-weight: 600;
}

.game-art-text61 {
  color: var(--dl-color-gray-white);
  margin-top: var(--dl-space-space-halfunit);
}

.game-art-container35 {
  flex: 0 0 auto;
  width: 100%;
  height: 100%;
  display: flex;
  grid-area: d;
  transition: 0.3s;
  align-items: flex-start;
  border-radius: var(--dl-radius-radius-radius8);
  flex-direction: column;
  background-size: cover;
  /* piggy */
  background-image: url("https://iili.io/HEnBXj4.png");
  background-repeat: no-repeat;
  background-position: center;
}

.game-art-container35:hover {
  transform: scale(0.98);
}

.game-art-container36 {
  width: 100%;
  height: 100%;
  display: flex;
  padding: var(--dl-space-space-unit);
  align-items: flex-start;
  flex-direction: column;
}

.game-art-text63 {
  color: var(--dl-color-gray-white);
  font-size: 1.5rem;
  font-weight: 600;
}

.game-art-text64 {
  color: var(--dl-color-gray-white);
  margin-top: var(--dl-space-space-halfunit);
}

.game-art-container37 {
  flex: 0 0 auto;
  width: 100%;
  height: 100%;
  display: flex;
  grid-area: e;
  transition: 0.3s;
  align-items: flex-start;
  border-radius: var(--dl-radius-radius-radius8);
  flex-direction: column;
  background-size: cover;
  /* ludo */
  background-image: url("https://iili.io/HEnBhZl.png");
  background-repeat: no-repeat;
  background-position: center;
}

.game-art-container37:hover {
  transform: scale(0.98);
}

.game-art-container38 {
  width: 100%;
  height: 100%;
  display: flex;
  padding: var(--dl-space-space-unit);
  align-items: flex-start;
  flex-direction: column;
}

.game-art-text66 {
  color: var(--dl-color-gray-white);
  font-size: 1.5rem;
  font-weight: 600;
}

.game-art-text67 {
  color: var(--dl-color-gray-white);
  margin-top: var(--dl-space-space-halfunit);
}

.game-art-container39 {
  flex: 0 0 auto;
  width: 100%;
  height: 100%;
  display: flex;
  grid-area: f;
  transition: 0.3s;
  align-items: flex-start;
  border-radius: var(--dl-radius-radius-radius8);
  flex-direction: column;
  background-size: cover;
  /* piggy */
  background-image: url("https://iili.io/HEnBMyG.png");
  background-repeat: no-repeat;
  background-position: center;
}

.game-art-container39:hover {
  transform: scale(0.98);
}

.game-art-container40 {
  width: 100%;
  height: 100%;
  display: flex;
  padding: var(--dl-space-space-unit);
  align-items: flex-start;
  flex-direction: column;
}

.game-art-text69 {
  color: var(--dl-color-gray-white);
  font-size: 1.5rem;
  font-weight: 600;
}

.game-art-text70 {
  color: var(--dl-color-gray-white);
  margin-top: var(--dl-space-space-halfunit);
}

.game-art-container41 {
  grid-gap: var(--dl-space-space-threeunits);
  gap: var(--dl-space-space-threeunits);
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  padding: var(--dl-space-space-unit);
  position: relative;
  max-width: 1600px;
  align-items: center;
  margin-bottom: var(--dl-space-space-fourunits);
  flex-direction: column;
  justify-content: center;
}

.game-art-container42 {
  grid-gap: var(--dl-space-space-oneandhalfunits);
  gap: var(--dl-space-space-oneandhalfunits);
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}

.game-art-text72 {
  font-size: 75px;
  font-style: normal;
  text-align: left;
  font-weight: 700;
  text-transform: capitalize;
  background-size: cover;
  background-image: url("https://images.unsplash.com/photo-1507908708918-778587c9e563?ixid=Mnw5MTMyMXwwfDF8c2VhcmNofDc5fHxza3l8ZW58MHx8fHwxNjc1MzUxOTg2&ixlib=rb-4.0.3&w=1500");
  background-repeat: no-repeat;
  background-position: center;
}
.game-art-text72 h2 {
  font-size: 75px;
  font-style: normal;
}

.game-art-text76 {
  color: var(--dl-color-gray-white);
  width: 100%;
  font-size: 20px;
  align-self: center;
  font-style: normal;
  text-align: left;
  font-weight: 400;
}

.game-art-container43 {
  flex: 0 0 auto;
  height: auto;
  display: grid;
  max-width: 1600px;
  align-items: center;
  grid-row-gap: var(--dl-space-space-twounits);
  flex-direction: column;
  grid-column-gap: 110px;
  justify-content: center;
  grid-template-columns: auto auto auto auto;
}

.game-art-container44 {
  grid-gap: var(--dl-space-space-unit);
  gap: var(--dl-space-space-unit);
  flex: 0 0 auto;
  width: 300px;
  height: 50px;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
}

.game-art-container45 {
  width: 52px;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.game-art-icon16 {
  fill: var(--dl-color-colors-neongreen);
  width: 30px;
  height: 30px;
}

.game-art-text77 {
  height: auto;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
}

.game-art-container46 {
  grid-gap: var(--dl-space-space-unit);
  gap: var(--dl-space-space-unit);
  flex: 0 0 auto;
  width: 300px;
  height: 50px;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
}

.game-art-container47 {
  width: 52px;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.game-art-icon18 {
  fill: var(--dl-color-colors-cyan);
  width: 30px;
  height: 30px;
}

.game-art-text78 {
  height: auto;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
}

.game-art-container48 {
  grid-gap: var(--dl-space-space-unit);
  gap: var(--dl-space-space-unit);
  flex: 0 0 auto;
  width: 300px;
  height: 50px;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
}

.game-art-container49 {
  width: 52px;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.game-art-icon20 {
  fill: var(--dl-color-colors-orange);
  width: 30px;
  height: 30px;
}

.game-art-text79 {
  height: auto;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
}

.game-art-container50 {
  grid-gap: var(--dl-space-space-unit);
  gap: var(--dl-space-space-unit);
  flex: 0 0 auto;
  width: 300px;
  height: 50px;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
}

.game-art-container51 {
  width: 52px;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.game-art-icon22 {
  fill: var(--dl-color-colors-pink);
  width: 30px;
  height: 30px;
}

.game-art-text80 {
  height: auto;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
}

.game-art-container52 {
  grid-gap: var(--dl-space-space-unit);
  gap: var(--dl-space-space-unit);
  flex: 0 0 auto;
  width: 300px;
  height: 50px;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
}

.game-art-container53 {
  width: 52px;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.game-art-icon24 {
  fill: var(--dl-color-colors-neongreen);
  width: 30px;
  height: 30px;
}

.game-art-text81 {
  height: auto;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
}

.game-art-container54 {
  grid-gap: var(--dl-space-space-unit);
  gap: var(--dl-space-space-unit);
  flex: 0 0 auto;
  width: 300px;
  height: 50px;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
}

.game-art-container55 {
  width: 52px;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.game-art-icon26 {
  fill: var(--dl-color-colors-cyan);
  width: 30px;
  height: 30px;
}

.game-art-text82 {
  height: auto;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
}

.game-art-container56 {
  grid-gap: var(--dl-space-space-unit);
  gap: var(--dl-space-space-unit);
  flex: 0 0 auto;
  width: 300px;
  height: 50px;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
}

.game-art-container57 {
  width: 52px;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.game-art-icon28 {
  fill: var(--dl-color-colors-orange);
  width: 30px;
  height: 30px;
}

.game-art-text83 {
  height: auto;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
}

.game-art-container58 {
  grid-gap: var(--dl-space-space-unit);
  gap: var(--dl-space-space-unit);
  flex: 0 0 auto;
  width: 300px;
  height: 50px;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
}

.game-art-container59 {
  width: 52px;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.game-art-icon32 {
  fill: var(--dl-color-colors-pink);
  width: 30px;
  height: 30px;
}

.game-art-text84 {
  height: auto;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
}

.game-art-container60 {
  grid-gap: var(--dl-space-space-unit);
  gap: var(--dl-space-space-unit);
  flex: 0 0 auto;
  width: 300px;
  height: 50px;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
}

.game-art-container61 {
  width: 52px;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.game-art-icon34 {
  fill: var(--dl-color-colors-neongreen);
  width: 30px;
  height: 30px;
}

.game-art-text85 {
  height: auto;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
}

.game-art-container62 {
  grid-gap: var(--dl-space-space-unit);
  gap: var(--dl-space-space-unit);
  flex: 0 0 auto;
  width: 300px;
  height: 50px;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
}

.game-art-container63 {
  width: 52px;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.game-art-icon40 {
  fill: var(--dl-color-colors-cyan);
  width: 30px;
  height: 30px;
}

.game-art-text86 {
  height: auto;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
}

.game-art-container64 {
  grid-gap: var(--dl-space-space-unit);
  gap: var(--dl-space-space-unit);
  flex: 0 0 auto;
  width: 300px;
  height: 50px;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
}

.game-art-container65 {
  width: 52px;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.game-art-icon42 {
  fill: var(--dl-color-colors-orange);
  width: 30px;
  height: 30px;
}

.game-art-text87 {
  height: auto;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
}

.game-art-container66 {
  grid-gap: var(--dl-space-space-unit);
  gap: var(--dl-space-space-unit);
  flex: 0 0 auto;
  width: 300px;
  height: 50px;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
}

.game-art-container67 {
  width: 52px;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.game-art-icon44 {
  fill: var(--dl-color-colors-pink);
  width: 30px;
  height: 30px;
}

.game-art-text88 {
  height: auto;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
}

.game-art-container68 {
  grid-gap: var(--dl-space-space-unit);
  gap: var(--dl-space-space-unit);
  flex: 0 0 auto;
  width: 300px;
  height: 50px;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
}

.game-art-container69 {
  width: 52px;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.game-art-icon54 {
  fill: var(--dl-color-colors-neongreen);
  width: 30px;
  height: 30px;
}

.game-art-text89 {
  height: auto;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
}

.game-art-container70 {
  grid-gap: var(--dl-space-space-unit);
  gap: var(--dl-space-space-unit);
  flex: 0 0 auto;
  width: 300px;
  height: 50px;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
}

.game-art-container71 {
  width: 52px;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.game-art-icon59 {
  fill: var(--dl-color-colors-cyan);
  width: 30px;
  height: 30px;
}

.game-art-text90 {
  height: auto;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
}

.game-art-container72 {
  grid-gap: var(--dl-space-space-unit);
  gap: var(--dl-space-space-unit);
  flex: 0 0 auto;
  width: 300px;
  height: 50px;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
}

.game-art-container73 {
  width: 52px;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.game-art-icon61 {
  fill: var(--dl-color-colors-orange);
  width: 30px;
  height: 30px;
}

.game-art-text91 {
  height: auto;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
}

.game-art-container74 {
  grid-gap: var(--dl-space-space-unit);
  gap: var(--dl-space-space-unit);
  flex: 0 0 auto;
  width: 300px;
  height: 50px;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
}

.game-art-container75 {
  width: 52px;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.game-art-icon63 {
  fill: var(--dl-color-colors-pink);
  width: 30px;
  height: 30px;
}

.game-art-text92 {
  height: auto;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
}

.game-art-container76 {
  grid-gap: var(--dl-space-space-unit);
  gap: var(--dl-space-space-unit);
  flex: 0 0 auto;
  width: 300px;
  height: 50px;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
}

.game-art-container77 {
  width: 52px;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.game-art-icon65 {
  fill: var(--dl-color-colors-neongreen);
  width: 30px;
  height: 30px;
}

.game-art-text93 {
  height: auto;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
}

.game-art-container78 {
  grid-gap: var(--dl-space-space-unit);
  gap: var(--dl-space-space-unit);
  flex: 0 0 auto;
  width: 300px;
  height: 50px;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
}

.game-art-container79 {
  width: 52px;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.game-art-icon67 {
  fill: var(--dl-color-colors-cyan);
  width: 30px;
  height: 30px;
}

.game-art-text94 {
  height: auto;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
}

.game-art-container80 {
  grid-gap: var(--dl-space-space-unit);
  gap: var(--dl-space-space-unit);
  flex: 0 0 auto;
  width: 300px;
  height: 50px;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
}

.game-art-container81 {
  width: 52px;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.game-art-icon69 {
  fill: var(--dl-color-colors-orange);
  width: 30px;
  height: 30px;
}

.game-art-text95 {
  height: auto;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
}

@media(max-width: 1400px) {
  .game-art-container43 {
    grid-template-columns: auto auto auto;
  }
}

@media(max-width: 991px) {
  .game-art-container04 {
    align-items: center;
  }

  .game-art-container06 {
    height: auto;
    flex-direction: column;
  }

  .game-art-container07 {
    width: 100%;
  }

  .game-art-container08 {
    width: 100%;
  }

  .game-art-container12 {
    height: auto;
    flex-direction: column;
  }

  .game-art-container13 {
    width: 100%;
    height: 100%;
  }

  .game-art-container14 {
    width: 100%;
    height: 100%;
  }

  .game-art-container15 {
    width: 100%;
    height: 100%;
  }

  .game-art-container16 {
    height: 100%;
    flex-direction: column;
  }

  .game-art-container17 {
    width: 100%;
  }

  .game-art-container21 {
    width: 100%;
  }

  .game-art-container22 {
    height: auto;
    flex-direction: column;
  }

  .game-art-container23 {
    width: 100%;
    height: 100%;
  }

  .game-art-container24 {
    width: 100%;
    height: 100%;
  }

  .game-art-container25 {
    width: 100%;
    height: 100%;
  }

  .game-art-gallery {
    grid-gap: var(--dl-space-space-unit);
  }

  .game-art-text54 {
    text-align: center;
  }

  .game-art-text55 {
    text-align: center;
  }

  .game-art-text57 {
    text-align: center;
  }

  .game-art-text58 {
    text-align: center;
  }

  .game-art-text60 {
    text-align: center;
  }

  .game-art-text61 {
    text-align: center;
  }

  .game-art-text63 {
    text-align: center;
  }

  .game-art-text64 {
    text-align: center;
  }

  .game-art-text66 {
    text-align: center;
  }

  .game-art-text67 {
    text-align: center;
  }

  .game-art-text69 {
    text-align: center;
  }

  .game-art-text70 {
    text-align: center;
  }

  .game-art-container43 {
    grid-template-columns: auto auto;
  }
}

@media(max-width: 767px) {
  .game-art-container05 {
    width: 100%;
  }

  .game-art-text {
    font-size: 45px;
    text-align: center;
  }

  .game-art-text04 {
    display: none;
  }

  .game-art-container06 {
    padding-top: var(--dl-space-space-fiveunits);
    padding-bottom: var(--dl-space-space-fiveunits);
  }

  .game-art-text10 h1 {
    font-size: 35px;
  }

  .game-art-text16 {
    font-size: 18px;
  }

  .game-art-container08 {
    width: 100%;
  }

  .game-art-container09 {
    width: 350px;
    height: 466px;
  }

  .game-art-icon {
    width: 70px;
    height: 70px;
  }

  .game-art-icon02 {
    width: 70px;
    height: 70px;
  }

  .game-art-icon04 {
    width: 70px;
    height: 70px;
  }

  .game-art-icon06 {
    width: 70px;
    height: 70px;
  }

  .game-art-container12 {
    padding-top: var(--dl-space-space-fiveunits);
    padding-bottom: var(--dl-space-space-fiveunits);
  }

  .game-art-text23 {
    font-size: 16px;
  }

  .game-art-text28 {
    font-size: 16px;
  }

  .game-art-text33 {
    font-size: 16px;
  }

  .game-art-container16 {
    height: auto;
    padding-top: var(--dl-space-space-fiveunits);
    padding-bottom: var(--dl-space-space-fiveunits);
  }

  .game-art-container18 {
    width: 350px;
    height: 466px;
  }

  .game-art-icon08 {
    width: 70px;
    height: 70px;
  }

  .game-art-icon10 {
    width: 70px;
    height: 70px;
  }

  .game-art-icon12 {
    width: 70px;
    height: 70px;
  }

  .game-art-icon14 {
    width: 70px;
    height: 70px;
  }

  .game-art-text34 {
    font-size: 35px;
  }

  .game-art-text38 {
    font-size: 18px;
  }

  .game-art-container22 {
    padding-top: var(--dl-space-space-fiveunits);
    padding-bottom: var(--dl-space-space-fiveunits);
  }

  .game-art-text43 {
    font-size: 16px;
  }

  .game-art-text48 {
    font-size: 16px;
  }

  .game-art-text50 {
    font-size: 16px;
  }

  .game-art-container26 {
    height: 100vh;
  }

  .game-art-gallery {
    height: auto;
    display: flex;
    padding-top: var(--dl-space-space-fiveunits);
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
    flex-direction: column;
    padding-bottom: var(--dl-space-space-fiveunits);
  }

  .game-art-text72 h2 {
    font-size: 35px;
  }

  .game-art-text76 {
    font-size: 17px;
  }

  .game-art-container43 {
    grid-template-columns: auto;
  }
}

@media(max-width: 479px) {
  .game-art-text16{
    text-align: left;
  }
  .game-art-container26{
    height: auto;
  }
  .game-art-container27{
    background-color: transparent;
  }
  .game-art-container03{
    background-attachment: initial;
  }
  .game-art-gallery {
    width: 100%;
    padding-top: var(--dl-space-space-fiveunits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-fiveunits);
  }
}

@media(max-width: 375px) {
  .game-art-container16 {
    height: auto;
  }

  .game-art-container26 {
    height: 50ch;
  }

  .game-art-text51 {
    font-size: 14vw;
  }

  .game-art-gallery {
    padding-top: var(--dl-space-space-sixunits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-sixunits);
  }
}
.privacy-policy-container {
    width: 80%;
    display: flex;
    overflow: auto;
    min-height: 100vh;
    align-items: center;
    flex-direction: column;
    justify-content: flex-start;
}

#object1 {
    /* object-fit: none; */
    object-position: center top;
}

.privacy-policy-container01 {
    width: 80%;
    display: flex;
    align-items: center;
    flex-direction: column;
    background-size: contain;
    justify-content: flex-start;
    /* background-image: url('https://iili.io/H78Yaob.png'); */
    background-repeat: repeat;
    background-position: center;
    background-attachment: fixed;
}

.privacy-policy-container02 {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: flex-start;
    background-color: rgba(0, 0, 0, 0.6);
}

.privacy-policy-container03 {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    background-size: cover;
    justify-content: flex-start;
    background-image: url("https://iili.io/HEpwRX2.png");
    background-repeat: no-repeat;
    background-position: center;
}

.privacy-policy-container04 {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: flex-start;
    background-color: rgba(0, 0, 0, 0.6);
}

.privacy-policy-container05 {
    flex: 0 0 auto;
    width: 100%;
    height: 100vh;
    display: flex;
    padding: var(--dl-space-space-unit);
    max-width: 1600px;
    align-items: center;
    flex-direction: row;
    justify-content: center;
}

.privacy-policy-container06 {
    flex: 0 0 auto;
    max-width: 50%;
    height: 100%;
    display: flex;
    padding: var(--dl-space-space-twounits);
    align-items: center;
    flex-direction: column;
    justify-content: center;
}

.privacy-policy-container07 {
    grid-gap: var(--dl-space-space-twounits);
    gap: var(--dl-space-space-twounits);
    flex: 0 0 auto;
    width: 100%;
    height: auto;
    display: flex;
    padding: var(--dl-space-space-threeunits);
    align-items: center;
    flex-direction: column;
    justify-content: center;
}

.privacy-policy-text {
    width: auto;
    font-size: 55px;
    text-align: left;
    font-family: Poppins;
    font-weight: 700;
    text-transform: none;
    background-size: cover;
    text-decoration: none;
    background-image: url('https://media.tenor.com/lveDtTE8Ek8AAAAM/rainbow-swirls-relaxing.gif');
}

.privacy-policy-text01 {
    font-size: 24px;
    font-style: normal;
    font-weight: 300;
}

.privacy-policy-container08 {
    flex: 0 0 auto;
    width: 50%;
    height: 100%;
    display: flex;
    padding: var(--dl-space-space-twounits);
    align-items: center;
    flex-direction: column;
    justify-content: center;
}

.privacy-policy-image {
    width: 100%;
    height: auto;
    display: none;
    object-fit: cover;
}

.privacy-policy-container09 {
    flex: 0 0 auto;
    width: 100%;
    height: auto;
    display: flex;
    padding: var(--dl-space-space-unit);
    max-width: 1600px;
    margin-top: var(--dl-space-space-fourunits);
    align-items: center;
    flex-direction: column;
    justify-content: center;
}

.privacy-policy-container10 {
    grid-gap: var(--dl-space-space-fourunits);
    gap: var(--dl-space-space-fourunits);
    flex: 0 0 auto;
    width: 100%;
    height: 50%;
    display: flex;
    padding: var(--dl-space-space-unit);
    align-items: center;
    flex-direction: row;
    justify-content: center;
}

.privacy-policy-container11 {
    grid-gap: var(--dl-space-space-unit);
    gap: var(--dl-space-space-unit);
    width: 510px;
    height: 70%;
    display: flex;
    padding: var(--dl-space-space-unit);
    align-items: center;
    border-color: var(--dl-color-gray-white);
    border-width: 2px;
    flex-direction: column;
    justify-content: center;
}

.privacy-policy-container12 {
    grid-gap: var(--dl-space-space-unit);
    gap: var(--dl-space-space-unit);
    width: 460px;
    height: 114px;
    display: flex;
    position: relative;
    align-items: center;
    flex-direction: row;
    justify-content: center;
}

.privacy-policy-icon {
    fill: var(--dl-color-colors-neongreen);
    width: 100px;
    height: 100px;
    transition: 0.3s;
    text-shadow: 0 0 10px blue;
}

.privacy-policy-icon:hover {
    fill: var(--dl-color-colors-cyan);
}

.privacy-policy-icon02 {
    fill: var(--dl-color-colors-orange);
    width: 90px;
    height: 90px;
    transition: 0.3s;
}

.privacy-policy-icon02:hover {
    fill: var(--dl-color-colors-cyan);
}

.privacy-policy-icon04 {
    fill: var(--dl-color-colors-pink);
    width: 80px;
    height: 80px;
    transition: 0.3s;
}

.privacy-policy-icon04:hover {
    fill: var(--dl-color-colors-cyan);
}

.privacy-policy-icon06 {
    fill: var(--dl-color-colors-skin);
    width: 100px;
    height: 100px;
    transition: 0.3s;
}

.privacy-policy-icon06:hover {
    fill: var(--dl-color-colors-cyan);
}

.privacy-policy-container13 {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}

.privacy-policy-image1 {
    width: 100%;
    height: 535px;
    object-fit: cover;
    border-color: var(--dl-color-gray-white);
    border-width: 2px;
    border-radius: var(--dl-radius-radius-radius8);
}

.privacy-policy-container14 {
    flex: 0 0 auto;
    width: 50%;
    height: 100%;
    display: flex;
    padding: var(--dl-space-space-halfunit);
    align-items: flex-start;
    flex-direction: column;
    justify-content: center;
}

.privacy-policy-text02 {
    width: auto;
    font-size: 55px;
    text-align: left;
    font-family: Poppins;
    font-weight: 700;
    text-transform: none;
    background-size: cover;
    background-image: url('https://media.tenor.com/lveDtTE8Ek8AAAAM/rainbow-swirls-relaxing.gif');
}

.privacy-policy-text03 {
    width: auto;
    font-size: 24px;
    font-style: normal;
    text-align: left;
    font-family: Poppins;
    font-weight: 300;
    text-transform: none;
    text-decoration: none;
}

.privacy-policy-container15 {
    grid-gap: var(--dl-space-space-fourunits);
    gap: var(--dl-space-space-fourunits);
    flex: 0 0 auto;
    width: 100%;
    height: 50%;
    display: none;
    padding: var(--dl-space-space-unit);
    align-items: center;
    flex-direction: row;
    justify-content: center;
}

.privacy-policy-container16 {
    flex: 0 0 auto;
    width: 50%;
    height: 100%;
    display: flex;
    padding: var(--dl-space-space-halfunit);
    align-items: flex-start;
    flex-direction: column;
    justify-content: center;
}

.privacy-policy-text04 {
    width: auto;
    font-size: 55px;
    text-align: left;
    font-family: Poppins;
    font-weight: 700;
    text-transform: none;
    background-size: cover;
    background-image: url('https://media.tenor.com/lveDtTE8Ek8AAAAM/rainbow-swirls-relaxing.gif');
}

.privacy-policy-text05 {
    width: auto;
    font-size: 24px;
    font-style: normal;
    text-align: left;
    font-family: Poppins;
    font-weight: 300;
    text-transform: none;
    text-decoration: none;
}

.privacy-policy-container17 {
    grid-gap: var(--dl-space-space-unit);
    gap: var(--dl-space-space-unit);
    width: 510px;
    height: 70%;
    display: flex;
    padding: var(--dl-space-space-unit);
    align-items: center;
    border-color: var(--dl-color-gray-white);
    border-width: 2px;
    flex-direction: column;
    justify-content: center;
}

.privacy-policy-container18 {
    grid-gap: var(--dl-space-space-unit);
    gap: var(--dl-space-space-unit);
    width: 460px;
    height: 114px;
    display: flex;
    position: relative;
    align-items: center;
    flex-direction: row;
    justify-content: center;
}

.privacy-policy-icon08 {
    fill: var(--dl-color-colors-neongreen);
    width: 100px;
    height: 100px;
    transition: 0.3s;
    text-shadow: 0 0 10px blue;
}

.privacy-policy-icon08:hover {
    fill: var(--dl-color-colors-cyan);
}

.privacy-policy-icon10 {
    fill: var(--dl-color-colors-orange);
    width: 90px;
    height: 90px;
    transition: 0.3s;
}

.privacy-policy-icon10:hover {
    fill: var(--dl-color-colors-cyan);
}

.privacy-policy-icon12 {
    fill: var(--dl-color-colors-pink);
    width: 80px;
    height: 80px;
    transition: 0.3s;
}

.privacy-policy-icon12:hover {
    fill: var(--dl-color-colors-cyan);
}

.privacy-policy-icon14 {
    fill: var(--dl-color-colors-skin);
    width: 100px;
    height: 100px;
    transition: 0.3s;
}

.privacy-policy-icon14:hover {
    fill: var(--dl-color-colors-cyan);
}

.privacy-policy-container19 {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}

.privacy-policy-image2 {
    width: 100%;
    height: 535px;
    object-fit: cover;
    border-color: var(--dl-color-gray-white);
    border-width: 2px;
    border-radius: var(--dl-radius-radius-radius8);
}

.privacy-policy-container20 {
    grid-gap: var(--dl-space-space-fourunits);
    gap: var(--dl-space-space-fourunits);
    flex: 0 0 auto;
    width: 100%;
    height: 50%;
    display: flex;
    padding: var(--dl-space-space-unit);
    align-items: center;
    flex-direction: row;
    justify-content: center;
}

.privacy-policy-container21 {
    flex: 0 0 auto;
    width: 50%;
    height: 100%;
    display: flex;
    padding: var(--dl-space-space-halfunit);
    align-items: flex-start;
    flex-direction: column;
    justify-content: center;
}

.privacy-policy-text06 {
    width: auto;
    font-size: 55px;
    text-align: left;
    font-family: Poppins;
    font-weight: 700;
    text-transform: none;
    background-size: cover;
    background-image: url('https://media.tenor.com/lveDtTE8Ek8AAAAM/rainbow-swirls-relaxing.gif');
}

.privacy-policy-text07 {
    width: auto;
    font-size: 24px;
    font-style: normal;
    text-align: left;
    font-family: Poppins;
    font-weight: 300;
    text-transform: none;
    text-decoration: none;
}

.privacy-policy-container22 {
    grid-gap: var(--dl-space-space-unit);
    gap: var(--dl-space-space-unit);
    width: 510px;
    height: 100%;
    display: flex;
    padding: var(--dl-space-space-unit);
    align-items: center;
    border-color: var(--dl-color-gray-white);
    border-width: 2px;
    flex-direction: column;
    justify-content: center;
}

.privacy-policy-container23 {
    grid-gap: var(--dl-space-space-unit);
    gap: var(--dl-space-space-unit);
    width: 460px;
    height: 114px;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: center;
}

.privacy-policy-icon16 {
    fill: var(--dl-color-colors-neongreen);
    width: 100px;
    height: 100px;
    transition: 0.3s;
}

.privacy-policy-icon16:hover {
    fill: var(--dl-color-colors-cyan);
}

.privacy-policy-icon18 {
    fill: var(--dl-color-colors-orange);
    width: 90px;
    height: 90px;
    transition: 0.3s;
}

.privacy-policy-icon18:hover {
    fill: var(--dl-color-colors-cyan);
}

.privacy-policy-icon20 {
    fill: var(--dl-color-colors-pink);
    width: 80px;
    height: 80px;
    transition: 0.3s;
}

.privacy-policy-icon20:hover {
    fill: var(--dl-color-colors-cyan);
}

.privacy-policy-icon22 {
    fill: var(--dl-color-colors-skin);
    width: 100px;
    height: 100px;
    transition: 0.3s;
}

.privacy-policy-icon22:hover {
    fill: var(--dl-color-colors-cyan);
}

.privacy-policy-container24 {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}

.privacy-policy-image3 {
    width: 100%;
    height: 535px;
    object-fit: cover;
    border-color: var(--dl-color-gray-white);
    border-width: 2px;
    border-radius: var(--dl-radius-radius-radius8);
}

.privacy-policy-container25 {
    flex: 0 0 auto;
    width: 100%;
    /* height: 100vh; */
    height: auto;
    display: flex;
    padding: var(--dl-space-space-unit);
    max-width: 1600px;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}

.privacy-policy-container26 {
    flex: 0 0 auto;
    width: 100%;
    height: 50%;
    display: flex;
    padding: var(--dl-space-space-unit);
    align-items: center;
    flex-direction: row;
    justify-content: center;
}

.privacy-policy-container27 {
    width: 50%;
    height: 100%;
    display: flex;
    padding: var(--dl-space-space-twounits);
    align-items: center;
    flex-direction: column;
    justify-content: flex-start;
    overflow: hidden;
}

.privacy-policy-text08 {
    width: auto;
    font-size: 55px;
    text-align: left;
    font-family: Poppins;
    font-weight: 700;
    text-transform: none;
    background-size: cover;
    background-image: url('https://media.tenor.com/lveDtTE8Ek8AAAAM/rainbow-swirls-relaxing.gif');
}

.privacy-policy-text09 {
    width: auto;
    font-size: 22px;
    font-style: normal;
    text-align: left;
    font-family: Poppins;
    font-weight: 300;
    text-transform: none;
    text-decoration: none;
}

.privacy-policy-container28 {
    flex: 0 0 auto;
    width: 50%;
    height: 100%;
    display: flex;
    padding: var(--dl-space-space-twounits);
    align-items: center;
    flex-direction: column;
    justify-content: center;
}

.privacy-policy-image4 {
    width: 70%;
    height: 100%;
    object-fit: cover;
    transition: 0.3s;
    border-color: var(--dl-color-gray-white);
    border-width: 1px;
    border-radius: var(--dl-radius-radius-radius8);
}

.privacy-policy-image4:hover {
    width: 65%;
    border: none;
    height: 90%;
}

.privacy-policy-container29 {
    flex: 0 0 auto;
    width: 100%;
    height: 50%;
    display: flex;
    padding: var(--dl-space-space-unit);
    align-items: center;
    flex-direction: row;
    justify-content: center;
}

.privacy-policy-container30 {
    flex: 0 0 auto;
    width: 50%;
    height: 100%;
    display: flex;
    padding: var(--dl-space-space-twounits);
    align-items: center;
    flex-direction: column;
    justify-content: center;
}

.privacy-policy-image5 {
    width: 70%;
    height: 100%;
    object-fit: cover;
    transition: 0.3s;
    border-color: var(--dl-color-gray-white);
    border-width: 1px;
    border-radius: var(--dl-radius-radius-radius8);
}

.privacy-policy-image5:hover {
    width: 65%;
    border: none;
    height: 90%;
}

.privacy-policy-container31 {
    width: 50%;
    height: 100%;
    display: flex;
    padding: var(--dl-space-space-twounits);
    align-items: center;
    flex-direction: column;
    justify-content: flex-start;
    overflow: hidden;
}

.privacy-policy-text13 {
    width: auto;
    font-size: 55px;
    text-align: left;
    font-family: Poppins;
    font-weight: 700;
    text-transform: none;
    background-size: cover;
    background-image: url("https://media.tenor.com/lveDtTE8Ek8AAAAM/rainbow-swirls-relaxing.gif");
}

.privacy-policy-text14 {
    width: auto;
    font-size: 22px;
    font-style: normal;
    text-align: left;
    font-family: Poppins;
    font-weight: 300;
    text-transform: none;
    text-decoration: none;
}

.privacy-policy-container32 {
    flex: 0 0 auto;
    width: 100%;
    height: 50%;
    display: none;
    padding: var(--dl-space-space-unit);
    align-items: center;
    flex-direction: row;
    justify-content: center;
}

.privacy-policy-container33 {
    width: 50%;
    height: 100%;
    display: flex;
    padding: var(--dl-space-space-twounits);
    align-items: center;
    flex-direction: column;
    justify-content: flex-start;
}

.privacy-policy-text18 {
    width: auto;
    font-size: 55px;
    text-align: left;
    font-family: Poppins;
    font-weight: 700;
    text-transform: none;
    background-size: cover;
    background-image: url("https://media.tenor.com/lveDtTE8Ek8AAAAM/rainbow-swirls-relaxing.gif");
}

.privacy-policy-text19 {
    width: auto;
    font-size: 22px;
    font-style: normal;
    text-align: left;
    font-family: Poppins;
    font-weight: 300;
    text-transform: none;
    text-decoration: none;
}

.privacy-policy-container34 {
    flex: 0 0 auto;
    width: 50%;
    height: 100%;
    display: flex;
    padding: var(--dl-space-space-twounits);
    align-items: center;
    flex-direction: column;
    justify-content: center;
}

.privacy-policy-image6 {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: 0.3s;
    border-color: var(--dl-color-gray-white);
    border-width: 1px;
    border-radius: var(--dl-radius-radius-radius8);
}

.privacy-policy-image6:hover {
    width: 90%;
    border: none;
    height: 90%;
}

.privacy-policy-container35 {
    grid-gap: var(--dl-space-space-unit);
    gap: var(--dl-space-space-unit);
    flex: 0 0 auto;
    width: 100%;
    height: 100vh;
    display: flex;
    padding: var(--dl-space-space-unit);
    max-width: 1600px;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}

.privacy-policy-container36 {
    flex: 0 0 auto;
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}

.privacy-policy-text23 {
    font-size: 55px;
    font-style: normal;
    font-weight: 700;
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-threeunits);
    padding-right: var(--dl-space-space-threeunits);
    padding-bottom: var(--dl-space-space-twounits);
    background-size: cover;
    background-image: url("https://media.tenor.com/lveDtTE8Ek8AAAAM/rainbow-swirls-relaxing.gif");
}

.privacy-policy-container37 {
    flex: 0 0 auto;
    width: 100%;
    height: auto;
    display: grid;
    padding: var(--dl-space-space-twounits);
    align-items: center;
    grid-row-gap: var(--dl-space-space-unit);
    flex-direction: column;
    grid-column-gap: var(--dl-space-space-unit);
    justify-content: center;
    grid-template-columns: auto auto auto auto auto;
}

.privacy-policy-container38 {
    width: 279px;
    height: 398px;
    display: flex;
    overflow: hidden;
    position: relative;
    align-items: flex-start;
    border-radius: var(--dl-radius-radius-radius8);
    flex-direction: column;
}

.privacy-policy-container-teams {
    flex: 0 0 auto;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    background-size: cover;
    justify-content: center;
    /* background-image: url("/playground_assets/bengaluru-1500w.png"); */
    background-repeat: no-repeat;
    background-position: center;
}

.privacy-policy-container39 {
    flex: 0 0 auto;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    background-size: cover;
    justify-content: center;
    background-image: url("/playground_assets/bengaluru-1500w.png");
    background-repeat: no-repeat;
    background-position: center;
}

.privacy-policy-container40 {
    width: 100%;
    height: 100%;
    display: flex;
    padding: var(--dl-space-space-twounits);
    align-items: flex-start;
    flex-direction: column;
    justify-content: flex-end;
    /* background-color: rgba(0, 0, 0, 0.6); */
    background-image: linear-gradient(to bottom, transparent, rgba(0, 0, 0, 0.8))
}

.privacy-policy-container41 {
    height: 96px;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: flex-start;
}

.privacy-policy-text24 {
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
}

.privacy-policy-text25 {
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
}

.privacy-policy-container42 {
    width: 279px;
    height: 398px;
    display: flex;
    overflow: hidden;
    align-items: flex-start;
    border-radius: var(--dl-radius-radius-radius8);
    flex-direction: column;
}

.privacy-policy-container43 {
    flex: 0 0 auto;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    background-size: cover;
    background-image: url("/playground_assets/chandigarh-1500w.jpg");
    background-repeat: no-repeat;
    background-position: center;
}

.privacy-policy-container44 {
    width: 100%;
    height: 100%;
    display: flex;
    padding: var(--dl-space-space-twounits);
    align-items: flex-start;
    flex-direction: column;
    justify-content: flex-end;
    /* background-color: rgba(0, 0, 0, 0.6); */
    background-image: linear-gradient(to bottom, transparent, rgba(0, 0, 0, 0.8))
}

.privacy-policy-container45 {
    height: 96px;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: flex-start;
}

.privacy-policy-text26 {
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
}

.privacy-policy-text27 {
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
}

.privacy-policy-container46 {
    width: 279px;
    height: 398px;
    display: flex;
    overflow: hidden;
    align-items: flex-start;
    border-radius: var(--dl-radius-radius-radius8);
    flex-direction: column;
}

.privacy-policy-container47 {
    flex: 0 0 auto;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    background-size: cover;
    background-image: url("/playground_assets/indore-1500w.png");
    background-repeat: no-repeat;
    background-position: center;
}

.privacy-policy-container48 {
    width: 100%;
    height: 100%;
    display: flex;
    padding: var(--dl-space-space-twounits);
    align-items: flex-start;
    flex-direction: column;
    justify-content: flex-end;
    /* background-color: rgba(0, 0, 0, 0.6); */
    background-image: linear-gradient(to bottom, transparent, rgba(0, 0, 0, 0.8))
}

.privacy-policy-container49 {
    height: 96px;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: flex-start;
}

.privacy-policy-text28 {
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
}

.privacy-policy-text29 {
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
}

.privacy-policy-container50 {
    width: 279px;
    height: 398px;
    display: flex;
    overflow: hidden;
    align-items: flex-start;
    border-radius: var(--dl-radius-radius-radius8);
    flex-direction: column;
}

.privacy-policy-container51 {
    flex: 0 0 auto;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    background-size: cover;
    background-image: url("/playground_assets/noida-1500w.png");
    background-repeat: no-repeat;
    background-position: center;
}

.privacy-policy-container52 {
    width: 100%;
    height: 100%;
    display: flex;
    padding: var(--dl-space-space-twounits);
    align-items: flex-start;
    flex-direction: column;
    justify-content: flex-end;
    /* background-color: rgba(0, 0, 0, 0.6); */
    background-image: linear-gradient(to bottom, transparent, rgba(0, 0, 0, 0.8))
}

.privacy-policy-container53 {
    height: 96px;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: flex-start;
}

.privacy-policy-text30 {
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
}

.privacy-policy-text31 {
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
}

.privacy-policy-container54 {
    width: 279px;
    height: 398px;
    display: flex;
    overflow: hidden;
    align-items: flex-start;
    border-radius: var(--dl-radius-radius-radius8);
    flex-direction: column;
}

.privacy-policy-container55 {
    flex: 0 0 auto;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    background-size: cover;
    background-image: url("/playground_assets/white-house-1500w.png");
    background-repeat: no-repeat;
    background-position: center;
}

.privacy-policy-container56 {
    width: 100%;
    height: 100%;
    display: flex;
    padding: var(--dl-space-space-twounits);
    align-items: flex-start;
    flex-direction: column;
    justify-content: flex-end;
    /* background-color: rgba(0, 0, 0, 0.6); */
    background-image: linear-gradient(to bottom, transparent, rgba(0, 0, 0, 0.8))
}

.privacy-policy-container57 {
    height: 96px;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: flex-start;
}

.privacy-policy-text32 {
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
}

.privacy-policy-text33 {
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
}

@media(max-width: 1400px) {
    .privacy-policy-container05 {
        justify-content: flex-start;
    }

    .privacy-policy-text {
        text-align: left;
        font-size: 42px;
        max-width: 300px;
        margin: auto;
    }

    .privacy-policy-text01 {
        text-align: left;
        font-size: 20px;
    }

    .privacy-policy-container08 {
        display: none;
    }

    .privacy-policy-container09 {
        height: auto;
    }

    .privacy-policy-container11 {
        width: 376px;
        height: 551px;
    }

    .privacy-policy-container12 {
        width: 346px;
        height: 131px;
    }

    .privacy-policy-icon {
        width: 70px;
        height: 70px;
    }

    .privacy-policy-icon02 {
        width: 60px;
        height: 60px;
    }

    .privacy-policy-icon04 {
        width: 60px;
        height: 60px;
    }

    .privacy-policy-icon06 {
        width: 70px;
        height: 70px;
    }

    .privacy-policy-image1 {
        height: 437px;
    }

    .privacy-policy-text02 {
        font-size: 45px;
    }

    .privacy-policy-text03 {
        font-size: 100%;
    }

    .privacy-policy-text05 {
        font-size: 100%;
    }

    .privacy-policy-container17 {
        width: 376px;
        height: 551px;
    }

    .privacy-policy-container18 {
        width: 346px;
        height: 131px;
    }

    .privacy-policy-icon08 {
        width: 70px;
        height: 70px;
    }

    .privacy-policy-icon10 {
        width: 60px;
        height: 60px;
    }

    .privacy-policy-icon12 {
        width: 60px;
        height: 60px;
    }

    .privacy-policy-icon14 {
        width: 70px;
        height: 70px;
    }

    .privacy-policy-image2 {
        height: 437px;
    }

    .privacy-policy-text06 {
        font-size: 45px;
    }

    .privacy-policy-text07 {
        font-size: 100%;
    }

    .privacy-policy-container22 {
        width: 376px;
        height: 551px;
    }

    .privacy-policy-container23 {
        width: 346px;
        height: 131px;
    }

    .privacy-policy-icon16 {
        width: 70px;
        height: 70px;
    }

    .privacy-policy-icon18 {
        width: 60px;
        height: 60px;
    }

    .privacy-policy-icon20 {
        width: 60px;
        height: 60px;
    }

    .privacy-policy-icon22 {
        width: 70px;
        height: 70px;
    }

    .privacy-policy-image3 {
        height: 437px;
    }

    .privacy-policy-container25 {
        grid-gap: var(--dl-space-space-unit);
        gap: var(--dl-space-space-unit);
        height: auto;
        flex-direction: column;
    }

    .privacy-policy-container26 {
        width: 100%;
        height: 50%;
        padding: var(--dl-space-space-unit);
        align-items: center;
        justify-content: flex-start;
    }

    .privacy-policy-text08 {
        font-size: 45px;
        font-family: Poppins;
        font-weight: 700;
        text-transform: none;
    }

    .privacy-policy-text09 {
        font-size: 100%;
        font-style: normal;
        text-align: left;
        font-family: Poppins;
        font-weight: 300;
        text-transform: none;
        text-decoration: none;
    }

    .privacy-policy-text10 {
        text-align: center;
    }

    .privacy-policy-text11 {
        text-align: center;
    }

    .privacy-policy-text12 {
        text-align: center;
    }

    .privacy-policy-container29 {
        width: 100%;
        height: 50%;
        padding: var(--dl-space-space-unit);
        align-items: center;
        justify-content: flex-start;
    }

    .privacy-policy-text13 {
        font-size: 45px;
        font-family: Poppins;
        font-weight: 700;
        text-transform: none;
    }

    .privacy-policy-text14 {
        font-size: 100%;
        font-style: normal;
        text-align: left;
        font-family: Poppins;
        font-weight: 300;
        text-transform: none;
        text-decoration: none;
    }

    .privacy-policy-text16 {
        text-align: center;
    }

    .privacy-policy-container32 {
        width: 100%;
        height: 50%;
        padding: var(--dl-space-space-unit);
        align-items: center;
        justify-content: flex-start;
    }

    .privacy-policy-text18 {
        font-size: 45px;
        font-family: Poppins;
        font-weight: 700;
        text-transform: none;
    }

    .privacy-policy-text19 {
        font-size: 100%;
        font-style: normal;
        text-align: left;
        font-family: Poppins;
        font-weight: 300;
        text-transform: none;
        text-decoration: none;
    }

    .privacy-policy-text21 {
        text-align: center;
    }

    .privacy-policy-container35 {
        grid-gap: var(--dl-space-space-unit);
        gap: var(--dl-space-space-unit);
        height: auto;
        flex-direction: column;
    }

    .privacy-policy-text23 {
        font-size: 45px;
    }

    .privacy-policy-container37 {
        grid-template-columns: auto auto auto auto;
    }
}

@media(max-width: 991px) {
    .privacy-policy-text {
        display: none;
    }

    .privacy-policy-text01 {
        display: none;
    }

    .privacy-policy-container06 {
        max-width: 50%;
    }

    .privacy-policy-text03 {
        font-size: 85%;
    }

    .privacy-policy-text05 {
        font-size: 85%;
    }

    .privacy-policy-text07 {
        font-size: 85%;
    }

    .privacy-policy-container26 {
        display: flex;
    }

    .privacy-policy-text09 {
        width: auto;
        height: auto;
        font-size: 85%;
    }

    .privacy-policy-text14 {
        font-size: 85%;
    }

    .privacy-policy-text19 {
        font-size: 85%;
    }

    .privacy-policy-container37 {
        grid-template-columns: auto auto;
    }
}

@media(max-width: 767px) {
    .privacy-policy-text {
        font-size: 45px;
    }

    .privacy-policy-text01 {
        display: none;
    }

    .privacy-policy-container10 {
        display: none;
        flex-direction: column;
    }

    .privacy-policy-container14 {
        width: 100%;
    }

    .privacy-policy-container15 {
        display: flex;
        flex-direction: column;
    }

    .privacy-policy-container16 {
        width: 100%;
    }

    .privacy-policy-container20 {
        flex-direction: column;
    }

    .privacy-policy-container21 {
        width: 100%;
    }

    .privacy-policy-container25 {
        height: auto;
    }

    .privacy-policy-container26 {
        flex-direction: column;
    }

    .privacy-policy-container27 {
        width: 100%;
    }

    .privacy-policy-container29 {
        display: none;
        flex-direction: column;
    }

    .privacy-policy-container31 {
        width: 100%;
    }

    .privacy-policy-container32 {
        display: flex;
        flex-direction: column;
    }

    .privacy-policy-container33 {
        width: 100%;
    }

    .privacy-policy-container37 {
        grid-template-columns: auto;
    }
}

@media(max-width: 479px) {

    .privacy-policy-container03 {
        background-image: url("/playground_assets/about_us/about_us_banner_500.png") !important;
        background-repeat: no-repeat;
        background-size: contain;
        height: 250px;
        margin-top: 100px;
    }

    .privacy-policy-container09 {
        margin-top: 0;
    }

    .privacy-policy-text {
        display: none;
    }

    .privacy-policy-container07 {
        padding: var(--dl-space-space-unit);
    }

    .privacy-policy-container15 {
        padding: var(--dl-space-space-halfunit);
    }

    .privacy-policy-container20 {
        padding: var(--dl-space-space-halfunit);
    }

    .privacy-policy-container27 {
        padding: var(--dl-space-space-unit);
    }

    .privacy-policy-container28 {
        width: 100%;
    }

    .privacy-policy-container30 {
        width: 100%;
    }

    .privacy-policy-container33 {
        padding: var(--dl-space-space-unit);
    }

    .privacy-policy-container34 {
        width: 100%;
    }

    .privacy-policy-text23 {
        font-size: 40px;
    }

    .privacy-policy-container38 {
        width: 100%;
        height: 550px;
    }

    .privacy-policy-container42 {
        width: 100%;
        height: 550px;
    }

    .privacy-policy-container46 {
        width: 100%;
        height: 550px;
    }

    .privacy-policy-container50 {
        width: 100%;
        height: 550px;
    }

    .privacy-policy-container54 {
        width: 100%;
        height: 550px;
    }
}

@media(max-width: 375px) {
    .privacy-policy-text01 {
        display: none;
    }

    .privacy-policy-container11 {
        width: 100%;
        height: 481px;
    }

    .privacy-policy-container12 {
        width: 273px;
    }

    .privacy-policy-icon {
        width: 45px;
        height: 45px;
    }

    .privacy-policy-icon02 {
        width: 39px;
        height: 39px;
    }

    .privacy-policy-icon04 {
        width: 40px;
        height: 40px;
    }

    .privacy-policy-icon06 {
        width: 50px;
        height: 50px;
    }

    .privacy-policy-image1 {
        height: 351px;
    }

    .privacy-policy-text05 {
        font-size: 16px;
    }

    .privacy-policy-container17 {
        width: 100%;
        height: 481px;
    }

    .privacy-policy-container18 {
        grid-gap: 0;
        gap: 0;
        width: 273px;
        justify-content: space-between;
    }

    .privacy-policy-icon08 {
        width: 45px;
        height: 45px;
    }

    .privacy-policy-icon10 {
        width: 39px;
        height: 39px;
    }

    .privacy-policy-icon12 {
        width: 40px;
        height: 40px;
    }

    .privacy-policy-icon14 {
        width: 50px;
        height: 50px;
    }

    .privacy-policy-image2 {
        height: 351px;
    }

    .privacy-policy-text07 {
        font-size: 16px;
    }

    .privacy-policy-container22 {
        width: 100%;
        height: 481px;
    }

    .privacy-policy-container23 {
        grid-gap: 0;
        gap: 0;
        width: 273px;
        justify-content: space-between;
    }

    .privacy-policy-icon16 {
        width: 45px;
        height: 45px;
    }

    .privacy-policy-icon18 {
        width: 39px;
        height: 39px;
    }

    .privacy-policy-icon20 {
        width: 40px;
        height: 40px;
    }

    .privacy-policy-icon22 {
        width: 50px;
        height: 50px;
    }

    .privacy-policy-image3 {
        height: 351px;
    }

    .privacy-policy-text09 {
        font-size: 16px;
    }

    .privacy-policy-text19 {
        font-size: 16px;
    }

    .privacy-policy-container38 {
        height: 400px;
    }

    .privacy-policy-container42 {
        height: 400px;
    }

    .privacy-policy-container46 {
        height: 400px;
    }

    .privacy-policy-container50 {
        height: 400px;
    }

    .privacy-policy-container54 {
        height: 400px;
    }
}
.about-us-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}

#object1 {
  /* object-fit: none; */
  object-position: center top;
}

.about-us-container01 {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  background-size: contain;
  justify-content: flex-start;
  background-image: url('https://iili.io/H78Yaob.png');
  background-repeat: repeat;
  background-position: center;
  background-attachment: fixed;
}

.about-us-container02 {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
  background-color: rgba(0, 0, 0, 0.6);
}

.about-us-container03 {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  background-size: cover;
  justify-content: flex-start;
  background-image: url("https://iili.io/HEpwRX2.png");
  background-repeat: no-repeat;
  background-position: center;
}

.about-us-container04 {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
  background-color: rgba(0, 0, 0, 0.6);
}

.about-us-container05 {
  flex: 0 0 auto;
  width: 100%;
  height: 100vh;
  display: flex;
  padding: var(--dl-space-space-unit);
  max-width: 1600px;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}

.about-us-container06 {
  flex: 0 0 auto;
  max-width: 50%;
  height: 100%;
  display: flex;
  padding: var(--dl-space-space-twounits);
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.about-us-container07 {
  grid-gap: var(--dl-space-space-twounits);
  gap: var(--dl-space-space-twounits);
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  padding: var(--dl-space-space-threeunits);
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.about-us-text {
  width: auto;
  font-size: 55px;
  text-align: left;
  font-family: Poppins;
  font-weight: 700;
  text-transform: none;
  background-size: cover;
  text-decoration: none;
  background-image: url('https://media.tenor.com/lveDtTE8Ek8AAAAM/rainbow-swirls-relaxing.gif');
}

.about-us-text01 {
  font-size: 24px;
  font-style: normal;
  font-weight: 300;
}

.about-us-container08 {
  flex: 0 0 auto;
  width: 50%;
  height: 100%;
  display: flex;
  padding: var(--dl-space-space-twounits);
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.about-us-image {
  width: 100%;
  height: auto;
  display: none;
  object-fit: cover;
}

.about-us-container09 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  padding: var(--dl-space-space-unit);
  max-width: 1600px;
  margin-top: var(--dl-space-space-fourunits);
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.about-us-container10 {
  grid-gap: var(--dl-space-space-fourunits);
  gap: var(--dl-space-space-fourunits);
  flex: 0 0 auto;
  width: 100%;
  height: 50%;
  display: flex;
  padding: var(--dl-space-space-unit);
  align-items: center;
  flex-direction: row;
  justify-content: center;
}

.about-us-container11 {
  grid-gap: var(--dl-space-space-unit);
  gap: var(--dl-space-space-unit);
  width: 510px;
  height: 70%;
  display: flex;
  padding: var(--dl-space-space-unit);
  align-items: center;
  border-color: var(--dl-color-gray-white);
  border-width: 2px;
  flex-direction: column;
  justify-content: center;
}

.about-us-container12 {
  grid-gap: var(--dl-space-space-unit);
  gap: var(--dl-space-space-unit);
  width: 460px;
  height: 114px;
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}

.about-us-icon {
  fill: var(--dl-color-colors-neongreen);
  width: 100px;
  height: 100px;
  transition: 0.3s;
  text-shadow: 0 0 10px blue;
}

.about-us-icon:hover {
  fill: var(--dl-color-colors-cyan);
}

.about-us-icon02 {
  fill: var(--dl-color-colors-orange);
  width: 90px;
  height: 90px;
  transition: 0.3s;
}

.about-us-icon02:hover {
  fill: var(--dl-color-colors-cyan);
}

.about-us-icon04 {
  fill: var(--dl-color-colors-pink);
  width: 80px;
  height: 80px;
  transition: 0.3s;
}

.about-us-icon04:hover {
  fill: var(--dl-color-colors-cyan);
}

.about-us-icon06 {
  fill: var(--dl-color-colors-skin);
  width: 100px;
  height: 100px;
  transition: 0.3s;
}

.about-us-icon06:hover {
  fill: var(--dl-color-colors-cyan);
}

.about-us-container13 {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.about-us-image1 {
  width: 100%;
  height: 535px;
  object-fit: cover;
  border-color: var(--dl-color-gray-white);
  border-width: 2px;
  border-radius: var(--dl-radius-radius-radius8);
}

.about-us-container14 {
  flex: 0 0 auto;
  width: 50%;
  height: 100%;
  display: flex;
  padding: var(--dl-space-space-halfunit);
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}

.about-us-text02 {
  width: auto;
  font-size: 55px;
  text-align: left;
  font-family: Poppins;
  font-weight: 700;
  text-transform: none;
  background-size: cover;
  background-image: url('https://media.tenor.com/lveDtTE8Ek8AAAAM/rainbow-swirls-relaxing.gif');
}

.about-us-text03 {
  width: auto;
  font-size: 24px;
  font-style: normal;
  text-align: left;
  font-family: Poppins;
  font-weight: 300;
  text-transform: none;
  text-decoration: none;
}

.about-us-container15 {
  grid-gap: var(--dl-space-space-fourunits);
  gap: var(--dl-space-space-fourunits);
  flex: 0 0 auto;
  width: 100%;
  height: 50%;
  display: none;
  padding: var(--dl-space-space-unit);
  align-items: center;
  flex-direction: row;
  justify-content: center;
}

.about-us-container16 {
  flex: 0 0 auto;
  width: 50%;
  height: 100%;
  display: flex;
  padding: var(--dl-space-space-halfunit);
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}

.about-us-text04 {
  width: auto;
  font-size: 55px;
  text-align: left;
  font-family: Poppins;
  font-weight: 700;
  text-transform: none;
  background-size: cover;
  background-image: url('https://media.tenor.com/lveDtTE8Ek8AAAAM/rainbow-swirls-relaxing.gif');
}

.about-us-text05 {
  width: auto;
  font-size: 24px;
  font-style: normal;
  text-align: left;
  font-family: Poppins;
  font-weight: 300;
  text-transform: none;
  text-decoration: none;
}

.about-us-container17 {
  grid-gap: var(--dl-space-space-unit);
  gap: var(--dl-space-space-unit);
  width: 510px;
  height: 70%;
  display: flex;
  padding: var(--dl-space-space-unit);
  align-items: center;
  border-color: var(--dl-color-gray-white);
  border-width: 2px;
  flex-direction: column;
  justify-content: center;
}

.about-us-container18 {
  grid-gap: var(--dl-space-space-unit);
  gap: var(--dl-space-space-unit);
  width: 460px;
  height: 114px;
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}

.about-us-icon08 {
  fill: var(--dl-color-colors-neongreen);
  width: 100px;
  height: 100px;
  transition: 0.3s;
  text-shadow: 0 0 10px blue;
}

.about-us-icon08:hover {
  fill: var(--dl-color-colors-cyan);
}

.about-us-icon10 {
  fill: var(--dl-color-colors-orange);
  width: 90px;
  height: 90px;
  transition: 0.3s;
}

.about-us-icon10:hover {
  fill: var(--dl-color-colors-cyan);
}

.about-us-icon12 {
  fill: var(--dl-color-colors-pink);
  width: 80px;
  height: 80px;
  transition: 0.3s;
}

.about-us-icon12:hover {
  fill: var(--dl-color-colors-cyan);
}

.about-us-icon14 {
  fill: var(--dl-color-colors-skin);
  width: 100px;
  height: 100px;
  transition: 0.3s;
}

.about-us-icon14:hover {
  fill: var(--dl-color-colors-cyan);
}

.about-us-container19 {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.about-us-image2 {
  width: 100%;
  height: 535px;
  object-fit: cover;
  border-color: var(--dl-color-gray-white);
  border-width: 2px;
  border-radius: var(--dl-radius-radius-radius8);
}

.about-us-container20 {
  grid-gap: var(--dl-space-space-fourunits);
  gap: var(--dl-space-space-fourunits);
  flex: 0 0 auto;
  width: 100%;
  height: 50%;
  display: flex;
  padding: var(--dl-space-space-unit);
  align-items: center;
  flex-direction: row;
  justify-content: center;
}

.about-us-container21 {
  flex: 0 0 auto;
  width: 50%;
  height: 100%;
  display: flex;
  padding: var(--dl-space-space-halfunit);
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.about-us-container21m {
  flex: 0 0 auto;
  width: 45%;
  height: 100%;
  display: flex;
  padding: var(--dl-space-space-halfunit);
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
  margin-left: 100px !important;
}

.about-us-text06 {
  width: auto;
  font-size: 55px;
  text-align: left;
  font-family: Poppins;
  font-weight: 700;
  text-transform: none;
  background-size: cover;
  background-image: url('https://media.tenor.com/lveDtTE8Ek8AAAAM/rainbow-swirls-relaxing.gif');
}

.about-us-text07 {
  width: auto;
  font-size: 24px;
  font-style: normal;
  text-align: left;
  font-family: Poppins;
  font-weight: 300;
  text-transform: none;
  text-decoration: none;
}

.about-us-container22 {
  grid-gap: var(--dl-space-space-unit);
  gap: var(--dl-space-space-unit);
  width: 510px;
  height: 100%;
  display: flex;
  padding: var(--dl-space-space-unit);
  align-items: center;
  border-color: var(--dl-color-gray-white);
  border-width: 2px;
  flex-direction: column;
  justify-content: center;
}

.about-us-container23 {
  grid-gap: var(--dl-space-space-unit);
  gap: var(--dl-space-space-unit);
  width: 460px;
  height: 114px;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}

.about-us-icon16 {
  fill: var(--dl-color-colors-neongreen);
  width: 100px;
  height: 100px;
  transition: 0.3s;
}

.about-us-icon16:hover {
  fill: var(--dl-color-colors-cyan);
}

.about-us-icon18 {
  fill: var(--dl-color-colors-orange);
  width: 90px;
  height: 90px;
  transition: 0.3s;
}

.about-us-icon18:hover {
  fill: var(--dl-color-colors-cyan);
}

.about-us-icon20 {
  fill: var(--dl-color-colors-pink);
  width: 80px;
  height: 80px;
  transition: 0.3s;
}

.about-us-icon20:hover {
  fill: var(--dl-color-colors-cyan);
}

.about-us-icon22 {
  fill: var(--dl-color-colors-skin);
  width: 100px;
  height: 100px;
  transition: 0.3s;
}

.about-us-icon22:hover {
  fill: var(--dl-color-colors-cyan);
}

.about-us-container24 {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.about-us-image3 {
  width: 100%;
  height: 535px;
  object-fit: cover;
  border-color: var(--dl-color-gray-white);
  border-width: 2px;
  border-radius: var(--dl-radius-radius-radius8);
}

.about-us-container25 {
  flex: 0 0 auto;
  width: 100%;
  /* height: 100vh; */
  height: auto;
  display: flex;
  padding: var(--dl-space-space-unit);
  max-width: 1600px;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.about-us-container26 {
  flex: 0 0 auto;
  width: 100%;
  height: 50%;
  display: flex;
  padding: var(--dl-space-space-unit);
  align-items: center;
  flex-direction: row;
  justify-content: center;
}

.about-us-container27 {
  width: 50%;
  height: 100%;
  display: flex;
  padding: var(--dl-space-space-twounits);
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
  overflow: hidden;
}

.about-us-text08 {
  width: auto;
  font-size: 55px;
  text-align: left;
  font-family: Poppins;
  font-weight: 700;
  text-transform: none;
  background-size: cover;
  background-image: url('https://media.tenor.com/lveDtTE8Ek8AAAAM/rainbow-swirls-relaxing.gif');
}

.about-us-text09 {
  width: auto;
  font-size: 22px;
  font-style: normal;
  text-align: left;
  font-family: Poppins;
  font-weight: 300;
  text-transform: none;
  text-decoration: none;
}

.about-us-container28 {
  flex: 0 0 auto;
  width: 50%;
  height: 100%;
  display: flex;
  padding: var(--dl-space-space-twounits);
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.about-us-image4 {
  width: 70%;
  height: 100%;
  object-fit: cover;
  transition: 0.3s;
  border-color: var(--dl-color-gray-white);
  border-width: 1px;
  border-radius: var(--dl-radius-radius-radius8);
}

.about-us-image4:hover {
  width: 65%;
  border: none;
  height: 90%;
}

.about-us-container29 {
  flex: 0 0 auto;
  width: 100%;
  height: 50%;
  display: flex;
  padding: var(--dl-space-space-unit);
  align-items: center;
  flex-direction: row;
  justify-content: center;
}

.about-us-container30 {
  flex: 0 0 auto;
  width: 50%;
  height: 100%;
  display: flex;
  padding: var(--dl-space-space-twounits);
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.about-us-image5 {
  width: 70%;
  height: 100%;
  object-fit: cover;
  transition: 0.3s;
  border-color: var(--dl-color-gray-white);
  border-width: 1px;
  border-radius: var(--dl-radius-radius-radius8);
}

.about-us-image5:hover {
  width: 65%;
  border: none;
  height: 90%;
}

.about-us-container31 {
  width: 50%;
  height: 100%;
  display: flex;
  padding: var(--dl-space-space-twounits);
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
  overflow: hidden;
}

.about-us-text13 {
  width: auto;
  font-size: 55px;
  text-align: left;
  font-family: Poppins;
  font-weight: 700;
  text-transform: none;
  background-size: cover;
  background-image: url("https://media.tenor.com/lveDtTE8Ek8AAAAM/rainbow-swirls-relaxing.gif");
}

.about-us-text14 {
  width: auto;
  font-size: 22px;
  font-style: normal;
  text-align: left;
  font-family: Poppins;
  font-weight: 300;
  text-transform: none;
  text-decoration: none;
}

.about-us-container32 {
  flex: 0 0 auto;
  width: 100%;
  height: 50%;
  display: none;
  padding: var(--dl-space-space-unit);
  align-items: center;
  flex-direction: row;
  justify-content: center;
}

.about-us-container33 {
  width: 50%;
  height: 100%;
  display: flex;
  padding: var(--dl-space-space-twounits);
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}

.about-us-text18 {
  width: auto;
  font-size: 55px;
  text-align: left;
  font-family: Poppins;
  font-weight: 700;
  text-transform: none;
  background-size: cover;
  background-image: url("https://media.tenor.com/lveDtTE8Ek8AAAAM/rainbow-swirls-relaxing.gif");
}

.about-us-text19 {
  width: auto;
  font-size: 22px;
  font-style: normal;
  text-align: left;
  font-family: Poppins;
  font-weight: 300;
  text-transform: none;
  text-decoration: none;
}

.about-us-container34 {
  flex: 0 0 auto;
  width: 50%;
  height: 100%;
  display: flex;
  padding: var(--dl-space-space-twounits);
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.about-us-image6 {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: 0.3s;
  border-color: var(--dl-color-gray-white);
  border-width: 1px;
  border-radius: var(--dl-radius-radius-radius8);
}

.about-us-image6:hover {
  width: 90%;
  border: none;
  height: 90%;
}

.about-us-container35 {
  grid-gap: var(--dl-space-space-unit);
  gap: var(--dl-space-space-unit);
  flex: 0 0 auto;
  width: 100%;
  height: 100vh;
  display: flex;
  padding: var(--dl-space-space-unit);
  max-width: 1600px;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.about-us-container36 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.about-us-text23 {
  font-size: 55px;
  font-style: normal;
  font-weight: 700;
  padding-top: var(--dl-space-space-twounits);
  padding-left: var(--dl-space-space-threeunits);
  padding-right: var(--dl-space-space-threeunits);
  padding-bottom: var(--dl-space-space-twounits);
  background-size: cover;
  background-image: url("https://media.tenor.com/lveDtTE8Ek8AAAAM/rainbow-swirls-relaxing.gif");
}

.about-us-container37 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: grid;
  padding: var(--dl-space-space-twounits);
  align-items: center;
  grid-row-gap: var(--dl-space-space-unit);
  flex-direction: column;
  grid-column-gap: var(--dl-space-space-unit);
  justify-content: center;
  grid-template-columns: auto auto auto auto auto;
}

.about-us-container38 {
  width: 279px;
  height: 398px;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: flex-start;
  border-radius: var(--dl-radius-radius-radius8);
  flex-direction: column;
}

.about-us-container-teams {
  flex: 0 0 auto;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  background-size: cover;
  justify-content: center;
  /* background-image: url("/playground_assets/bengaluru-1500w.png"); */
  background-repeat: no-repeat;
  background-position: center;
}

.about-us-container39 {
  flex: 0 0 auto;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  background-size: cover;
  justify-content: center;
  background-image: url("/playground_assets/bengaluru-1500w.png");
  background-repeat: no-repeat;
  background-position: center;
}

.about-us-container40 {
  width: 100%;
  height: 100%;
  display: flex;
  padding: var(--dl-space-space-twounits);
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-end;
  /* background-color: rgba(0, 0, 0, 0.6); */
  background-image: linear-gradient(to bottom, transparent , rgba(0, 0, 0, 0.8))
}

.about-us-container41 {
  /* height: 96px; */
  height: 111px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}

.about-us-text24 {
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
}

.about-us-text25 {
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
}

.about-us-container42 {
  width: 279px;
  height: 398px;
  display: flex;
  overflow: hidden;
  align-items: flex-start;
  border-radius: var(--dl-radius-radius-radius8);
  flex-direction: column;
}

.about-us-container43 {
  flex: 0 0 auto;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  background-size: cover;
  background-image: url("/playground_assets/chandigarh-1500w.jpg");
  background-repeat: no-repeat;
  background-position: center;
}

.about-us-container44 {
  width: 100%;
  height: 100%;
  display: flex;
  padding: var(--dl-space-space-twounits);
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-end;
  /* background-color: rgba(0, 0, 0, 0.6); */
  background-image: linear-gradient(to bottom, transparent , rgba(0, 0, 0, 0.8))

}

.about-us-container45 {
  /* height: 96px; */
  height: 111px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}

.about-us-text26 {
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
}

.about-us-text27 {
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
}

.about-us-container46 {
  width: 279px;
  height: 398px;
  display: flex;
  overflow: hidden;
  align-items: flex-start;
  border-radius: var(--dl-radius-radius-radius8);
  flex-direction: column;
}

.about-us-container47 {
  flex: 0 0 auto;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  background-size: cover;
  background-image: url("/playground_assets/indore-1500w.png");
  background-repeat: no-repeat;
  background-position: center;
}

.about-us-container48 {
  width: 100%;
  height: 100%;
  display: flex;
  padding: var(--dl-space-space-twounits);
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-end;
  /* background-color: rgba(0, 0, 0, 0.6); */
  background-image: linear-gradient(to bottom, transparent , rgba(0, 0, 0, 0.8))

}

.about-us-container49 {
  /* height: 96px; */
  height: 111px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}

.about-us-text28 {
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
}

.about-us-text29 {
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
}

.about-us-container50 {
  width: 279px;
  height: 398px;
  display: flex;
  overflow: hidden;
  align-items: flex-start;
  border-radius: var(--dl-radius-radius-radius8);
  flex-direction: column;
}

.about-us-container51 {
  flex: 0 0 auto;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  background-size: cover;
  background-image: url("/playground_assets/noida-1500w.png");
  background-repeat: no-repeat;
  background-position: center;
}

.about-us-container52 {
  width: 100%;
  height: 100%;
  display: flex;
  padding: var(--dl-space-space-twounits);
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-end;
  /* background-color: rgba(0, 0, 0, 0.6); */
  background-image: linear-gradient(to bottom, transparent , rgba(0, 0, 0, 0.8))

}

.about-us-container53 {
  /* height: 96px; */
  height: 111px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}

.about-us-text30 {
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
}

.about-us-text31 {
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
}

.about-us-container54 {
  width: 279px;
  height: 398px;
  display: flex;
  overflow: hidden;
  align-items: flex-start;
  border-radius: var(--dl-radius-radius-radius8);
  flex-direction: column;
}

.about-us-container55 {
  flex: 0 0 auto;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  background-size: cover;
  background-image: url("/playground_assets/white-house-1500w.png");
  background-repeat: no-repeat;
  background-position: center;
}

.ourwork {
  flex: 0 0 auto;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  background-size: cover;
  /* background-image: url("/playground_assets/white-house-1500w.png"); */
  background-repeat: no-repeat;
  background-position: center;
}

.about-us-container56 {
  width: 100%;
  height: 100%;
  display: flex;
  padding: var(--dl-space-space-twounits);
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-end;
  /* background-color: rgba(0, 0, 0, 0.6); */
  background-image: linear-gradient(to bottom, transparent , rgba(0, 0, 0, 0.8))

}

.about-us-container57 {
  /* height: 96px; */
  height: 111px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}

.about-us-text32 {
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
}

.about-us-text33 {
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
}

@media(max-width: 1400px) {
  .about-us-container05{
    justify-content: flex-start;
  }
  .about-us-text {
    text-align: left;
    font-size: 42px;
    max-width: 300px;
    margin: auto;
  }

  .about-us-text01 {
    text-align: left;
    font-size: 20px;
  }

  .about-us-container08 {
    display: none;
  }

  .about-us-container09 {
    height: auto;
  }

  .about-us-container11 {
    width: 376px;
    height: 551px;
  }

  .about-us-container12 {
    width: 346px;
    height: 131px;
  }

  .about-us-icon {
    width: 70px;
    height: 70px;
  }

  .about-us-icon02 {
    width: 60px;
    height: 60px;
  }

  .about-us-icon04 {
    width: 60px;
    height: 60px;
  }

  .about-us-icon06 {
    width: 70px;
    height: 70px;
  }

  .about-us-image1 {
    height: 437px;
  }

  .about-us-text02 {
    font-size: 45px;
  }

  .about-us-text03 {
    font-size: 100%;
  }

  .about-us-text05 {
    font-size: 100%;
  }

  .about-us-container17 {
    width: 376px;
    height: 551px;
  }

  .about-us-container18 {
    width: 346px;
    height: 131px;
  }

  .about-us-icon08 {
    width: 70px;
    height: 70px;
  }

  .about-us-icon10 {
    width: 60px;
    height: 60px;
  }

  .about-us-icon12 {
    width: 60px;
    height: 60px;
  }

  .about-us-icon14 {
    width: 70px;
    height: 70px;
  }

  .about-us-image2 {
    height: 437px;
  }

  .about-us-text06 {
    font-size: 45px;
  }

  .about-us-text07 {
    font-size: 100%;
  }

  .about-us-container22 {
    width: 376px;
    height: 551px;
  }

  .about-us-container23 {
    width: 346px;
    height: 131px;
  }

  .about-us-icon16 {
    width: 70px;
    height: 70px;
  }

  .about-us-icon18 {
    width: 60px;
    height: 60px;
  }

  .about-us-icon20 {
    width: 60px;
    height: 60px;
  }

  .about-us-icon22 {
    width: 70px;
    height: 70px;
  }

  .about-us-image3 {
    height: 437px;
  }

  .about-us-container25 {
    grid-gap: var(--dl-space-space-unit);
    gap: var(--dl-space-space-unit);
    height: auto;
    flex-direction: column;
  }

  .about-us-container26 {
    width: 100%;
    height: 50%;
    padding: var(--dl-space-space-unit);
    align-items: center;
    justify-content: flex-start;
  }

  .about-us-text08 {
    font-size: 45px;
    font-family: Poppins;
    font-weight: 700;
    text-transform: none;
  }

  .about-us-text09 {
    font-size: 100%;
    font-style: normal;
    text-align: left;
    font-family: Poppins;
    font-weight: 300;
    text-transform: none;
    text-decoration: none;
  }

  .about-us-text10 {
    text-align: center;
  }

  .about-us-text11 {
    text-align: center;
  }

  .about-us-text12 {
    text-align: center;
  }

  .about-us-container29 {
    width: 100%;
    height: 50%;
    padding: var(--dl-space-space-unit);
    align-items: center;
    justify-content: flex-start;
  }

  .about-us-text13 {
    font-size: 45px;
    font-family: Poppins;
    font-weight: 700;
    text-transform: none;
  }

  .about-us-text14 {
    font-size: 100%;
    font-style: normal;
    text-align: left;
    font-family: Poppins;
    font-weight: 300;
    text-transform: none;
    text-decoration: none;
  }

  .about-us-text16 {
    text-align: center;
  }

  .about-us-container32 {
    width: 100%;
    height: 50%;
    padding: var(--dl-space-space-unit);
    align-items: center;
    justify-content: flex-start;
  }

  .about-us-text18 {
    font-size: 45px;
    font-family: Poppins;
    font-weight: 700;
    text-transform: none;
  }

  .about-us-text19 {
    font-size: 100%;
    font-style: normal;
    text-align: left;
    font-family: Poppins;
    font-weight: 300;
    text-transform: none;
    text-decoration: none;
  }

  .about-us-text21 {
    text-align: center;
  }

  .about-us-container35 {
    grid-gap: var(--dl-space-space-unit);
    gap: var(--dl-space-space-unit);
    height: auto;
    flex-direction: column;
  }

  .about-us-text23 {
    font-size: 45px;
  }

  .about-us-container37 {
    /* padding-bottom: 300px; */
    grid-template-columns: auto auto auto auto ;
  }
}

@media(max-width: 991px) {
  .about-us-text{
    display: none;
  }
  .about-us-text01 {
    display: none;
  }
  .about-us-container06 {
    max-width: 50%;
  }

  .about-us-text03 {
    font-size: 85%;
  }

  .about-us-text05 {
    font-size: 85%;
  }

  .about-us-text07 {
    font-size: 85%;
  }

  .about-us-container26 {
    display: flex;
  }

  .about-us-text09 {
    width: auto;
    height: auto;
    font-size: 85%;
  }

  .about-us-text14 {
    font-size: 85%;
  }

  .about-us-text19 {
    font-size: 85%;
  }

  .about-us-container37 {
    grid-template-columns: auto auto;
  }
}

@media(max-width: 767px) {
  .about-us-text {
    font-size: 45px;
  }

  .about-us-text01 {
    display: none;
  }

  .about-us-container10 {
    display: none;
    flex-direction: column;
  }

  .about-us-container14 {
    width: 100%;
  }

  .about-us-container15 {
    display: flex;
    flex-direction: column;
  }

  .about-us-container16 {
    width: 100%;
  }

  .about-us-container20 {
    flex-direction: column;
  }

  .about-us-container21 {
    width: 100%;
  }

  .about-us-container25 {
    height: auto;
  }

  .about-us-container26 {
    flex-direction: column;
  }

  .about-us-container27 {
    width: 100%;
  }

  .about-us-container29 {
    display: none;
    flex-direction: column;
  }

  .about-us-container31 {
    width: 100%;
  }

  .about-us-container32 {
    display: flex;
    flex-direction: column;
  }

  .about-us-container33 {
    width: 100%;
  }

  .about-us-container37 {
    grid-template-columns: auto;
  }
}

@media(max-width: 479px) {
  
  .about-us-container03{
    background-image: url("/playground_assets/about_us/about_us_banner_500.png")!important;
    background-repeat: no-repeat;
    background-size: contain;
    height: 250px;
    margin-top: 100px;
  }
  .about-us-container09{
    margin-top: 0;
  }
  .about-us-text{
    display: none;
  }
  .about-us-container07 {
    padding: var(--dl-space-space-unit);
  }

  .about-us-container15 {
    padding: var(--dl-space-space-halfunit);
  }

  .about-us-container20 {
    padding: var(--dl-space-space-halfunit);
  }

  .about-us-container27 {
    padding: var(--dl-space-space-unit);
  }

  .about-us-container28 {
    width: 100%;
  }

  .about-us-container30 {
    width: 100%;
  }

  .about-us-container33 {
    padding: var(--dl-space-space-unit);
  }

  .about-us-container34 {
    width: 100%;
  }

  .about-us-text23 {
    font-size: 40px;
  }

  .about-us-container38 {
    width: 100%;
    height: 550px;
  }

  .about-us-container42 {
    width: 100%;
    height: 550px;
  }

  .about-us-container46 {
    width: 100%;
    height: 550px;
  }

  .about-us-container50 {
    width: 100%;
    height: 550px;
  }

  .about-us-container54 {
    width: 100%;
    height: 550px;
  }
}

@media(max-width: 375px) {
  .about-us-text01 {
    display: none;
  }

  .about-us-container11 {
    width: 100%;
    height: 481px;
  }

  .about-us-container12 {
    width: 273px;
  }

  .about-us-icon {
    width: 45px;
    height: 45px;
  }

  .about-us-icon02 {
    width: 39px;
    height: 39px;
  }

  .about-us-icon04 {
    width: 40px;
    height: 40px;
  }

  .about-us-icon06 {
    width: 50px;
    height: 50px;
  }

  .about-us-image1 {
    height: 351px;
  }

  .about-us-text05 {
    font-size: 16px;
  }

  .about-us-container17 {
    width: 100%;
    height: 481px;
  }

  .about-us-container18 {
    grid-gap: 0;
    gap: 0;
    width: 273px;
    justify-content: space-between;
  }

  .about-us-icon08 {
    width: 45px;
    height: 45px;
  }

  .about-us-icon10 {
    width: 39px;
    height: 39px;
  }

  .about-us-icon12 {
    width: 40px;
    height: 40px;
  }

  .about-us-icon14 {
    width: 50px;
    height: 50px;
  }

  .about-us-image2 {
    height: 351px;
  }

  .about-us-text07 {
    font-size: 16px;
  }

  .about-us-container22 {
    width: 100%;
    height: 481px;
  }

  .about-us-container23 {
    grid-gap: 0;
    gap: 0;
    width: 273px;
    justify-content: space-between;
  }

  .about-us-icon16 {
    width: 45px;
    height: 45px;
  }

  .about-us-icon18 {
    width: 39px;
    height: 39px;
  }

  .about-us-icon20 {
    width: 40px;
    height: 40px;
  }

  .about-us-icon22 {
    width: 50px;
    height: 50px;
  }

  .about-us-image3 {
    height: 351px;
  }

  .about-us-text09 {
    font-size: 16px;
  }

  .about-us-text19 {
    font-size: 16px;
  }

  .about-us-container38 {
    height: 400px;
  }

  .about-us-container42 {
    height: 400px;
  }

  .about-us-container46 {
    height: 400px;
  }

  .about-us-container50 {
    height: 400px;
  }

  .about-us-container54 {
    height: 400px;
  }
}
.outstaffing-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}

.outstaffing-container01 {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  background-size: auto;
  background-image: url('https://iili.io/H78Yaob.png');
  background-repeat: repeat;
  background-position: center;
  background-attachment: fixed;
}

.outstaffing-container02 {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: rgba(0, 0, 0, 0.8);
}

.outstaffing-container03 {
  flex: 0 0 auto;
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  flex-direction: column;
  background-size: cover;
  justify-content: center;
  /* background-image: url('https://images.unsplash.com/photo-1612988952181-c995680479a2?ixid=Mnw5MTMyMXwwfDF8c2VhcmNofDMyfHxibGFja3xlbnwwfHx8fDE2NzUzNTM5MTQ&ixlib=rb-4.0.3&w=1500'); */
  background-image: url('/playground_assets/aboutus_image-1500w.png');
  background-repeat: no-repeat;
  background-position: center;
  background-attachment: fixed;
}

.outstaffing-container04 {
  flex: 0 0 auto;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.65);
}

.outstaffing-text {
  font-size: 10vw;
  font-style: normal;
  transition: 0.3s;
  font-weight: 900;
  text-transform: uppercase;
}

.outstaffing-text:hover {
  opacity: 0.7;
}

.outstaffing-container05 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.outstaffing-container06 {
  flex: 0 0 auto;
  width: 100%;
  height: 100%;
  display: flex;
  padding: var(--dl-space-space-unit);
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.outstaffing-container07 {
  flex: 0 0 auto;
  width: 100%;
  height: 50%;
  display: flex;
  max-width: 1600px;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}

.outstaffing-container08 {
  flex: 0 0 auto;
  width: 50%;
  height: 100%;
  display: flex;
  padding: var(--dl-space-space-twounits);
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.outstaffing-text01 {
  color: var(--dl-color-colors-pink);
  font-size: 75px;
  font-style: normal;
  font-weight: 700;
  text-shadow: 2px 2px #fff;
}

.outstaffing-text02 {
  font-size: 75px;
  font-style: normal;
  font-weight: 700;
}

.outstaffing-container09 {
  flex: 0 0 auto;
  width: 50%;
  height: 100%;
  display: flex;
  padding: var(--dl-space-space-twounits);
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.outstaffing-text06 {
  font-size: 22px;
}

.outstaffing-container10 {
  grid-gap: var(--dl-space-space-sixunits);
  gap: var(--dl-space-space-sixunits);
  flex: 0 0 auto;
  width: 100%;
  height: 50%;
  display: flex;
  padding: var(--dl-space-space-unit);
  max-width: 1600px;
  align-items: flex-start;
  flex-direction: row;
  justify-content: center;
}

.outstaffing-container11 {
  grid-gap: var(--dl-space-space-unit);
  gap: var(--dl-space-space-unit);
  flex: 0 0 auto;
  width: 400px;
  height: 355px;
  display: flex;
  padding: var(--dl-space-space-unit);
  align-items: center;
  border-color: var(--dl-color-colors-neongreen);
  border-style: solid;
  border-width: 2px;
  border-radius: var(--dl-radius-radius-radius8);
  flex-direction: column;
  justify-content: flex-start;
  background-color: rgba(128, 255, 68, 0.2);
}

.outstaffing-text11 {
  color: var(--dl-color-colors-neongreen);
  width: 100%;
  font-size: 36px;
  font-style: normal;
  font-weight: 600;
}

.outstaffing-text15 {
  font-size: 22px;
  font-style: normal;
  font-weight: 300;
}

.outstaffing-container12 {
  grid-gap: var(--dl-space-space-unit);
  gap: var(--dl-space-space-unit);
  flex: 0 0 auto;
  width: 400px;
  height: 355px;
  display: flex;
  padding: var(--dl-space-space-unit);
  align-items: center;
  border-color: var(--dl-color-colors-pink);
  border-style: solid;
  border-width: 2px;
  border-radius: var(--dl-radius-radius-radius8);
  flex-direction: column;
  justify-content: flex-start;
  background-color: rgba(218, 15, 117, 0.2);
}

.outstaffing-text16 {
  color: var(--dl-color-colors-pink);
  width: 100%;
  font-size: 36px;
  font-style: normal;
  font-weight: 600;
}

.outstaffing-text20 {
  font-size: 22px;
  font-style: normal;
  font-weight: 300;
}

.outstaffing-container13 {
  grid-gap: var(--dl-space-space-unit);
  gap: var(--dl-space-space-unit);
  flex: 0 0 auto;
  width: 400px;
  height: 355px;
  display: flex;
  padding: var(--dl-space-space-unit);
  align-items: center;
  border-color: var(--dl-color-colors-orange);
  border-style: solid;
  border-width: 2px;
  border-radius: var(--dl-radius-radius-radius8);
  flex-direction: column;
  justify-content: flex-start;
  background-color: rgba(255, 171, 0, 0.2);
}

.outstaffing-text21 {
  color: var(--dl-color-colors-orange);
  width: 100%;
  font-size: 36px;
  font-style: normal;
  font-weight: 600;
}

.outstaffing-text22 {
  font-size: 22px;
  font-style: normal;
  font-weight: 300;
}

.outstaffing-container14 {
  flex: 0 0 auto;
  width: 100%;
  height: 40vh;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-threeunits);
  padding-left: var(--dl-space-space-sixunits);
  padding-right: var(--dl-space-space-sixunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-threeunits);
  justify-content: center;
}

.outstaffing-container15 {
  flex: 0 0 auto;
  width: 100%;
  height: 100%;
  display: flex;
  max-width: 1400px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-gray-white);
  border-width: 2px;
  flex-direction: column;
  background-size: cover;
  justify-content: center;
  /* background-image: url("https://images.unsplash.com/photo-1609743298373-66d0ef55319a?ixid=Mnw5MTMyMXwwfDF8c2VhcmNofDQwfHxjb29sfGVufDB8fHx8MTY3NTQ1OTg2Ng&ixlib=rb-4.0.3&h=1500"); */
  background-image: url("https://iili.io/HEo3aIV.jpg");
  background-repeat: no-repeat;
  background-position: center;
}

.outstaffing-container15:hover {
  border-width: 3px;
  background-size: cover;
  /* background-image: url("https://images.unsplash.com/photo-1462331940025-496dfbfc7564?ixid=Mnw5MTMyMXwwfDF8c2VhcmNofDEyfHxwbGFuZXR8ZW58MHx8fHwxNjc1NDYyMTIy&ixlib=rb-4.0.3&h=1500"); */
  background-image: url("https://iili.io/HEo35kx.jpg");
}

.outstaffing-container16 {
  flex: 0 0 auto;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.6);
}

.outstaffing-text23 {
  color: transparent;
  font-size: 55px;
  font-style: normal;
  font-weight: 600;
  text-transform: capitalize;
  background-size: cover;
  background-image: url('https://media.tenor.com/lveDtTE8Ek8AAAAM/rainbow-swirls-relaxing.gif');
}

.outstaffing-container17 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.outstaffing-container18 {
  flex: 0 0 auto;
  width: 100%;
  height: 100%;
  display: flex;
  padding: var(--dl-space-space-unit);
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.outstaffing-container19 {
  flex: 0 0 auto;
  width: 100%;
  height: 50%;
  display: flex;
  max-width: 1600px;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}

.outstaffing-container20 {
  flex: 0 0 auto;
  width: 50%;
  height: 100%;
  display: flex;
  padding: var(--dl-space-space-twounits);
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.outstaffing-text28 {
  font-size: 75px;
  font-style: normal;
  font-weight: 700;
  -webkit-text-stroke: 2px #9A20BD;
}

.outstaffing-text29 {
  color: var(--dl-color-colors-purple);
  font-size: 75px;
  font-style: normal;
  font-weight: 700;
  text-shadow: 2px 2px #fff;
  -webkit-text-stroke: 2px #9A20BD;
}

.outstaffing-container21 {
  flex: 0 0 auto;
  width: 50%;
  height: 100%;
  display: flex;
  padding: var(--dl-space-space-twounits);
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.outstaffing-text33 {
  font-size: 22px;
}

.outstaffing-container22 {
  grid-gap: var(--dl-space-space-sixunits);
  gap: var(--dl-space-space-sixunits);
  flex: 0 0 auto;
  width: 100%;
  height: 50%;
  display: flex;
  padding: var(--dl-space-space-unit);
  max-width: 1600px;
  align-items: flex-start;
  flex-direction: row;
  justify-content: center;
}

.outstaffing-container23 {
  overflow: auto;
  grid-gap: var(--dl-space-space-unit);
  gap: var(--dl-space-space-unit);
  flex: 0 0 auto;
  width: 400px;
  height: 355px;
  display: flex;
  padding: var(--dl-space-space-unit);
  align-items: center;
  border-color: var(--dl-color-colors-cyan);
  border-style: solid;
  border-width: 2px;
  border-radius: var(--dl-radius-radius-radius8);
  flex-direction: column;
  justify-content: flex-start;
  background-color: rgba(0, 173, 181, 0.2);
}

.outstaffing-text38 {
  color: var(--dl-color-colors-cyan);
  width: 100%;
  font-size: 36px;
  font-style: normal;
  font-weight: 600;
}

.outstaffing-text39 {
  font-size: 22px;
  font-style: normal;
  font-weight: 300;
}

.outstaffing-container24 {
  overflow: auto;
  grid-gap: var(--dl-space-space-unit);
  gap: var(--dl-space-space-unit);
  flex: 0 0 auto;
  width: 400px;
  height: 355px;
  display: flex;
  padding: var(--dl-space-space-unit);
  align-items: center;
  border-color: var(--dl-color-colors-purple);
  border-style: solid;
  border-width: 2px;
  border-radius: var(--dl-radius-radius-radius8);
  flex-direction: column;
  justify-content: flex-start;
  background-color: rgba(154, 32, 189, 0.2);
}

.outstaffing-text40 {
  color: var(--dl-color-colors-purple);
  width: 100%;
  font-size: 36px;
  font-style: normal;
  font-weight: 600;
}

.outstaffing-text41 {
  font-size: 22px;
  font-style: normal;
  font-weight: 300;
}

.outstaffing-container25 {
  overflow: auto;
  grid-gap: var(--dl-space-space-unit);
  gap: var(--dl-space-space-unit);
  flex: 0 0 auto;
  width: 400px;
  height: 355px;
  display: flex;
  padding: var(--dl-space-space-unit);
  align-items: center;
  border-color: var(--dl-color-colors-skin);
  border-style: solid;
  border-width: 2px;
  border-radius: var(--dl-radius-radius-radius8);
  flex-direction: column;
  justify-content: flex-start;
  background-color: rgba(255, 142, 158, 0.2);
}

.outstaffing-text42 {
  color: var(--dl-color-colors-skin);
  width: 100%;
  font-size: 36px;
  font-style: normal;
  font-weight: 600;
}

.outstaffing-text43 {
  font-size: 22px;
  font-style: normal;
  font-weight: 300;
}

.outstaffing-container26 {
  grid-gap: var(--dl-space-space-threeunits);
  gap: var(--dl-space-space-threeunits);
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  padding: var(--dl-space-space-unit);
  position: relative;
  max-width: 1400px;
  align-items: center;
  margin-bottom: var(--dl-space-space-fourunits);
  flex-direction: column;
  justify-content: center;
}

.outstaffing-container27 {
  grid-gap: var(--dl-space-space-oneandhalfunits);
  gap: var(--dl-space-space-oneandhalfunits);
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}

.outstaffing-container28 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}

.outstaffing-text44 {
  color: var(--dl-color-colors-skin);
  font-size: 75px;
  font-style: normal;
  text-align: left;
  font-weight: 700;
  text-transform: capitalize;
}

.outstaffing-text45 {
  font-size: 75px;
  font-style: normal;
  text-align: left;
  font-weight: 500;
  text-transform: capitalize;
  -webkit-text-stroke: 2px #FF8E9E;
}

.outstaffing-text46 {
  color: var(--dl-color-gray-white);
  width: 100%;
  font-size: 20px;
  align-self: center;
  font-style: normal;
  text-align: left;
  font-weight: 400;
}

.outstaffing-container29 {
  flex: 0 0 auto;
  height: auto;
  display: grid;
  max-width: 1600px;
  align-items: center;
  grid-row-gap: var(--dl-space-space-twounits);
  flex-direction: column;
  grid-column-gap: 60px;
  justify-content: center;
  grid-template-columns: auto auto auto auto;
}

.outstaffing-container30 {
  grid-gap: var(--dl-space-space-unit);
  gap: var(--dl-space-space-unit);
  flex: 0 0 auto;
  width: 300px;
  height: 50px;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
}

.outstaffing-container31 {
  width: 52px;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.outstaffing-icon {
  fill: var(--dl-color-colors-orange);
  width: 24px;
  height: 24px;
}

.outstaffing-text47 {
  height: auto;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
}

.outstaffing-container32 {
  grid-gap: var(--dl-space-space-unit);
  gap: var(--dl-space-space-unit);
  flex: 0 0 auto;
  width: 300px;
  height: 50px;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
}

.outstaffing-container33 {
  width: 52px;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.outstaffing-icon02 {
  fill: var(--dl-color-colors-cyan);
  width: 24px;
  height: 24px;
}

.outstaffing-text48 {
  height: auto;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
}

.outstaffing-container34 {
  grid-gap: var(--dl-space-space-unit);
  gap: var(--dl-space-space-unit);
  flex: 0 0 auto;
  width: 300px;
  height: 50px;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
}

.outstaffing-container35 {
  width: 52px;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.outstaffing-icon04 {
  fill: var(--dl-color-colors-pink);
  width: 24px;
  height: 24px;
}

.outstaffing-text49 {
  height: auto;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
}

.outstaffing-container36 {
  grid-gap: var(--dl-space-space-unit);
  gap: var(--dl-space-space-unit);
  flex: 0 0 auto;
  width: 300px;
  height: 50px;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
}

.outstaffing-container37 {
  width: 52px;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.outstaffing-icon06 {
  fill: var(--dl-color-colors-neongreen);
  width: 24px;
  height: 24px;
}

.outstaffing-text50 {
  height: auto;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
}

.outstaffing-container38 {
  grid-gap: var(--dl-space-space-unit);
  gap: var(--dl-space-space-unit);
  flex: 0 0 auto;
  width: 300px;
  height: 50px;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
}

.outstaffing-container39 {
  width: 52px;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.outstaffing-icon08 {
  fill: var(--dl-color-colors-orange);
  width: 24px;
  height: 24px;
}

.outstaffing-text51 {
  height: auto;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
}

.outstaffing-container40 {
  grid-gap: var(--dl-space-space-unit);
  gap: var(--dl-space-space-unit);
  flex: 0 0 auto;
  width: 300px;
  height: 50px;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
}

.outstaffing-container41 {
  width: 52px;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.outstaffing-icon10 {
  fill: var(--dl-color-colors-cyan);
  width: 24px;
  height: 24px;
}

.outstaffing-text52 {
  height: auto;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
}

.outstaffing-container42 {
  grid-gap: var(--dl-space-space-unit);
  gap: var(--dl-space-space-unit);
  flex: 0 0 auto;
  width: 300px;
  height: 50px;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
}

.outstaffing-container43 {
  width: 52px;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.outstaffing-icon12 {
  fill: var(--dl-color-colors-pink);
  width: 24px;
  height: 24px;
}

.outstaffing-text53 {
  height: auto;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
}

.outstaffing-container44 {
  grid-gap: var(--dl-space-space-unit);
  gap: var(--dl-space-space-unit);
  flex: 0 0 auto;
  width: 300px;
  height: 50px;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
}

.outstaffing-container45 {
  width: 52px;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.outstaffing-icon14 {
  fill: var(--dl-color-colors-neongreen);
  width: 24px;
  height: 24px;
}

.outstaffing-text54 {
  height: auto;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
}

.outstaffing-container46 {
  grid-gap: var(--dl-space-space-unit);
  gap: var(--dl-space-space-unit);
  flex: 0 0 auto;
  width: 300px;
  height: 50px;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
}

.outstaffing-container47 {
  width: 52px;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.outstaffing-icon16 {
  fill: var(--dl-color-colors-orange);
  width: 24px;
  height: 24px;
}

.outstaffing-text55 {
  height: auto;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
}

.outstaffing-container48 {
  grid-gap: var(--dl-space-space-unit);
  gap: var(--dl-space-space-unit);
  flex: 0 0 auto;
  width: 300px;
  height: 50px;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
}

.outstaffing-container49 {
  width: 52px;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.outstaffing-icon18 {
  fill: var(--dl-color-colors-cyan);
  width: 24px;
  height: 24px;
}

.outstaffing-text56 {
  height: auto;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
}

.outstaffing-container50 {
  grid-gap: var(--dl-space-space-unit);
  gap: var(--dl-space-space-unit);
  flex: 0 0 auto;
  width: 300px;
  height: 50px;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
}

.outstaffing-container51 {
  width: 52px;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.outstaffing-icon20 {
  fill: var(--dl-color-colors-pink);
  width: 24px;
  height: 24px;
}

.outstaffing-text57 {
  height: auto;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
}

@media(max-width: 1400px) {
  .outstaffing-container10 {
    grid-gap: var(--dl-space-space-twounits);
    gap: var(--dl-space-space-twounits);
  }

  .outstaffing-container22 {
    grid-gap: var(--dl-space-space-twounits);
    gap: var(--dl-space-space-twounits);
  }

  .outstaffing-container29 {
    grid-template-columns: auto auto auto;
  }
}

@media(max-width: 991px) {
  .outstaffing-container05 {
    height: auto;
    padding-top: var(--dl-space-space-fiveunits);
    padding-bottom: var(--dl-space-space-fiveunits);
  }

  .outstaffing-container06 {
    grid-gap: var(--dl-space-space-twounits);
    gap: var(--dl-space-space-twounits);
  }

  .outstaffing-container07 {
    flex-direction: column;
  }

  .outstaffing-container08 {
    width: 100%;
    align-items: flex-start;
  }

  .outstaffing-container09 {
    width: 100%;
  }

  .outstaffing-container10 {
    grid-gap: var(--dl-space-space-threeunits);
    gap: var(--dl-space-space-threeunits);
    flex-direction: column;
  }

  .outstaffing-container11 {
    width: 100%;
  }

  .outstaffing-container12 {
    width: 100%;
  }

  .outstaffing-container13 {
    width: 100%;
  }

  .outstaffing-container14 {
    height: 40vh;
    padding-top: var(--dl-space-space-fiveunits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }

  .outstaffing-container17 {
    height: auto;
    padding-top: var(--dl-space-space-fiveunits);
    padding-bottom: var(--dl-space-space-fiveunits);
  }

  .outstaffing-container18 {
    grid-gap: var(--dl-space-space-twounits);
    gap: var(--dl-space-space-twounits);
  }

  .outstaffing-container19 {
    flex-direction: column;
  }

  .outstaffing-container20 {
    width: 100%;
    align-items: flex-start;
  }

  .outstaffing-container21 {
    width: 100%;
  }

  .outstaffing-container22 {
    flex-direction: column;
  }

  .outstaffing-container23 {
    width: 100%;
  }

  .outstaffing-container24 {
    width: 100%;
  }

  .outstaffing-container25 {
    width: 100%;
  }

  .outstaffing-container26 {
    grid-gap: var(--dl-space-space-fourunits);
    gap: var(--dl-space-space-fourunits);
  }

  .outstaffing-container29 {
    grid-template-columns: auto auto;
  }
}

@media(max-width: 767px) {
  .outstaffing-text01 {
    font-size: 55px;
  }

  .outstaffing-text02 {
    font-size: 55px;
  }

  .outstaffing-text06 {
    font-size: 18px;
  }

  .outstaffing-text15 {
    font-size: 18px;
  }

  .outstaffing-text20 {
    font-size: 18px;
  }

  .outstaffing-text22 {
    font-size: 18px;
  }

  .outstaffing-text23 {
    width: 100%;
    height: auto;
    font-size: 35px;
    text-align: center;
  }

  .outstaffing-text28 {
    font-size: 55px;
  }

  .outstaffing-text29 {
    font-size: 55px;
  }

  .outstaffing-text33 {
    font-size: 18px;
  }

  .outstaffing-text39 {
    font-size: 18px;
  }

  .outstaffing-text41 {
    font-size: 18px;
  }

  .outstaffing-text43 {
    font-size: 18px;
  }

  .outstaffing-text44 {
    font-size: 55px;
  }

  .outstaffing-text45 {
    font-size: 55px;
  }

  .outstaffing-container29 {
    width: auto;
    grid-template-columns: auto;
  }
}

@media(max-width: 479px) {
  .outstaffing-text46 {
    font-size: 18px;
  }

  .outstaffing-container03 {
    background-attachment: initial;
  }

  .outstaffing-container08 {
    padding: var(--dl-space-space-halfunit);
  }

  .outstaffing-text01 {
    font-size: 45px;
  }

  .outstaffing-text02 {
    font-size: 45px;
  }

  .outstaffing-container09 {
    padding: var(--dl-space-space-halfunit);
  }

  .outstaffing-container20 {
    padding: var(--dl-space-space-halfunit);
  }

  .outstaffing-text28 {
    font-size: 45px;
  }

  .outstaffing-text29 {
    font-size: 45px;
  }

  .outstaffing-container21 {
    padding: var(--dl-space-space-halfunit);
  }

  .outstaffing-text44 {
    font-size: 45px;
  }

  .outstaffing-text45 {
    font-size: 45px;
  }
}
.game-development-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
.game-development-container01 {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  background-size: contain;
  background-image: url('https://iili.io/H78Yaob.png');
  background-repeat: repeat;
  background-position: center;
}
.game-development-container02 {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: rgba(0, 0, 0, 0.75);
}
.game-development-hero {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  background-size: cover;
  justify-content: center;
  background-image: url('https://iili.io/HEo0dtp.png');
  background-position: center;
  background-attachment: fixed;
}
.game-development-container03 {
  grid-gap: var(--dl-space-space-unit);
  gap: var(--dl-space-space-unit);
  flex: 0 0 auto;
  width: 100%;
  height: 100vh;
  display: flex;
  padding: var(--dl-space-space-unit);
  max-width: 100%;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.6);
}
.game-development-text {
  color: var(--dl-color-gray-white);
  font-size: 55px;
  font-style: normal;
  text-align: center;
  font-weight: 700;
}
.game-development-text004 {
  color: var(--dl-color-gray-white);
  font-size: 24px;
  font-style: normal;
  text-align: center;
  font-weight: 400;
}
.game-development-container04 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  padding: var(--dl-space-space-unit);
  max-width: 1600px;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.game-development-container05 {
  width: 50%;
  height: 100%;
  display: flex;
  padding: var(--dl-space-space-twounits);
  align-items: center;
  flex-direction: row;
  justify-content: flex-end;
}
.game-development-text008 h1 {
  font-size: 75px;
  font-style: normal;
  text-align: right;
  font-weight: 700;
  text-transform: capitalize;
  transition: 0.3s ease-in;
}
.game-development-text008:hover{
  color: var(--dl-color-colors-pink);
  transition: 0.3s ease-out;
}
.game-development-container06 {
  width: 50%;
  height: 100%;
  display: flex;
  padding: var(--dl-space-space-twounits);
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.game-development-text014 {
  color: var(--dl-color-gray-white);
  width: auto;
  font-size: 22px;
  font-style: normal;
  text-align: left;
  font-weight: 400;
}
.game-development-text016 {
  color: var(--dl-color-gray-white);
  font-style: normal;
  font-weight: 400;
}
.game-development-container07 {
  grid-gap: var(--dl-space-space-twounits);
  gap: var(--dl-space-space-twounits);
  flex: 0 0 auto;
  width: 100%;
  height: 80vh;
  display: flex;
  padding: var(--dl-space-space-unit);
  position: relative;
  max-width: 1600px;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.game-development-container08 {
  grid-gap: var(--dl-space-space-unit);
  gap: var(--dl-space-space-unit);
  flex: 0 0 auto;
  width: 30%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.game-development-text019 {
  color: var(--dl-color-colors-cyan);
  width: 100%;
  font-size: 35px;
  font-style: normal;
  text-align: left;
  font-weight: 700;
}
.game-development-text021 {
  font-size: 35px;
  font-style: normal;
  font-weight: 700;
}
.game-development-text023 {
  color: var(--dl-color-gray-white);
  width: 100%;
  font-size: 20px;
  align-self: center;
  font-style: normal;
  text-align: left;
  font-weight: 400;
}
.game-development-container09 {
  grid-gap: var(--dl-space-space-unit);
  gap: var(--dl-space-space-unit);
  flex: 0 0 auto;
  width: 30%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.game-development-text024 {
  color: var(--dl-color-colors-orange);
  width: 100%;
  font-size: 35px;
  font-style: normal;
  text-align: left;
  font-weight: 700;
}
.game-development-text028 {
  color: var(--dl-color-gray-white);
  width: 100%;
  font-size: 20px;
  align-self: center;
  font-style: normal;
  text-align: left;
  font-weight: 400;
}
.game-development-container10 {
  grid-gap: var(--dl-space-space-unit);
  gap: var(--dl-space-space-unit);
  flex: 0 0 auto;
  width: 30%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.game-development-text029 {
  color: var(--dl-color-colors-neongreen);
  width: 100%;
  font-size: 35px;
  font-style: normal;
  text-align: left;
  font-weight: 700;
}
.game-development-text033 {
  color: var(--dl-color-gray-white);
  width: 100%;
  font-size: 20px;
  align-self: center;
  font-style: normal;
  text-align: left;
  font-weight: 400;
}
.game-development-container11 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  padding: var(--dl-space-space-unit);
  max-width: 1600px;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.game-development-container12 {
  width: 50%;
  height: 100%;
  display: flex;
  padding: var(--dl-space-space-twounits);
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.game-development-text034 {
  color: var(--dl-color-gray-white);
  width: auto;
  font-size: 22px;
  font-style: normal;
  text-align: left;
  font-weight: 400;
}
.game-development-text036 {
  color: var(--dl-color-gray-white);
  font-style: normal;
  font-weight: 400;
}
.game-development-container13 {
  width: 50%;
  height: 100%;
  display: flex;
  padding: var(--dl-space-space-twounits);
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
}
.game-development-text039 {
  font-size: 75px;
  font-style: normal;
  text-align: left;
  font-weight: 700;
  text-transform: capitalize;
  transition: 0.3s ease-in;
}
.game-development-text039:hover{
  color: var(--dl-color-colors-pink);
  transition: 0.3s ease-out;
}
.game-development-container14 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  padding: var(--dl-space-space-unit);
  max-width: 1600px;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.game-development-container15 {
  width: 50%;
  height: 100%;
  display: flex;
  padding: var(--dl-space-space-twounits);
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
}
.game-development-text047 {
  font-size: 75px;
  font-style: normal;
  text-align: left;
  font-weight: 700;
  text-transform: capitalize;
  transition: 0.3s ease-in;
}
.game-development-text047:hover{
  color: var(--dl-color-colors-pink);
  transition: 0.3s ease-out;
}
.game-development-container16 {
  width: 50%;
  height: 100%;
  display: flex;
  padding: var(--dl-space-space-twounits);
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.game-development-text055 {
  color: var(--dl-color-gray-white);
  width: auto;
  font-size: 22px;
  font-style: normal;
  text-align: left;
  font-weight: 400;
}
.game-development-text057 {
  color: var(--dl-color-gray-white);
  font-style: normal;
  font-weight: 400;
}
.game-development-container17 {
  flex: 0 0 auto;
  width: 100%;
  height: 40vh;
  display: flex;
  align-items: center;
  flex-direction: column;
  background-size: cover;
  justify-content: center;
  background-image: url('https://media.tenor.com/ju5hhb03JgIAAAAC/dark.gif');
  background-repeat: no-repeat;
  background-position: center;
  background-attachment: fixed;
}
.game-development-container18 {
  flex: 0 0 auto;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.61);
}
.game-development-container19 {
  grid-gap: var(--dl-space-space-twounits);
  gap: var(--dl-space-space-twounits);
  flex: 0 0 auto;
  width: 100%;
  height: 100%;
  display: flex;
  max-width: 1600px;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.game-development-text060 {
  font-size: 45px;
  font-style: normal;
  text-align: center;
  font-weight: 400;
}
.game-development-container20 {
  flex: 0 0 auto;
  width: 150px;
  height: 38px;
  display: flex;
  align-items: center;
  border-color: var(--dl-color-colors-pink);
  border-width: 1px;
  border-radius: 50px;
  flex-direction: column;
  justify-content: center;
}
.game-development-text064 {
  transition: 0.3s;
}
.game-development-text064:hover {
  color: var(--dl-color-colors-pink);
}
.game-development-container21 {
  grid-gap: var(--dl-space-space-fiveunits);
  gap: var(--dl-space-space-fiveunits);
  flex: 0 0 auto;
  width: 100%;
  height: 80vh;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.game-development-container22 {
  grid-gap: var(--dl-space-space-unit);
  gap: var(--dl-space-space-unit);
  width: auto;
  display: flex;
  transition: 0.3s;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.game-development-container22:hover {
  color: var(--dl-color-colors-pink);
}
.game-development-text065 {
  width: 330px;
  font-size: 55px;
  font-style: normal;
  text-align: right;
  font-weight: 700;
}
.game-development-text066 {
  width: 330px;
  font-size: 55px;
  font-style: normal;
  text-align: center;
  font-weight: 700;
}
.game-development-text067 {
  width: 330px;
  font-size: 55px;
  font-style: normal;
  text-align: left;
  font-weight: 700;
}
.game-development-text070 {
  font-size: 115px;
}
.game-development-container23 {
  flex: 0 0 auto;
  width: auto;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.game-development-text074 {
  font-size: 22px;
  text-align: center;
}
.game-development-container24 {
  grid-gap: var(--dl-space-space-twounits);
  gap: var(--dl-space-space-twounits);
  flex: 0 0 auto;
  width: 100%;
  height: 40vh;
  display: flex;
  padding: var(--dl-space-space-unit);
  max-width: 1600px;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.game-development-container25 {
  grid-gap: var(--dl-space-space-unit);
  gap: var(--dl-space-space-unit);
  flex: 0 0 auto;
  width: 30%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.game-development-text080 {
  color: var(--dl-color-colors-cyan);
  width: 100%;
  font-size: 35px;
  font-style: normal;
  text-align: left;
  font-weight: 700;
}
.game-development-text081 {
  color: var(--dl-color-gray-white);
  width: 100%;
  font-size: 20px;
  align-self: center;
  font-style: normal;
  text-align: left;
  font-weight: 400;
}
.game-development-container26 {
  grid-gap: var(--dl-space-space-unit);
  gap: var(--dl-space-space-unit);
  flex: 0 0 auto;
  width: 30%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.game-development-text082 {
  color: var(--dl-color-colors-orange);
  width: 100%;
  font-size: 35px;
  font-style: normal;
  text-align: left;
  font-weight: 700;
}
.game-development-text083 {
  color: var(--dl-color-gray-white);
  width: 100%;
  font-size: 20px;
  align-self: center;
  font-style: normal;
  text-align: left;
  font-weight: 400;
}
.game-development-container27 {
  grid-gap: var(--dl-space-space-unit);
  gap: var(--dl-space-space-unit);
  flex: 0 0 auto;
  width: 30%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.game-development-text084 {
  color: var(--dl-color-colors-neongreen);
  width: 100%;
  font-size: 35px;
  font-style: normal;
  text-align: left;
  font-weight: 700;
}
.game-development-text085 {
  color: var(--dl-color-gray-white);
  width: 100%;
  font-size: 20px;
  align-self: center;
  font-style: normal;
  text-align: left;
  font-weight: 400;
}
.game-development-container28 {
  grid-gap: var(--dl-space-space-threeunits);
  gap: var(--dl-space-space-threeunits);
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  padding: var(--dl-space-space-unit);
  position: relative;
  max-width: 1600px;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.game-development-container29 {
  grid-gap: var(--dl-space-space-oneandhalfunits);
  gap: var(--dl-space-space-oneandhalfunits);
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.game-development-text086 h2 {
  font-size: 75px;
  font-style: normal;
  text-align: left;
  font-weight: 700;
  text-transform: capitalize;
}
.game-development-text090 {
  color: var(--dl-color-gray-white);
  width: 100%;
  font-size: 20px;
  align-self: center;
  font-style: normal;
  text-align: left;
  font-weight: 400;
}
.game-development-container30 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: grid;
  max-width: 1600px;
  align-items: center;
  grid-row-gap: var(--dl-space-space-twounits);
  flex-direction: column;
  grid-column-gap: 110px;
  justify-content: center;
  grid-template-columns: auto auto auto auto;
}
.game-development-container31 {
  grid-gap: var(--dl-space-space-unit);
  gap: var(--dl-space-space-unit);
  flex: 0 0 auto;
  width: 300px;
  height: 50px;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
}
.game-development-container32 {
  width: 52px;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.game-development-icon {
  fill: var(--dl-color-colors-orange);
  width: 30px;
  height: 30px;
}
.game-development-text091 {
  height: auto;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
}
.game-development-container33 {
  grid-gap: var(--dl-space-space-unit);
  gap: var(--dl-space-space-unit);
  flex: 0 0 auto;
  width: 300px;
  height: 50px;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
}
.game-development-container34 {
  width: 52px;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.game-development-icon02 {
  fill: var(--dl-color-colors-cyan);
  width: 30px;
  height: 30px;
}
.game-development-text092 {
  height: auto;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
}
.game-development-container35 {
  grid-gap: var(--dl-space-space-unit);
  gap: var(--dl-space-space-unit);
  flex: 0 0 auto;
  width: 300px;
  height: 50px;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
}
.game-development-container36 {
  width: 52px;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.game-development-icon04 {
  fill: var(--dl-color-colors-pink);
  width: 30px;
  height: 30px;
}
.game-development-text093 {
  height: auto;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
}
.game-development-container37 {
  grid-gap: var(--dl-space-space-unit);
  gap: var(--dl-space-space-unit);
  flex: 0 0 auto;
  width: 300px;
  height: 50px;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
}
.game-development-container38 {
  width: 52px;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.game-development-icon06 {
  fill: var(--dl-color-colors-neongreen);
  width: 30px;
  height: 30px;
}
.game-development-text094 {
  height: auto;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
}
.game-development-container39 {
  grid-gap: var(--dl-space-space-unit);
  gap: var(--dl-space-space-unit);
  flex: 0 0 auto;
  width: 300px;
  height: 50px;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
}
.game-development-container40 {
  width: 52px;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.game-development-icon08 {
  fill: var(--dl-color-colors-orange);
  width: 30px;
  height: 30px;
}
.game-development-text095 {
  height: auto;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
}
.game-development-container41 {
  grid-gap: var(--dl-space-space-unit);
  gap: var(--dl-space-space-unit);
  flex: 0 0 auto;
  width: 300px;
  height: 50px;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
}
.game-development-container42 {
  width: 52px;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.game-development-icon10 {
  fill: var(--dl-color-colors-cyan);
  width: 30px;
  height: 30px;
}
.game-development-text096 {
  height: auto;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
}
.game-development-container43 {
  grid-gap: var(--dl-space-space-unit);
  gap: var(--dl-space-space-unit);
  flex: 0 0 auto;
  width: 300px;
  height: 50px;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
}
.game-development-container44 {
  width: 52px;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.game-development-icon12 {
  fill: var(--dl-color-colors-pink);
  width: 30px;
  height: 30px;
}
.game-development-text097 {
  height: auto;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
}
.game-development-container45 {
  grid-gap: var(--dl-space-space-unit);
  gap: var(--dl-space-space-unit);
  flex: 0 0 auto;
  width: 300px;
  height: 50px;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
}
.game-development-container46 {
  width: 52px;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.game-development-icon14 {
  fill: var(--dl-color-colors-neongreen);
  width: 30px;
  height: 30px;
}
.game-development-text098 {
  height: auto;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
}
.game-development-container47 {
  grid-gap: var(--dl-space-space-unit);
  gap: var(--dl-space-space-unit);
  flex: 0 0 auto;
  width: 300px;
  height: 50px;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
}
.game-development-container48 {
  width: 52px;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.game-development-icon16 {
  fill: var(--dl-color-colors-orange);
  width: 30px;
  height: 30px;
}
.game-development-text099 {
  height: auto;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
}
.game-development-container49 {
  grid-gap: var(--dl-space-space-unit);
  gap: var(--dl-space-space-unit);
  flex: 0 0 auto;
  width: 300px;
  height: 50px;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
}
.game-development-container50 {
  width: 52px;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.game-development-icon19 {
  fill: var(--dl-color-colors-cyan);
  width: 30px;
  height: 30px;
}
.game-development-text100 {
  height: auto;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
}
.game-development-container51 {
  grid-gap: var(--dl-space-space-unit);
  gap: var(--dl-space-space-unit);
  flex: 0 0 auto;
  width: 300px;
  height: 50px;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
}
.game-development-container52 {
  width: 52px;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.game-development-icon21 {
  fill: var(--dl-color-colors-pink);
  width: 30px;
  height: 30px;
}
.game-development-text101 {
  height: auto;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
}
.game-development-container53 {
  grid-gap: var(--dl-space-space-unit);
  gap: var(--dl-space-space-unit);
  flex: 0 0 auto;
  width: 300px;
  height: 50px;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
}
.game-development-container54 {
  width: 52px;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.game-development-icon23 {
  fill: var(--dl-color-colors-neongreen);
  width: 30px;
  height: 30px;
}
.game-development-text102 {
  height: auto;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
}
.game-development-container55 {
  grid-gap: var(--dl-space-space-unit);
  gap: var(--dl-space-space-unit);
  flex: 0 0 auto;
  width: 300px;
  height: 50px;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
}
.game-development-container56 {
  width: 52px;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.game-development-icon25 {
  fill: var(--dl-color-colors-orange);
  width: 30px;
  height: 30px;
}
.game-development-text103 {
  height: auto;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
}
.game-development-container57 {
  grid-gap: var(--dl-space-space-unit);
  gap: var(--dl-space-space-unit);
  flex: 0 0 auto;
  width: 300px;
  height: 50px;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
}
.game-development-container58 {
  width: 52px;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.game-development-icon27 {
  fill: var(--dl-color-colors-cyan);
  width: 30px;
  height: 30px;
}
.game-development-text104 {
  height: auto;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
}
.game-development-container59 {
  grid-gap: var(--dl-space-space-unit);
  gap: var(--dl-space-space-unit);
  flex: 0 0 auto;
  width: 300px;
  height: 50px;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
}
.game-development-container60 {
  width: 52px;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.game-development-icon29 {
  fill: var(--dl-color-colors-pink);
  width: 30px;
  height: 30px;
}
.game-development-text105 {
  height: auto;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
}
.game-development-container61 {
  grid-gap: var(--dl-space-space-unit);
  gap: var(--dl-space-space-unit);
  flex: 0 0 auto;
  width: 300px;
  height: 50px;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
}
.game-development-container62 {
  width: 52px;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.game-development-icon31 {
  fill: var(--dl-color-colors-neongreen);
  width: 30px;
  height: 30px;
}
.game-development-text106 {
  height: auto;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
}
@media(max-width: 1400px) {
  .game-development-container30 {
    grid-template-columns: auto auto auto;
  }
}
@media(max-width: 991px) {
  .game-development-text {
    font-size: 45px;
  }
  .game-development-text004 {
    display: none;
  }
  .game-development-container04 {
    flex-direction: column;
  }
  .game-development-container05 {
    width: 100%;
    padding: var(--dl-space-space-unit);
    justify-content: flex-start;
  }
  .game-development-text008 {
    text-align: left;
  }
  .game-development-container06 {
    width: 100%;
    padding: var(--dl-space-space-unit);
  }
  .game-development-container07 {
    height: auto;
    flex-direction: column;
  }
  .game-development-container08 {
    width: 100%;
  }
  .game-development-container09 {
    width: 100%;
  }
  .game-development-container10 {
    width: 100%;
  }
  .game-development-container11 {
    flex-direction: row;
  }
  .game-development-container14 {
    display: none;
    flex-direction: column;
  }
  .game-development-container15 {
    width: 100%;
  }
  .game-development-container16 {
    width: 100%;
  }
  .game-development-container21 {
    height: auto;
  }
  .game-development-container22 {
    display: none;
  }
  .game-development-text066 {
    width: auto;
  }
  .game-development-container24 {
    height: auto;
    flex-direction: column;
  }
  .game-development-container25 {
    width: 100%;
  }
  .game-development-container26 {
    width: 100%;
  }
  .game-development-container27 {
    width: 100%;
  }
  .game-development-container30 {
    grid-template-columns: auto auto;
  }
}
@media(max-width: 767px) {
  .game-development-text008 {
    font-size: 55px;
  }
  .game-development-container11 {
    display: none;
  }
  .game-development-container14 {
    display: flex;
  }
  .game-development-text047 {
    font-size: 55px;
  }
  .game-development-text060 {
    font-size: 35px;
  }
  .game-development-container21 {
    padding: var(--dl-space-space-unit);
  }
  .game-development-text074 {
    display: none;
    text-align: center;
  }
  .game-development-text086 h2 {
    font-size: 55px;
  }
  .game-development-container30 {
    grid-template-columns: auto;
  }
}
@media(max-width: 479px) {
  .game-development-text090{
    font-size: 18px;
  }
  .game-development-container14{
    padding: 0;
  }
  .game-development-container04{
    padding: 0;
  }
  .game-development-hero{
    background-attachment: initial;
  }
  .game-development-container17{
    background-attachment: initial;
  }
  .game-development-text008 {
    font-size: 45px;
  }
  .game-development-text014 {
    font-size: 18px;
  }
  .game-development-text023 {
    font-size: 16px;
  }
  .game-development-text028 {
    font-size: 16px;
  }
  .game-development-text033 {
    font-size: 16px;
  }
  .game-development-container15 {
    padding: var(--dl-space-space-unit);
  }
  .game-development-text047 {
    font-size: 45px;
  }
  .game-development-container16 {
    padding: var(--dl-space-space-unit);
  }
  .game-development-text055 {
    font-size: 18px;
  }
  .game-development-text081 {
    font-size: 16px;
  }
  .game-development-text083 {
    font-size: 16px;
  }
  .game-development-text085 {
    font-size: 16px;
  }
  .game-development-text086 h2 {
    font-size: 45px;
  }
}


.somebutton {
  border: 2px solid white;
  width: 100px;
  height: 100px;
}

.our-apps-container {
  width: 100%;
  height: 100vh;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: column;
  background-size: cover;
  background-image: url('https://c4.wallpaperflare.com/wallpaper/109/664/851/black-texture-wallpaper-preview.jpg');
  background-attachment: fixed;
}

.our-apps-container01 {
  grid-gap: var(--dl-space-space-fiveunits);
  gap: var(--dl-space-space-fiveunits);
  flex: 0 0 auto;
  width: 100%;
  height: 100%;
  display: flex;
  padding: var(--dl-space-space-unit);
  position: relative;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.81);
}

.our-apps-header {
  grid-gap: var(--dl-space-space-unit);
  gap: var(--dl-space-space-unit);
  width: 100%;
  display: flex;
  max-width: 1200px;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}

.our-apps-left {
  width: 100%;
  display: flex;
  max-width: 600px;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.our-apps-heading {
  color: transparent;
  width: 100%;
  font-size: 52px;
  text-align: left;
  font-family: Poppins;
  font-weight: 700;
  text-transform: none;
  background-size: cover;
  text-decoration: none;
  background-image: url('https://media.tenor.com/lveDtTE8Ek8AAAAM/rainbow-swirls-relaxing.gif');
}

.our-apps-container02 {
  display: grid;
  grid-gap: var(--dl-space-space-twounits);
  align-items: center;
  flex-direction: row;
  justify-content: center;
  grid-template-columns: auto auto auto;
}

.our-apps-app-circle {
  flex: 0 0 auto;
  width: 300px;
  cursor: pointer;
  height: 300px;
  display: flex;
  transition: 0.3s;
  align-items: center;
  border-radius: var(--dl-radius-radius-round);
  flex-direction: column;
  background-size: cover;
  justify-content: center;
  /* background-image: url('https://images.pexels.com/photos/1342650/pexels-photo-1342650.jpeg?auto=compress&cs=tinysrgb&w=1600'); */
  /* background-attachment: fixed; */
  background-color: black;
}

.our-apps-app-circle:hover {
  width: 350px;
  height: 350px;
}

.our-apps-gradient {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  border-radius: var(--dl-radius-radius-round);
  flex-direction: column;
  justify-content: center;
  background-image: linear-gradient(135deg, rgba(252, 0, 255, 0.57) 0.00%, rgba(0, 219, 222, 0.6) 100.00%);
}

.our-apps-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: var(--dl-radius-radius-round);
}

.our-apps-app-circle1 {
  flex: 0 0 auto;
  width: 300px;
  cursor: pointer;
  height: 300px;
  display: flex;
  transition: 0.3s;
  align-items: center;
  border-radius: var(--dl-radius-radius-round);
  flex-direction: column;
  background-size: cover;
  justify-content: center;
  /* background-image: url('https://images.pexels.com/photos/1342650/pexels-photo-1342650.jpeg?auto=compress&cs=tinysrgb&w=1600'); */
  background-attachment: fixed;
}

.our-apps-app-circle1:hover {
  width: 350px;
  height: 350px;
}

.our-apps-gradient1 {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  border-radius: var(--dl-radius-radius-round);
  flex-direction: column;
  justify-content: center;
  background-image: linear-gradient(135deg, rgba(219, 19, 20, 0.62) 0.00%, rgba(210, 16, 130, 0.63) 100.00%);
}

.our-apps-image01 {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: var(--dl-radius-radius-round);
}

.our-apps-app-circle2 {
  flex: 0 0 auto;
  width: 300px;
  cursor: pointer;
  height: 300px;
  display: flex;
  transition: 0.3s;
  align-items: center;
  border-radius: var(--dl-radius-radius-round);
  flex-direction: column;
  background-size: cover;
  justify-content: center;
  /* background-image: url('https://images.pexels.com/photos/1342650/pexels-photo-1342650.jpeg?auto=compress&cs=tinysrgb&w=1600'); */
  background-attachment: fixed;
}

.our-apps-app-circle2:hover {
  width: 350px;
  height: 350px;
}

.our-apps-gradient2 {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  border-radius: var(--dl-radius-radius-round);
  flex-direction: column;
  justify-content: center;
  background-image: linear-gradient(135deg, rgba(0, 255, 17, 0.55) 0.00%, rgba(243, 255, 0, 0.6) 100.00%);
}

.our-apps-image02 {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: var(--dl-radius-radius-round);
}

.our-apps-pop-up-ludo {
  top: 0;
  left: 0;
  right: 0;
  width: 1116px;
  bottom: 0;
  height: 627px;
  margin: auto auto;
  display: flex;
  padding: var(--dl-space-space-twounits);
  z-index: 200;
  position: fixed;
  align-items: flex-start;
  border-radius: var(--dl-radius-radius-radius8);
  flex-direction: row;
  background-color: rgba(0, 0, 0, 0.88);
}

.our-apps-close-button {
  top: 5px;
  flex: 0 0 auto;
  right: 5px;
  width: 40px;
  cursor: pointer;
  height: 40px;
  display: flex;
  position: absolute;
  align-items: center;
  border-radius: var(--dl-radius-radius-round);
  flex-direction: column;
  justify-content: center;
  background-color: #000000;
}

.our-apps-icon {
  fill: #ffffff;
  width: 80%;
  height: 80%;
}

.our-apps-container03 {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  border-radius: var(--dl-radius-radius-radius8);
  flex-direction: row;
  justify-content: center;
}

.our-apps-container04 {
  grid-gap: var(--dl-space-space-oneandhalfunits);
  gap: var(--dl-space-space-oneandhalfunits);
  flex: 0 0 auto;
  width: 50%;
  height: 100%;
  display: flex;
  padding: var(--dl-space-space-twounits);
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.our-apps-text {
  color: transparent;
  width: 100%;
  text-align: left;
  background-size: cover;
  background-image: url('https://media.tenor.com/lveDtTE8Ek8AAAAM/rainbow-swirls-relaxing.gif');
}

.our-apps-text1 {
  color: #dcd5d5;
  width: 100%;
}

.our-apps-download-now {
  grid-gap: var(--dl-space-space-halfunit);
  gap: var(--dl-space-space-halfunit);
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

.our-apps-text2 {
  font-weight: 700;
}

.our-apps-container05 {
  grid-gap: var(--dl-space-space-unit);
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}

.our-apps-link {
  display: contents;
}

.our-apps-image03 {
  width: auto;
  height: auto;
  object-fit: cover;
  text-decoration: none;
}

.our-apps-image04 {
  width: auto;
  height: auto;
  display: none;
  object-fit: cover;
}

.our-apps-container06 {
  flex: 0 0 auto;
  width: 50%;
  height: 100%;
  display: flex;
  padding: var(--dl-space-space-twounits);
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.our-apps-background {
  flex: 0 0 auto;
  width: 400px;
  height: 400px;
  display: flex;
  align-items: center;
  border-radius: var(--dl-radius-radius-round);
  flex-direction: column;
  justify-content: center;
}

.our-apps-gradient3 {
  flex: 0 0 auto;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  border-radius: var(--dl-radius-radius-round);
  flex-direction: column;
  justify-content: center;
  background-image: linear-gradient(135deg, rgba(252, 0, 255, 0.57) 0.00%, rgba(0, 219, 222, 0.6) 100.00%);
}

.our-apps-image05 {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: var(--dl-radius-radius-round);
}

.our-apps-pop-up-ludo1 {
  top: 0;
  left: 0;
  right: 0;
  width: 1116px;
  bottom: 0;
  height: 627px;
  margin: auto auto;
  display: flex;
  padding: var(--dl-space-space-twounits);
  z-index: 200;
  position: fixed;
  align-items: flex-start;
  border-radius: var(--dl-radius-radius-radius8);
  flex-direction: row;
  background-color: rgba(0, 0, 0, 0.88);
}

.our-apps-close-button1 {
  top: 11px;
  flex: 0 0 auto;
  right: 11px;
  width: 40px;
  cursor: pointer;
  height: 40px;
  margin: auto;
  display: flex;
  position: absolute;
  align-items: center;
  border-radius: var(--dl-radius-radius-round);
  flex-direction: column;
  justify-content: center;
  background-color: #000000;
}

.our-apps-icon2 {
  fill: #ffffff;
  width: 80%;
  height: 80%;
}

.our-apps-container07 {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  border-radius: var(--dl-radius-radius-radius8);
  flex-direction: row;
  justify-content: center;
}

.our-apps-container08 {
  grid-gap: var(--dl-space-space-oneandhalfunits);
  gap: var(--dl-space-space-oneandhalfunits);
  flex: 0 0 auto;
  width: 50%;
  height: 100%;
  display: flex;
  padding: var(--dl-space-space-twounits);
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.our-apps-text3 {
  color: transparent;
  width: 100%;
  text-align: left;
  background-size: cover;
  background-image: url("https://media.tenor.com/lveDtTE8Ek8AAAAM/rainbow-swirls-relaxing.gif");
}

.our-apps-text4 {
  color: rgb(220, 213, 213);
  width: 100%;
}

.our-apps-download-now1 {
  grid-gap: var(--dl-space-space-halfunit);
  gap: var(--dl-space-space-halfunit);
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

.our-apps-text5 {
  font-weight: 700;
}

.our-apps-container09 {
  grid-gap: var(--dl-space-space-unit);
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}

.our-apps-link1 {
  display: contents;
}

.our-apps-image06 {
  width: auto;
  height: auto;
  object-fit: cover;
  text-decoration: none;
}

.our-apps-image07 {
  width: auto;
  height: auto;
  display: none;
  object-fit: cover;
}

.our-apps-container10 {
  flex: 0 0 auto;
  width: 50%;
  height: 100%;
  display: flex;
  padding: var(--dl-space-space-twounits);
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.our-apps-background1 {
  flex: 0 0 auto;
  width: 400px;
  height: 400px;
  display: flex;
  align-items: center;
  border-radius: var(--dl-radius-radius-round);
  flex-direction: column;
  justify-content: center;
}

.our-apps-gradient4 {
  flex: 0 0 auto;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  border-radius: var(--dl-radius-radius-round);
  flex-direction: column;
  justify-content: center;
  background-image: linear-gradient(135deg, rgba(219, 19, 20, 0.62) 0.00%, rgba(210, 16, 130, 0.63) 100.00%);
}

.our-apps-image08 {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: var(--dl-radius-radius-round);
}

.our-apps-pop-up-ludo2 {
  top: 0;
  left: 0;
  right: 0;
  width: 1116px;
  bottom: 0;
  height: 627px;
  margin: auto auto;
  display: flex;
  padding: var(--dl-space-space-twounits);
  z-index: 200;
  position: fixed;
  align-items: flex-start;
  border-radius: var(--dl-radius-radius-radius8);
  flex-direction: row;
  background-color: rgba(0, 0, 0, 0.88);
}

.our-apps-close-button2 {
  top: 5px;
  flex: 0 0 auto;
  right: 5px;
  width: 40px;
  cursor: pointer;
  height: 40px;
  display: flex;
  position: absolute;
  align-items: center;
  border-radius: var(--dl-radius-radius-round);
  flex-direction: column;
  justify-content: center;
  background-color: #000000;
}

.our-apps-icon4 {
  fill: #ffffff;
  width: 80%;
  height: 80%;
}

.our-apps-container11 {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  border-radius: var(--dl-radius-radius-radius8);
  flex-direction: row;
  justify-content: center;
}

.our-apps-container12 {
  grid-gap: var(--dl-space-space-oneandhalfunits);
  gap: var(--dl-space-space-oneandhalfunits);
  flex: 0 0 auto;
  width: 50%;
  height: 100%;
  display: flex;
  padding: var(--dl-space-space-twounits);
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.our-apps-text6 {
  color: transparent;
  width: 100%;
  text-align: left;
  background-size: cover;
  background-image: url("https://media.tenor.com/lveDtTE8Ek8AAAAM/rainbow-swirls-relaxing.gif");
}

.our-apps-text7 {
  color: rgb(220, 213, 213);
  width: 100%;
}

.our-apps-download-now2 {
  grid-gap: var(--dl-space-space-halfunit);
  gap: var(--dl-space-space-halfunit);
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

.our-apps-text8 {
  font-weight: 700;
}

.our-apps-container13 {
  grid-gap: var(--dl-space-space-unit);
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}

.our-apps-link2 {
  display: contents;
}

.our-apps-image09 {
  width: auto;
  height: auto;
  object-fit: cover;
  text-decoration: none;
}

.our-apps-container14 {
  flex: 0 0 auto;
  width: 50%;
  height: 100%;
  display: flex;
  padding: var(--dl-space-space-twounits);
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.our-apps-background2 {
  flex: 0 0 auto;
  width: 400px;
  height: 400px;
  display: flex;
  align-items: center;
  border-radius: var(--dl-radius-radius-round);
  flex-direction: column;
  justify-content: center;
}

.our-apps-gradient5 {
  flex: 0 0 auto;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  border-radius: var(--dl-radius-radius-round);
  flex-direction: column;
  justify-content: center;
  background-image: linear-gradient(135deg, rgba(0, 255, 17, 0.55) 0.00%, rgba(243, 255, 0, 0.6) 100.00%);
}

.our-apps-image10 {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: var(--dl-radius-radius-round);
}

@media(max-width: 1400px) {
  .our-apps-container {
    height: 100vh;
  }

  .our-apps-app-circle {
    width: 300px;
    height: 300px;
  }

  .our-apps-app-circle1 {
    width: 300px;
    height: 300px;
  }

  .our-apps-app-circle2 {
    width: 300px;
    height: 300px;
  }

  .our-apps-pop-up-ludo {
    width: 963px;
  }

  .our-apps-pop-up-ludo1 {
    width: 963px;
  }

  .our-apps-pop-up-ludo2 {
    width: 963px;
  }
}

@media(max-width: 991px) {
  .our-apps-app-circle {
    width: 200px;
    height: 200px;
  }

  .our-apps-app-circle1 {
    width: 200px;
    height: 200px;
  }

  .our-apps-app-circle2 {
    width: 200px;
    height: 200px;
  }

  .our-apps-pop-up-ludo {
    width: 100%;
  }

  .our-apps-pop-up-ludo1 {
    width: 100%;
  }

  .our-apps-pop-up-ludo2 {
    width: 100%;
  }
}

@media(max-width: 767px) {
  .our-apps-container {
    height: auto;
  }

  .our-apps-container02 {
    grid-template-columns: auto;
  }

  .our-apps-app-circle,
  .our-apps-app-circle1,
  .our-apps-app-circle2 {
    width: 300px;
    height: 300px;
  }

  .our-apps-app-circle:hover,
  .our-apps-app-circle1:hover,
  .our-apps-app-circle2:hover {
    width: 310 px;
    height: 310 px;
  }

  .our-apps-pop-up-ludo {
    height: auto;
  }

  .our-apps-container04 {
    width: 100%;
  }

  .our-apps-container06 {
    display: none;
  }

  .our-apps-pop-up-ludo1 {
    height: auto;
  }

  .our-apps-container08 {
    width: 100%;
  }

  .our-apps-container10 {
    display: none;
  }

  .our-apps-pop-up-ludo2 {
    height: auto;
  }

  .our-apps-container12 {
    width: 100%;
  }

  .our-apps-container14 {
    display: none;
  }
}

@media(max-width: 479px) {

  .our-apps-heading{
    font-size: 35px;
    text-align: center;
  }
  .our-apps-app-circle,
  .our-apps-app-circle1,
  .our-apps-app-circle2 {
    width: 200px;
    height: 200px;
  }

  .our-apps-app-circle:hover,
  .our-apps-app-circle1:hover,
  .our-apps-app-circle2:hover {
    width: 200px;
    height: 200px;
  }

  .our-apps-pop-up-ludo {
    height: 100vh;
  }

  .our-apps-container05 {
    flex-direction: column;
  }

  .our-apps-pop-up-ludo1 {
    height: 100vh;
  }

  .our-apps-container09 {
    flex-direction: column;
  }

  .our-apps-pop-up-ludo2 {
    height: 100vh;
  }

  .our-apps-container13 {
    flex-direction: column;
  }
}

@media(max-width: 375px) {

  .our-apps-app-circle,
  .our-apps-app-circle1,
  .our-apps-app-circle2 {
    width: 200px;
    height: 200px;
  }

  .our-apps-app-circle:hover,
  .our-apps-app-circle1:hover,
  .our-apps-app-circle2:hover {
    width: 200px;
    height: 200px;
  }
}
.review-card {
  grid-gap: var(--dl-space-space-threeunits);
  gap: var(--dl-space-space-threeunits);
  flex: 1 1;
  width: auto;
  height: auto;
  display: flex;
  padding: var(--dl-space-space-threeunits);
  position: relative;
  align-items: flex-start;
  border-radius: 20px;
  flex-direction: column;
  justify-content: flex-start;
  background-color: #292929;
}
.review-stars {
  grid-gap: 4px;
  gap: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.review-icon {
  fill: #FFDE6A;
  width: 16px;
  height: 16px;
}
.review-icon02 {
  fill: #FFDE6A;
  width: 16px;
  height: 16px;
}
.review-icon04 {
  fill: #FFDE6A;
  width: 16px;
  height: 16px;
}
.review-icon06 {
  fill: #FFDE6A;
  width: 16px;
  height: 16px;
}
.review-icon08 {
  fill: #FFDE6A;
  width: 16px;
  height: 16px;
}
.review-content {
  grid-gap: var(--dl-space-space-oneandhalfunits);
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.review-quote {
  color: #CCCCCC;
  font-size: 18px;
  font-family: Poppins;
  line-height: 25px;
}
.review-author {
  grid-gap: var(--dl-space-space-unit);
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
}
.review-avatar {
  width: 60px;
  height: 60px;
  object-fit: cover;
  border-radius: 50%;
}
.review-details {
  grid-gap: var(--dl-space-space-halfunit);
  gap: var(--dl-space-space-halfunit);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.review-author1 {
  color: rgb(255, 255, 255);
  font-size: 16px;
  font-style: normal;
  font-family: Poppins;
  font-weight: 600;
}
.review-position {
  color: #CCCCCC;
  font-size: 14px;
  font-family: Poppins;
  line-height: 20px;
}
.review-root-class-name {
  width: 100%;
  height: 100%;
  max-width: 1400px;
  max-height: 1400px;
}





.review-root-class-name6 {
  width: 100%;
  height: 100%;
  max-width: 1400px;
  max-height: 1400px;
}
.review-root-class-name7 {
  width: 100%;
  height: 100%;
  max-width: 1400px;
  max-height: 1400px;
}
.review-root-class-name8 {
  width: 100%;
  height: 100%;
  max-width: 1400px;
  max-height: 1400px;
}
.review-root-class-name9 {
  width: 100%;
  height: 100%;
  max-width: 1400px;
  max-height: 1400px;
}

.client-feedback-old-section {
  flex: 1 1;
  width: 100%;
  display: flex;
  position: relative;
  align-items: center;
  border-color: #51515A;
  flex-direction: column;
  background-size: cover;
  justify-content: center;
  background-image: url('https://media2.giphy.com/media/3o6UBedJJfaxXHvZyU/giphy.gif?cid=ecf05e47jmwcaqmyd9ivpz03651cxa7ocyy5s3ro6xqmbhxf&rid=giphy.gif&ct=g');
  border-top-width: 1px;
  background-repeat: no-repeat;
  background-position: center;
  background-attachment: fixed;
}
.client-feedback-old-container {
  grid-gap: var(--dl-space-space-fiveunits);
  gap: var(--dl-space-space-fiveunits);
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  padding-top: 120px;
  padding-left: var(--dl-space-space-oneandhalfunits);
  padding-right: var(--dl-space-space-oneandhalfunits);
  flex-direction: column;
  padding-bottom: 120px;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.79);
}
.client-feedback-old-header {
  grid-gap: var(--dl-space-space-unit);
  gap: var(--dl-space-space-unit);
  width: 100%;
  display: flex;
  max-width: 1200px;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.client-feedback-old-left {
  grid-gap: var(--dl-space-space-oneandhalfunits);
  gap: var(--dl-space-space-oneandhalfunits);
  width: 100%;
  display: flex;
  max-width: 600px;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.client-feedback-old-heading {
  color: transparent;
  text-align: center;
  background-size: cover;
  background-image: url('https://media.tenor.com/lveDtTE8Ek8AAAAM/rainbow-swirls-relaxing.gif');
}
.client-feedback-old-right {
  grid-gap: var(--dl-space-space-unit);
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.client-feedback-old-paragraph {
  width: 100%;
  max-width: 600px;
  text-align: center;
}
.client-feedback-old-cards {
  width: 100%;
  display: grid;
  grid-gap: var(--dl-space-space-threeunits);
  max-width: 1200px;
  align-items: flex-start;
  flex-direction: row;
  grid-template-columns: auto auto auto;
}
.client-feedback-old-container1 {
  grid-gap: var(--dl-space-space-threeunits);
  gap: var(--dl-space-space-threeunits);
  display: flex;
  flex-direction: column;
}
.client-feedback-old-container2 {
  grid-gap: var(--dl-space-space-threeunits);
  gap: var(--dl-space-space-threeunits);
  display: flex;
  flex-direction: column;
}
.client-feedback-old-container3 {
  grid-gap: var(--dl-space-space-threeunits);
  gap: var(--dl-space-space-threeunits);
  border: 2px dashed rgba(120, 120, 120, 0.4);
  display: flex;
  flex-direction: column;
}
.client-feedback-old-view-more {
  grid-gap: var(--dl-space-space-oneandhalfunits);
  gap: var(--dl-space-space-oneandhalfunits);
  cursor: pointer;
  display: none;
  transition: 0.3s;
  align-items: center;
  border-color: #80FF44;
  flex-direction: column;
  padding-bottom: var(--dl-space-space-halfunit);
  justify-content: center;
  border-bottom-width: 1px;
}
.client-feedback-old-view-more:hover {
  opacity: 0.5;
}
.client-feedback-old-text {
  color: rgb(128, 255, 68);
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}
@media(max-width: 991px) {
  .client-feedback-old-section {
    grid-gap: var(--dl-space-space-oneandhalfunits);
    gap: var(--dl-space-space-oneandhalfunits);
  }
  .client-feedback-old-cards {
    grid-template-columns: auto;
  }
  .client-feedback-old-container3 {
    display: none;
  }
  .client-feedback-old-view-more {
    display: none;
    border-color: #CCCCCC;
  }
  .client-feedback-old-text {
    color: #CCCCCC;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
  }
}
@media(max-width: 479px) {
  .client-feedback-old-section{
    background-image: none;
  }
}
.company-card-container {
  width: 263px;
  height: 135px;
  display: flex;
  position: relative;
  align-items: flex-start;
  border-color: #ffffff;
  border-style: solid;
  border-width: .5px;
  border-radius: var(--dl-radius-radius-radius8);
  flex-direction: column;
  background-color: #ffffff;
}
.company-card-image {
  width: 100%;
  height: 100%;
  object-fit: contain;
  transition: 0.3s;
  border-radius: var(--dl-radius-radius-radius8);
}









@media(max-width: 767px) {
  .company-card-root-class-name {
    width: 210px;
    height: 110px;
  }
  .company-card-root-class-name1 {
    width: 210px;
    height: 110px;
  }
  .company-card-root-class-name2 {
    width: 210px;
    height: 110px;
  }
  .company-card-root-class-name3 {
    width: 210px;
    height: 110px;
  }
  .company-card-root-class-name4 {
    width: 210px;
    height: 110px;
  }
  .company-card-root-class-name5 {
    width: 210px;
    height: 110px;
  }
  .company-card-root-class-name6 {
    width: 210px;
    height: 110px;
  }
  .company-card-root-class-name7 {
    width: 210px;
    height: 110px;
  }
}
@media(max-width: 479px) {
  .company-card-container{
    width: 120px;
    height: 65px;
  }
}
@media(max-width: 409px){
  .company-card-container{
    width: 80px;
    height: 40px;
  }
}
.leftArrow{
    position: absolute;
    transform: translate(0, -50%);
    top: 50%;
    left: 100px;
    font-size: 45px;
    color: white;
    z-index: 1;
    cursor: pointer;
}

.rightArrow{
    position: absolute;
    transform: translate(0, -50%);
    top: 50%;
    right: 100px;
    font-size: 45px;
    color: white;
    z-index: 1;
    cursor: pointer;
}

@media(max-width: 767px){
    .leftArrow{
        left: 20px;
    }
    .rightArrow{
        right: 20px;
    }
}
.home-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
  background-color: #000000;
}

.home-slider {
  flex: 0 0 auto;
  width: 90%;
  height: 100vh;
  display: flex;
  padding: var(--dl-space-space-fiveunits);
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: #000000;
  margin-top: 30px;
}

.home-container01 {
  flex: 0 0 auto;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-color: rgba(120, 120, 120, 0.4);
  border-style: solid;
  border-width: 2px;
  flex-direction: column;
}

.slider_container {
  width: 100%;
  height: 100%;
  /* border: 2px solid white; */
}

.home-section {
  flex: 1 1;
  width: 100%;
  display: flex;
  position: relative;
  align-items: center;
  border-color: #51515A;
  border-width: 1px;
  flex-direction: column;
  background-size: cover;
  justify-content: center;
  background-image: url("/playground_assets/home/camp.gif");
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 1px;
  background-attachment: fixed;
}

.home-container02 {
  flex: 1 1;
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: 120px;
  padding-left: var(--dl-space-space-oneandhalfunits);
  padding-right: var(--dl-space-space-oneandhalfunits);
  flex-direction: column;
  padding-bottom: 120px;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.26);
}

.home-text {
  font-size: 55px;
  font-style: normal;
  font-family: Poppins;
  font-weight: 700;
  background-size: cover;
  background-image: url('/playground_assets/rainbow-swirls.gif');
}

.home-features {
  width: 100%;
  display: flex;
  max-width: 1200px;
  margin-top: var(--dl-space-space-fiveunits);
  align-items: flex-start;
  -webkit-user-select: none;
          user-select: none;
  border-color: #5A5A5A;
  margin-bottom: 120px;
  flex-direction: row;
  justify-content: center;
  border-bottom-width: 1px;
}

.home-text01 {
  color: rgb(255, 255, 255);
  font-size: 24px;
  font-style: normal;
  font-family: Poppins;
  font-weight: 600;
}

.home-text04 {
  color: rgb(255, 255, 255);
  font-size: 24px;
  font-style: normal;
  font-family: Poppins;
  font-weight: 600;
}

.home-text05 {
  color: rgb(255, 255, 255);
  font-size: 24px;
  font-style: normal;
  font-family: Poppins;
  font-weight: 600;
}

.home-note {
  grid-gap: 140px;
  gap: 140px;
  width: 100%;
  display: flex;
  max-width: 1200px;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}

.home-content {
  grid-gap: var(--dl-space-space-threeunits);
  gap: var(--dl-space-space-threeunits);
  flex: 1 1;
  display: flex;
  max-width: 520px;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}

.home-main {
  grid-gap: var(--dl-space-space-oneandhalfunits);
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.home-heading {
  color: rgb(255, 255, 255);
  font-size: 40px;
  font-style: normal;
  font-family: Poppins;
  font-weight: 600;
}

.home-paragraph {
  color: rgb(204, 204, 204);
  font-style: normal;
  font-family: Poppins;
  font-weight: 400;
  line-height: 28px;
}

.home-container03 {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}

.home-container04 {
  width: 431px;
  height: 566px;
  display: flex;
  align-items: flex-start;
  border-color: #ffffff;
  border-width: 2px;
  flex-direction: column;
  background-size: contain;
  background-image: url('/playground_assets/home/high-gaming-pc.webp');
  background-repeat: no-repeat;
  background-position: right;
  background-attachment: fixed;
}

.home-section01 {
  grid-gap: var(--dl-space-space-sixunits);
  gap: var(--dl-space-space-sixunits);
  flex: 1 1;
  width: 100%;
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: column;
  background-size: contain;
  justify-content: center;
  background-image: url('playground_assets/gaming_bg.png');
  background-position: center;
  background-attachment: fixed;
}

.home-container05 {
  grid-gap: var(--dl-space-space-oneandhalfunits);
  gap: var(--dl-space-space-oneandhalfunits);
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-twounits);
  padding-left: var(--dl-space-space-oneandhalfunits);
  padding-right: var(--dl-space-space-oneandhalfunits);
  flex-direction: column;
  padding-bottom: 120px;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.81);
}

.home-header {
  grid-gap: var(--dl-space-space-unit);
  gap: var(--dl-space-space-unit);
  width: 100%;
  display: flex;
  max-width: 900px;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.home-text06 {
  font-style: normal;
  background-size: cover;
  background-image: url('/playground_assets/rainbow-swirls.gif');
}

.home-text07 {
  color: rgb(194, 198, 204);
  width: 100%;
  max-width: 800px;
  text-align: center;
  font-family: Poppins;
  line-height: 28px;
}

.home-note1 {
  grid-gap: var(--dl-space-space-unit);
  gap: var(--dl-space-space-unit);
  width: 100%;
  display: flex;
  max-width: 1200px;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}

.home-container06 {
  width: 453px;
  height: 579px;
  display: flex;
  align-items: flex-start;
  border-color: #ffffff;
  border-style: solid;
  border-width: 2px;
  flex-direction: column;
  /* background-size: contain; */
  background-image: url('/playground_assets/home/TFT_GDU_thumbnail2.png');
  background-repeat: no-repeat;
  background-position: 30%;
  background-attachment: fixed;
}

.home-content1 {
  grid-gap: var(--dl-space-space-threeunits);
  gap: var(--dl-space-space-threeunits);
  flex: 1 1;
  display: flex;
  max-width: 600px;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}

.home-main1 {
  grid-gap: var(--dl-space-space-oneandhalfunits);
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  padding: var(--dl-space-space-unit);
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}

.home-caption {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.home-section02 {
  font-family: Poppins;
}

.home-heading01 {
  grid-gap: var(--dl-space-space-unit);
  gap: var(--dl-space-space-unit);
  width: 100%;
  display: flex;
  max-width: 600px;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}

.home-paragraph1 {
  font-family: Poppins;
}

.home-note2 {
  grid-gap: var(--dl-space-space-unit);
  gap: var(--dl-space-space-unit);
  width: 100%;
  display: flex;
  max-width: 1200px;
  align-items: center;
  flex-direction: row-reverse;
  justify-content: center;
}

.home-container07 {
  width: 453px;
  height: 579px;
  display: flex;
  align-items: flex-start;
  border-color: #ffffff;
  border-style: solid;
  border-width: 2px;
  flex-direction: column;
  background-size: 51%;
  background-image: url('/playground_assets/home/VR-pic.png');
  background-repeat: no-repeat;
  background-position: 600px;
  background-attachment: fixed;
}

.home-content2 {
  grid-gap: var(--dl-space-space-threeunits);
  gap: var(--dl-space-space-threeunits);
  flex: 1 1;
  display: flex;
  max-width: 600px;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}

.home-main2 {
  grid-gap: var(--dl-space-space-oneandhalfunits);
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  padding: var(--dl-space-space-unit);
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}

.home-caption1 {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.home-section03 {
  color: rgb(198, 255, 75);
  font-family: Poppins;
}

.home-heading03 {
  grid-gap: var(--dl-space-space-twounits);
  gap: var(--dl-space-space-twounits);
  width: 100%;
  display: flex;
  max-width: 600px;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}

.home-header1 {
  grid-gap: var(--dl-space-space-unit);
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}

.home-paragraph2 {
  font-family: Poppins;
}

.home-note3 {
  grid-gap: var(--dl-space-space-unit);
  gap: var(--dl-space-space-unit);
  width: 100%;
  display: flex;
  max-width: 1200px;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}

.home-container08 {
  width: 453px;
  height: 579px;
  display: flex;
  align-items: flex-start;
  border-color: #ffffff;
  border-style: solid;
  border-width: 2px;
  flex-direction: column;
  /* background-size: contain; */
  background-image: url('/playground_assets/home/rajshree.png');
  background-repeat: no-repeat;
  background-position: 30%;
  background-attachment: fixed;
}

.home-content3 {
  grid-gap: var(--dl-space-space-threeunits);
  gap: var(--dl-space-space-threeunits);
  flex: 1 1;
  display: flex;
  max-width: 600px;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}

.home-main3 {
  grid-gap: var(--dl-space-space-oneandhalfunits);
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  padding: var(--dl-space-space-unit);
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}

.home-caption2 {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.home-section04 {
  font-family: Poppins;
}

.home-heading05 {
  grid-gap: var(--dl-space-space-unit);
  gap: var(--dl-space-space-unit);
  width: 100%;
  display: flex;
  max-width: 600px;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}

.home-paragraph3 {
  font-family: Poppins;
}

.home-note4 {
  grid-gap: var(--dl-space-space-unit);
  gap: var(--dl-space-space-unit);
  width: 100%;
  display: flex;
  max-width: 1200px;
  align-items: center;
  flex-direction: row-reverse;
  justify-content: center;
}

.home-container09 {
  width: 453px;
  height: 579px;
  display: flex;
  align-items: flex-start;
  border-color: #ffffff;
  border-style: solid;
  border-width: 2px;
  flex-direction: column;
  background-size: 46%;
  background-image: url('/playground_assets/home/online-gaming-1-770x433.png');
  background-position: right;
  background-attachment: fixed;
  background-repeat: no-repeat;
}

.home-content4 {
  grid-gap: var(--dl-space-space-threeunits);
  gap: var(--dl-space-space-threeunits);
  flex: 1 1;
  display: flex;
  padding: var(--dl-space-space-unit);
  max-width: 600px;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}

.home-main4 {
  grid-gap: var(--dl-space-space-oneandhalfunits);
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}

.home-caption3 {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.home-section05 {
  color: rgb(198, 255, 75);
  font-family: Poppins;
}

.home-heading07 {
  grid-gap: var(--dl-space-space-twounits);
  gap: var(--dl-space-space-twounits);
  width: 100%;
  display: flex;
  max-width: 600px;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}

.home-header2 {
  grid-gap: var(--dl-space-space-unit);
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}

.home-paragraph4 {
  font-family: Poppins;
}

.home-section06 {
  flex: 1 1;
  width: 100%;
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: row;
  background-size: contain;
  justify-content: center;
  background-image: url('/playground_assets/gaming_bg.png');
  background-position: center;
  background-attachment: fixed;
}

.home-container10 {
  grid-gap: var(--dl-space-space-halfunit);
  gap: var(--dl-space-space-halfunit);
  width: 100%;
  height: 100%;
  display: grid;
  padding: var(--dl-space-space-sixunits);
  align-items: center;
  flex-direction: row;
  grid-column-gap: var(--dl-space-space-twounits);
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.81);
  grid-template-columns: auto auto auto auto;
}

.home-card {
  flex: 0 0 auto;
  width: 270px;
  height: 210px;
  display: flex;
  transition: 0.3s;
  align-items: flex-start;
  border-color: #4c4848;
  border-width: 2px;
  flex-direction: column;
}

.home-card:hover {
  border-image: url(/playground_assets/rainbow-swirls.gif) 1 stretch;
  background-color: rgba(0, 0, 0, 0.65);
}

.home-container11 {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-end;
}

.home-text08 {
  width: 100%;
  height: auto;
  font-size: 80px;
  font-style: normal;
  text-align: center;
  font-family: Poppins;
  font-weight: 600;
  background-size: cover;
  background-image: url('/playground_assets/rainbow-swirls.gif');
}

.home-container12 {
  flex: 0 0 auto;
  width: 100%;
  height: 40%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.home-text11 {
  width: 100%;
  height: auto;
  font-size: 25px;
  font-style: normal;
  text-align: center;
  font-family: Poppins;
  font-weight: 500;
  text-transform: uppercase;
  background-size: cover;
  background-image: url('/playground_assets/rainbow-swirls.gif');
}

.home-card1 {
  flex: 0 0 auto;
  width: 270px;
  height: 210px;
  display: flex;
  transition: 0.3s;
  align-items: flex-start;
  border-color: #4c4848;
  border-width: 2px;
  flex-direction: column;
}

.home-card1:hover {
  border-image: url(/playground_assets/rainbow-swirls.gif) 1 stretch;
  background-color: rgba(0, 0, 0, 0.65);
}

.home-container13 {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-end;
}

.home-text12 {
  width: 100%;
  height: auto;
  font-size: 80px;
  font-style: normal;
  text-align: center;
  font-family: Poppins;
  font-weight: 600;
  background-size: cover;
  background-image: url("/playground_assets/rainbow-swirls.gif");
}

.home-container14 {
  flex: 0 0 auto;
  width: 100%;
  height: 40%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.home-text15 {
  width: 100%;
  height: auto;
  font-size: 25px;
  font-style: normal;
  text-align: center;
  font-family: Poppins;
  font-weight: 500;
  text-transform: uppercase;
  background-size: cover;
  background-image: url("/playground_assets/rainbow-swirls.gif");
}

.home-card2 {
  flex: 0 0 auto;
  width: 270px;
  height: 210px;
  display: flex;
  transition: 0.3s;
  align-items: flex-start;
  border-color: #4c4848;
  border-width: 2px;
  flex-direction: column;
}

.home-card2:hover {
  border-image: url(/playground_assets/rainbow-swirls.gif) 1 stretch;
  background-color: rgba(0, 0, 0, 0.65);
}

.home-container15 {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-end;
}

.home-text16 {
  width: 100%;
  height: auto;
  font-size: 80px;
  font-style: normal;
  text-align: center;
  font-family: Poppins;
  font-weight: 600;
  background-size: cover;
  background-image: url("/playground_assets/rainbow-swirls.gif");
}

.home-container16 {
  flex: 0 0 auto;
  width: 100%;
  height: 40%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.home-text19 {
  width: 100%;
  height: auto;
  font-size: 25px;
  font-style: normal;
  text-align: center;
  font-family: Poppins;
  font-weight: 500;
  text-transform: uppercase;
  background-size: cover;
  background-image: url("/playground_assets/rainbow-swirls.gif");
}

.home-card3 {
  flex: 0 0 auto;
  width: 270px;
  height: 210px;
  display: flex;
  transition: 0.3s;
  align-items: flex-start;
  border-color: #4c4848;
  border-width: 2px;
  flex-direction: column;
}

.home-card3:hover {
  border-image: url(/playground_assets/rainbow-swirls.gif) 1 stretch;
  background-color: rgba(0, 0, 0, 0.65);
}

.home-container17 {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-end;
}

.home-text20 {
  width: 100%;
  height: auto;
  font-size: 80px;
  font-style: normal;
  text-align: center;
  font-family: Poppins;
  font-weight: 600;
  background-size: cover;
  background-image: url("/playground_assets/rainbow-swirls.gif");
}

.home-container18 {
  flex: 0 0 auto;
  width: 100%;
  height: 40%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.home-text23 {
  width: 100%;
  height: auto;
  font-size: 25px;
  font-style: normal;
  text-align: center;
  font-family: Poppins;
  font-weight: 500;
  text-transform: uppercase;
  background-size: cover;
  background-image: url("/playground_assets/rainbow-swirls.gif");
}

.home-container19 {
  width: 100%;
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.home-container20 {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  background-size: cover;
  justify-content: center;
  background-image: url('/playground_assets/home/nightForest.jfif');
  background-attachment: fixed;
}

.home-section07 {
  grid-gap: var(--dl-space-space-fiveunits);
  gap: var(--dl-space-space-fiveunits);
  flex: 1 1;
  width: 100%;
  display: flex;
  position: relative;
  align-items: center;
  padding-top: 120px;
  border-color: #4e4848;
  border-width: 1px;
  padding-left: var(--dl-space-space-oneandhalfunits);
  padding-right: var(--dl-space-space-oneandhalfunits);
  flex-direction: column;
  padding-bottom: 120px;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.74);
  border-top-width: 1px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 0px;
}

.home-cube {
  top: 20px;
  left: 0px;
  width: 60px;
  height: 60px;
  display: none;
  z-index: 100;
  position: absolute;
  transform: rotateX(240deg) rotateY(25deg) rotateZ(-110deg);
  align-items: center;
  flex-direction: column;
  justify-content: center;
  transform-style: preserve-3d;
}

.home-top {
  transform: translateZ(-60px);
  background-image: linear-gradient(270deg, rgb(253, 253, 253) 0.00%, rgb(178, 178, 178) 100.00%);
}

.home-front {
  transform: rotateX(90deg);
  background: grey;
  background-image: linear-gradient(90deg, rgb(247, 247, 247) 0.00%, rgb(203, 203, 203) 100.00%);
  transform-origin: bottom;
}

.home-left {
  /* width: 60px; */
  transform: translateZ(-60px) rotateY(90deg);
  background-image: linear-gradient(90deg, rgb(247, 247, 247) 0.00%, rgb(203, 203, 203) 100.00%);
  transform-origin: right;
}

.home-banner {
  width: 100%;
  display: flex;
  z-index: 50;
  max-width: 1200px;
  border-image: url(/playground_assets/rainbow-swirls.gif) 1 stretch;
  border-width: 2px;
  flex-direction: column;
  background-size: cover;
  justify-content: center;
  background-image: url('/playground_assets/home/moonlightSakura.gif');
  background-repeat: no-repeat;
  background-position: center;
}

.home-container21 {
  width: 100%;
  height: 100%;
  display: flex;
  padding: var(--dl-space-space-fiveunits);
  flex-direction: column;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.72);
}

.home-header3 {
  grid-gap: var(--dl-space-space-unit);
  gap: var(--dl-space-space-unit);
  width: 100%;
  display: flex;
  max-width: 700px;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}

.home-heading09 {
  color: var(--dl-color-colors-cyan);
}

.home-description {
  font-size: 20px;
  line-height: 30px;
}

.home-award-section {
  width: 100%;
  height: auto;
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  padding-bottom: 5%;
}

.home-container22 {
  grid-gap: var(--dl-space-space-fiveunits);
  gap: var(--dl-space-space-fiveunits);
  flex: 0 0 auto;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.74);
}

.home-container23 {
  flex: 0 0 auto;
  width: 1052px;
  height: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}

.home-text24 {
  color: transparent;
  width: auto;
  font-style: normal;
  background-size: cover;
  background-image: url('/playground_assets/rainbow-swirls.gif');
  background-attachment: fixed;
}

.home-award-grid {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: grid;
  grid-gap: var(--dl-space-space-fourunits);
  align-items: flex-start;
  flex-direction: column;
  grid-template-columns: auto auto;
}

.home-container24 {
  flex: 0 0 auto;
  width: 500px;
  height: 300px;
  display: flex;
  transition: 0.3s;
  align-items: center;
  border-color: rgba(255, 255, 255, 0.35);
  border-width: 1px;
  border-radius: var(--dl-radius-radius-radius8);
  flex-direction: column;
  background-size: cover;
  justify-content: center;
  background-image: url("/playground_assets/laurel%20gif-300h.gif");
  background-repeat: no-repeat;
  background-position: center;
}

.home-container24:hover {
  border: none;
  box-shadow: 0px 0px 100px 10px #ff0000, 0px 0px 100px 20px #0028ff;
}

.home-container25 {
  flex: 0 0 auto;
  width: 100%;
  height: 100%;
  display: flex;
  padding: var(--dl-space-space-oneandhalfunits);
  align-items: center;
  border-radius: var(--dl-radius-radius-radius8);
  flex-direction: column;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.7);
}

.home-text28 {
  color: var(--dl-color-colors-orange);
  width: 100%;
  font-size: 38px;
  font-style: normal;
  text-align: center;
  transition: 0.3s;
  font-weight: 600;
}

.home-text28:hover {
  color: #ffffff;
  cursor: default;
}

.home-text29 {
  color: rgba(255, 255, 255, 0.6);
  width: auto;
  font-size: 28px;
  font-style: normal;
  text-align: center;
  transition: 0.3s;
  font-weight: 300;
}

.home-text29:hover {
  color: #ffffff;
  cursor: default;
}

.home-container26 {
  flex: 0 0 auto;
  width: 500px;
  height: 300px;
  display: flex;
  transition: 0.3s;
  align-items: center;
  border-color: rgba(255, 255, 255, 0.35);
  border-width: 1px;
  border-radius: var(--dl-radius-radius-radius8);
  flex-direction: column;
  background-size: cover;
  justify-content: center;
  background-image: url("/playground_assets/laurel%20gif-300h.gif");
  background-repeat: no-repeat;
  background-position: center;
}

.home-container26:hover {
  border: none;
  box-shadow: 0px 0px 100px 10px #23ff00, 0px 0px 100px 20px #0028ff;
}

.home-container27 {
  flex: 0 0 auto;
  width: 100%;
  height: 100%;
  display: flex;
  padding: var(--dl-space-space-oneandhalfunits);
  align-items: center;
  border-radius: var(--dl-radius-radius-radius8);
  flex-direction: column;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.62);
}

.home-text30 {
  color: var(--dl-color-colors-orange);
  width: 100%;
  font-size: 38px;
  font-style: normal;
  text-align: center;
  transition: 0.3s;
  font-weight: 600;
}

.home-text30:hover {
  color: #ffffff;
  cursor: default;
}

.home-text31 {
  color: rgba(255, 255, 255, 0.6);
  width: auto;
  font-size: 28px;
  font-style: normal;
  text-align: center;
  transition: 0.3s;
  font-weight: 300;
}

.home-text31:hover {
  color: #ffffff;
  cursor: default;
}

.home-container28 {
  flex: 0 0 auto;
  width: 500px;
  height: 300px;
  display: flex;
  transition: 0.3s;
  align-items: center;
  border-color: rgba(255, 255, 255, 0.35);
  border-width: 1px;
  border-radius: var(--dl-radius-radius-radius8);
  flex-direction: column;
  background-size: cover;
  justify-content: center;
  background-image: url("/playground_assets/laurel%20gif-300h.gif");
  background-repeat: no-repeat;
  background-position: center;
}

.home-container28:hover {
  border: none;
  box-shadow: 0px 0px 100px 10px rgba(255, 247, 0, 0.46), 0px 0px 100px 20px rgba(255, 0, 143, 0.65);
}

.home-container29 {
  flex: 0 0 auto;
  width: 100%;
  height: 100%;
  display: flex;
  padding: var(--dl-space-space-oneandhalfunits);
  align-items: center;
  border-radius: var(--dl-radius-radius-radius8);
  flex-direction: column;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.62);
}

.home-text32 {
  color: var(--dl-color-colors-orange);
  width: 100%;
  font-size: 38px;
  font-style: normal;
  text-align: center;
  transition: 0.3s;
  font-weight: 600;
}

.home-text32:hover {
  color: #ffffff;
  cursor: default;
}

.home-text33 {
  color: rgba(255, 255, 255, 0.6);
  width: auto;
  font-size: 28px;
  font-style: normal;
  text-align: center;
  transition: 0.3s;
  font-weight: 300;
}

.home-text33:hover {
  color: #ffffff;
  cursor: default;
}

.home-container30 {
  flex: 0 0 auto;
  width: 500px;
  height: 300px;
  display: flex;
  transition: 0.3s;
  align-items: center;
  border-color: rgba(255, 255, 255, 0.35);
  border-width: 1px;
  border-radius: var(--dl-radius-radius-radius8);
  flex-direction: column;
  background-size: cover;
  justify-content: center;
  background-image: url("/playground_assets/laurel%20gif-300h.gif");
  background-repeat: no-repeat;
  background-position: center;
}

.home-container30:hover {
  border: none;
  box-shadow: 0px 0px 100px 10px rgba(0, 255, 212, 0.54), 0px 0px 100px 20px rgba(206, 0, 255, 0.61);
}

.home-container31 {
  flex: 0 0 auto;
  width: 100%;
  height: 100%;
  display: flex;
  padding: var(--dl-space-space-oneandhalfunits);
  align-items: center;
  border-radius: var(--dl-radius-radius-radius8);
  flex-direction: column;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.62);
}

.home-text34 {
  color: var(--dl-color-colors-orange);
  width: 100%;
  font-size: 38px;
  font-style: normal;
  text-align: center;
  transition: 0.3s;
  font-weight: 600;
}

.home-text34:hover {
  color: #ffffff;
  cursor: default;
}

.home-text35 {
  color: rgba(255, 255, 255, 0.6);
  width: auto;
  font-size: 28px;
  font-style: normal;
  text-align: center;
  transition: 0.3s;
  font-weight: 300;
}

.home-text35:hover {
  color: #ffffff;
  cursor: default;
}

.home-section08 {
  grid-gap: var(--dl-space-space-threeunits);
  gap: var(--dl-space-space-threeunits);
  flex: 1 1;
  width: 100%;
  display: flex;
  position: relative;
  align-items: center;
  padding-top: 120px;
  padding-left: var(--dl-space-space-oneandhalfunits);
  padding-right: var(--dl-space-space-oneandhalfunits);
  flex-direction: column;
  padding-bottom: 120px;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.74);
}

.home-cube1 {
  right: 0px;
  width: 60px;
  bottom: -80px;
  height: 60px;
  margin: auto;
  display: none;
  z-index: 100;
  position: absolute;
  transform: rotateX(240deg) rotateY(50deg) rotateZ(-110deg);
  align-items: center;
  flex-direction: column;
  justify-content: center;
  transform-style: preserve-3d;
}

.home-top1 {
  transform: translateZ(-60px);
  background-image: linear-gradient(270deg, rgb(253, 253, 253) 0.00%, rgb(178, 178, 178) 100.00%);
}

.home-front1 {
  transform: rotateX(90deg);
  background: grey;
  background-image: linear-gradient(90deg, rgb(247, 247, 247) 0.00%, rgb(203, 203, 203) 100.00%);
  transform-origin: bottom;
}

.home-left1 {
  /* width: 210px; */
  transform: translateZ(-60px) rotateY(90deg);
  background-image: linear-gradient(90deg, rgb(247, 247, 247) 0.00%, rgb(203, 203, 203) 100.00%);
  transform-origin: right;
}

.home-pricing {
  grid-gap: var(--dl-space-space-threeunits);
  gap: var(--dl-space-space-threeunits);
  width: 100%;
  display: flex;
  z-index: 50;
  max-width: 1200px;
  align-items: center;
  border-radius: 20px;
  flex-direction: column;
  justify-content: center;
}

.home-header4 {
  grid-gap: var(--dl-space-space-unit);
  gap: var(--dl-space-space-unit);
  width: 100%;
  display: flex;
  max-width: 900px;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.home-left2 {
  grid-gap: var(--dl-space-space-oneandhalfunits);
  gap: var(--dl-space-space-oneandhalfunits);
  width: 100%;
  display: flex;
  max-width: 600px;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.home-heading10 {
  color: transparent;
  background-size: cover;
  background-image: url('/playground_assets/rainbow-swirls.gif');
}

.home-right {
  grid-gap: var(--dl-space-space-unit);
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.home-paragraph5 {
  width: 100%;
  font-size: 18px;
  max-width: 600px;
  text-align: center;
  line-height: 32px;
}

.home-section09 {
  grid-gap: var(--dl-space-space-fiveunits);
  gap: var(--dl-space-space-fiveunits);
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  border-color: #51515A;
  flex-direction: column;
  background-size: contain;
  justify-content: center;
  background-image: url('/playground_assets/home/squarelight.gif');
  border-top-width: 1px;
  background-repeat: no-repeat;
  background-position: center;
  background-attachment: fixed;
}

.home-container32 {
  grid-gap: var(--dl-space-space-fiveunits);
  gap: var(--dl-space-space-fiveunits);
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  padding-top: 120px;
  padding-left: var(--dl-space-space-oneandhalfunits);
  padding-right: var(--dl-space-space-oneandhalfunits);
  flex-direction: column;
  padding-bottom: 120px;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.83);
}

.home-header5 {
  grid-gap: var(--dl-space-space-oneandhalfunits);
  gap: var(--dl-space-space-oneandhalfunits);
  width: 100%;
  display: flex;
  max-width: 1200px;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.home-heading11 {
  text-align: center;
}

.home-cards {
  grid-gap: var(--dl-space-space-halfunit);
  gap: var(--dl-space-space-halfunit);
  width: 100%;
  display: grid;
  grid-gap: var(--dl-space-space-twounits);
  max-width: 1200px;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  grid-template-columns: auto auto auto;
}

.home-container33 {
  width: 263px;
  height: 135px;
  display: flex;
  position: relative;
  transition: 0.3s;
  align-items: center;
  border-color: #605252;
  border-style: solid;
  border-width: 1px;
  border-radius: var(--dl-radius-radius-radius8);
  flex-direction: column;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0);
}

.home-container33:hover {
  border-image: url(/playground_assets/rainbow-swirls.gif) 1 stretch;
}

.home-text40 {
  width: 100%;
  height: auto;
  font-size: 25px;
  font-style: normal;
  text-align: center;
  font-weight: 600;
  background-size: cover;
  background-image: url('/playground_assets/rainbow-swirls.gif');
}

.home-section10 {
  flex: 1 1;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  background-size: cover;
  justify-content: center;
  background-image: url('/playground_assets/home/showreel.gif');
  background-repeat: no-repeat;
  background-position: center;
}

.home-container34 {
  grid-gap: var(--dl-space-space-fiveunits);
  gap: var(--dl-space-space-fiveunits);
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  padding-top: 120px;
  padding-left: var(--dl-space-space-oneandhalfunits);
  padding-right: var(--dl-space-space-oneandhalfunits);
  flex-direction: column;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.43);
}

.home-content5 {
  width: 100%;
  display: flex;
  position: relative;
  max-width: 1200px;
  align-items: flex-start;
  flex-direction: row;
  justify-content: space-between;
}

.home-header6 {
  grid-gap: var(--dl-space-space-threeunits);
  gap: var(--dl-space-space-threeunits);
  width: 100%;
  display: flex;
  max-width: 600px;
  align-items: flex-start;
  flex-direction: column;
  padding-bottom: 120px;
  justify-content: flex-start;
}

.home-heading12 {
  text-align: left;
}

.home-text41 {
  color: #c1b3b3;
  font-size: 20px;
  font-style: normal;
  font-family: Poppins;
  font-weight: 200;
}

@media(max-width: 1400px) {
  .home-container04 {
    background-size: cover;
    background-position: center;
  }

  .home-container06 {
    background-size: cover;
    background-position: center;
  }

  .home-container07 {
    /* background-size: cover; */
    /* background-position: center; */
  }

  .home-container08 {
    background-size: cover;
    background-position: center;
  }

  .home-container09 {
    background-size: cover;
    background-repeat: no-repeat;
  }

  .home-container10 {
    grid-gap: var(--dl-space-space-twounits);
    grid-template-columns: auto auto;
  }
}

@media(max-width: 991px) {
  .home-text {
    text-align: center;
  }

  .home-note {
    grid-gap: var(--dl-space-space-fourunits);
    gap: var(--dl-space-space-fourunits);
    width: 100%;
    flex-direction: column;
  }

  .home-content {
    max-width: 100%;
  }

  .home-main {
    align-items: flex-start;
  }

  .home-container03 {
    width: 100%;
  }

  .home-container04 {
    width: 100%;
    height: 438px;
  }

  .home-text06 {
    text-align: center;
  }

  .home-container06 {
    width: 369px;
    height: 487px;
  }

  .home-container07 {
    width: 369px;
    height: 487px;
    background-position: center;
    background-size: contain;
  }

  .home-container08 {
    width: 369px;
    height: 487px;
  }

  .home-container09 {
    width: 369px;
    height: 487px;
  }

  .home-container10 {
    grid-gap: var(--dl-space-space-halfunit);
    gap: var(--dl-space-space-halfunit);
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }

  .home-banner {
    grid-gap: var(--dl-space-space-twounits);
    gap: var(--dl-space-space-twounits);
    flex-direction: column;
    justify-content: center;
  }

  .home-award-section {
    height: auto;
  }

  .home-container22 {
    padding-top: var(--dl-space-space-fiveunits);
    padding-bottom: var(--dl-space-space-fiveunits);
  }

  .home-container23 {
    width: 100%;
    padding: var(--dl-space-space-unit);
    max-width: 1052px;
  }

  .home-award-grid {
    grid-gap: var(--dl-space-space-twounits);
    grid-template-columns: auto auto;
  }

  .home-container24 {
    width: 400px;
    height: 250px;
  }

  .home-container26 {
    width: 400px;
    height: 250px;
  }

  .home-container28 {
    width: 400px;
    height: 250px;
  }

  .home-container30 {
    width: 400px;
    height: 250px;
  }

  .home-cube1 {
    display: none;
  }

  .home-section09 {
    background-size: cover;
  }

  .home-cards {
    grid-gap: var(--dl-space-space-halfunit);
    gap: var(--dl-space-space-halfunit);
    grid-template-columns: auto auto;
  }

  .home-container33 {
    display: none;
  }

  .home-content5 {
    align-items: center;
    flex-direction: column;
  }
}

@media(max-width: 767px) {
  .home-slider {
    /* padding: 20px; */
    width: 90vw;
    height: 50vh;
    padding: 0px 0px 0px 0px;
  }

  .home-section {
    background-attachment: initial;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .home-container01 {
    border-width: 0px;
  }

  .home-text {
    font-size: 32px;
    text-align: center;
  }

  .home-features {
    grid-gap: var(--dl-space-space-oneandhalfunits);
    gap: var(--dl-space-space-oneandhalfunits);
    margin-top: var(--dl-space-space-threeunits);
    border-color: #5A5A5A;
    margin-bottom: var(--dl-space-space-threeunits);
    flex-direction: column;
  }

  .home-feature {
    width: 100%;
    padding-bottom: var(--dl-space-space-unit);
  }

  .home-text01 {
    font-size: 20px;
  }

  .home-feature1 {
    width: 100%;
    /* border-color: #5A5A5A; */
    padding-bottom: var(--dl-space-space-unit);
    /* border-bottom-width: 1px; */
  }

  .home-text04 {
    font-size: 20px;
  }

  .home-feature2 {
    width: 100%;
    padding-bottom: var(--dl-space-space-unit);
  }

  .home-text05 {
    font-size: 20px;
  }

  .home-note {
    grid-gap: var(--dl-space-space-threeunits);
    gap: var(--dl-space-space-threeunits);
    width: 100%;
  }

  .home-main {
    width: 100%;
    align-items: flex-start;
  }

  .home-heading {
    font-size: 28px;
  }

  .home-paragraph {
    line-height: 24px;
  }

  .home-container03 {
    width: 100%;
  }

  .home-container04 {
    width: 100%;
    height: 364px;
  }

  .home-text06 {
    font-size: 32px;
    text-align: center;
  }

  .home-note1 {
    flex-direction: column-reverse;
  }

  .home-container06 {
    width: 333px;
    height: 454px;
  }

  .home-note2 {
    flex-direction: column-reverse;
  }

  .home-container07 {
    width: 333px;
    height: 454px;
    background-size: cover;
    background-position: center;

  }

  .home-note3 {
    flex-direction: column-reverse;
  }

  .home-container08 {
    width: 333px;
    height: 454px;
  }

  .home-note4 {
    flex-direction: column-reverse;
  }

  .home-container09 {
    width: 333px;
    height: 454px;
  }

  .home-container10 {
    grid-template-columns: auto;
  }

  .home-card {
    width: 260px;
    height: 210px;
  }

  .home-card1 {
    width: 260px;
    height: 210px;
  }

  .home-card2 {
    width: 260px;
    height: 210px;
  }

  .home-card3 {
    width: 260px;
    height: 210px;
  }

  .home-award-grid {
    grid-template-columns: auto;
  }

  .home-cube1 {
    right: -250px;
  }

  .home-cards {
    grid-gap: var(--dl-space-space-halfunit);
    gap: var(--dl-space-space-halfunit);
    flex-direction: column;
  }

  .home-header6 {
    padding-bottom: var(--dl-space-space-fiveunits);
  }

  /* .home-text41 {
    display: none;
  } */
}

@media(max-width: 479px) {
  .home-container34 {
    background-color: rgba(0, 0, 0, 0.8);

  }

  .home-header6 {
    grid-gap: 0;
    gap: 0;
  }

  .home-container33 {
    width: 120px;
    height: 65px;
    display: flex;
    position: relative;
    transition: 0.3s;
    align-items: center;
    border-color: #605252;
    border-style: solid;
    border-width: 1px;
    border-radius: var(--dl-radius-radius-radius8);
    flex-direction: column;
    justify-content: center;
    background-color: rgba(255, 255, 255, 0);
  }

  .home-text40 {
    font-size: 16px;
  }

  .home-container24:hover,
  .home-container26:hover,
  .home-container28:hover,
  .home-container30:hover {
    /* display: none; */
    box-shadow: none;
    border: 1px solid grey
  }

  .home-container02 {
    padding-top: 30px;
  }

  .home-main1 {
    grid-gap: 0;
    gap: 0;
  }

  .home-heading01 {
    grid-gap: 0;
    gap: 0;
  }

  .home-main2 {
    grid-gap: 0;
    gap: 0;
  }

  .home-header1 {
    grid-gap: 0;
    gap: 0;
  }

  .home-main3 {
    grid-gap: 0;
    gap: 0;
  }

  .home-heading05 {
    grid-gap: 0;
    gap: 0;
  }

  .home-main4 {
    grid-gap: 0;
    gap: 0;
  }

  .home-header2 {
    grid-gap: 0;
    gap: 0;
  }

  .home-container05 {
    padding-bottom: 30px;
  }

  .home-section07 {
    padding-bottom: 10px;
    padding-top: 50px;
  }

  .home-container22 {
    padding-top: 40px;
    grid-gap: 10px;
    gap: 10px;
  }

  .home-text24 {
    font-size: 30px;
  }

  .home-section08 {
    padding-top: 10px;
    padding-bottom: 60px;
    width: 90%;
  }


  .header-root-class-name {
    position: relative;
  }

  .h2er {
    font-size: 20px;
  }

  .texter {
    font-size: 16px;
  }

  .home-slider {
    /* padding: 20px; */
    width: 90vw;
    height: 32vh;
    padding: 0px 0px 0px 0px;
  }

  .home-container04 {
    width: 100%;
    height: 220px;
    background-attachment: initial;
  }


  .home-container06 {
    background-attachment: initial;
  }

  .home-container07 {
    background-attachment: initial;
    background-position: center;
  }

  .home-container08 {
    background-attachment: initial;
  }

  .home-container09 {
    background-attachment: initial;
  }

  .home-text06 {
    text-align: left;
  }

  .home-text07 {
    text-align: left;
  }

  .home-container10 {
    padding: var(--dl-space-space-twounits);
  }

  .home-card {
    width: 290px;
    height: 180px;
  }

  .home-card1 {
    width: 290px;
    height: 180px;
  }

  .home-card2 {
    width: 290px;
    height: 180px;
  }

  .home-card3 {
    width: 290px;
    height: 200px;
  }

  .home-pricing {
    grid-gap: var(--dl-space-space-oneandhalfunits);
    gap: var(--dl-space-space-oneandhalfunits);
  }

  .home-heading10 {
    font-size: 32px;
    text-align: center;
  }

  .home-paragraph5 {
    font-size: 16px;
    text-align: center;
  }

  .home-section09 {
    height: auto;
  }

  .home-cards {
    grid-template-columns: auto auto auto;
    grid-gap: 15px;
    gap: 15px;
  }

  .home-section10 {
    background-position: center;
  }

  /* for Dream Build Repeat Section */
  .home-feature {
    width: 50%;
  }

  .home-feature1 {
    width: 75%;
  }

  .home-feature2 {
    width: 100%;
  }
}

@media(max-width: 409px) {
  .home-container33 {
    width: 80px;
    height: 40px;
    display: flex;
    position: relative;
    transition: 0.3s;
    align-items: center;
    border-color: #605252;
    border-style: solid;
    border-width: 1px;
    border-radius: var(--dl-radius-radius-radius8);
    flex-direction: column;
    justify-content: center;
    background-color: rgba(255, 255, 255, 0);
  }

  .home-text40 {
    font-size: 12px;
  }
}

@media(max-width: 375px) {
  .home-slider {
    height: 30vh;
  }

  .home-container04 {
    background-attachment: initial;
  }

  .home-container06 {
    background-attachment: initial;
  }

  .home-container07 {
    background-attachment: initial;
  }

  .home-container08 {
    background-attachment: initial;
  }

  .home-container09 {
    background-attachment: initial;
  }

  .home-paragraph {
    font-size: 16px;
  }

  .home-container04 {
    height: 239px;
  }

  .home-text07 {
    font-size: 16px;
  }

  .home-container06 {
    width: 327px;
    height: 409px;
  }

  .home-paragraph1 {
    font-size: 16px;
  }

  .home-container07 {
    width: 327px;
    height: 409px;
  }

  .home-paragraph2 {
    font-size: 16px;
  }

  .home-container08 {
    width: 327px;
    height: 409px;
  }

  .home-paragraph3 {
    font-size: 16px;
  }

  .home-container09 {
    width: 327px;
    height: 409px;
  }

  .home-paragraph4 {
    font-size: 16px;
  }

  .home-text08 {
    font-size: 50px;
  }

  .home-text11 {
    font-size: 20px;
  }

  .home-text12 {
    font-size: 50px;
  }

  .home-text15 {
    font-size: 20px;
  }

  .home-text16 {
    font-size: 50px;
  }

  .home-text19 {
    font-size: 20px;
  }

  .home-text20 {
    font-size: 50px;
  }

  .home-text23 {
    font-size: 20px;
  }

  .home-container21 {
    padding: var(--dl-space-space-unit);
  }

  .home-container24 {
    width: 350px;
    height: 300px;
  }

  .home-text28 {
    font-size: 30px;
  }

  .home-text29 {
    font-size: 22px;
  }

  .home-container26 {
    width: 350px;
    height: 300px;
  }

  .home-text30 {
    font-size: 30px;
  }

  .home-text31 {
    font-size: 22px;
  }

  .home-container28 {
    width: 350px;
    height: 300px;
  }

  .home-text32 {
    font-size: 30px;
  }

  .home-text33 {
    font-size: 22px;
  }

  .home-container30 {
    width: 350px;
    height: 300px;
  }

  .home-text34 {
    font-size: 30px;
  }

  .home-text35 {
    font-size: 22px;
  }
}
.com-container {
    transition: 0.3s ease-out;
    width: 250px;
    height: 250px;
    display: flex;
    position: relative;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    border:4px solid;
    border-image: url(https://media.tenor.com/lveDtTE8Ek8AAAAM/rainbow-swirls-relaxing.gif) 1 stretch;
}

.com-container:hover{
    /* transition: 0.3s ease-in; */
    /* border: 1px solid white; */
    /* border-radius: 10px; */
}

.com-image {
    width: 100%;
    height: 100%;
    object-fit: fill;
}
.com-image:hover{
    /* border: 1px solid white; */
    /* border-radius: 10px; */
    /* transform: scale(1.15); */
    /* transition: 0.3s ease-in; */
}

@media(max-width:480px){
    .com-container:hover{
        border: 10px solid white;
        transform: scale(1.15);
    }
}












@media(max-width: 767px) {
    .com-container {
        width: 100px;
        height: 100px;
    }
}
.worked-container {
    width: 100%;
    /* height: 100vh; */
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: url("/playground_assets/gaming_bg.png");
    background-attachment: fixed;
    background-position: center;
    background-size: contain;

}

.last_div{
    display: flex;
    justify-content: center;
    align-items: center;
    border-width: 2px;
    border-style: solid;
    width: 250px;
    height: 250px;
    padding: 5px;
    font-size: 35px;
    font-weight: 600;
    font-family: poppins;
}
.last_div:hover{
    /* transform: scale(1.15);
    cursor: pointer; */
}
.span1{
    padding: 10px;
    text-align: center;
}

.transparent {
    width: 100%;
    /* height: 100vh; */
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.81);
    flex-direction: column;
}

.grider {
    grid-gap: 50px;
    gap: 50px;
    width: 100%;
    height: auto;
    display: grid;
    padding: var(--dl-space-space-fourunits);
    /* max-width: 1400px; */
    align-items: center;
    flex-direction: column;
    justify-content: center;
    grid-template-columns: auto auto auto auto;
}

@media(max-width: 991px) {
    .grider {
        grid-template-columns: auto auto auto;
    }
}

@media(max-width: 767px) {
    .grider {
        grid-gap: 30px;
        gap: 30px;
        padding: var(--dl-space-space-threeunits);
    }
    .last_div{
        width: 100px;
        height: 100px;
    }
}

@media(max-width: 479px) {
    .grider {
        grid-gap: 10px;
        gap: 10px;
        padding: var(--dl-space-space-oneandhalfunits);
    }
    .last_div{
        font-size: 24px;
    }
}
.showreel-container {
    width: 100%;
    height: 100%;
    display: flex;
    position: relative;
    align-items: center;
    flex-direction: column;
    background-size: contain;
    justify-content: center;
    background-image: url("playground_assets/gaming_bg.png");
    background-position: center;
    background-attachment: fixed;
  }
  .showreel-container1 {
    flex: 0 0 auto;
    width: 100%;
    height: 100%;
    display: flex;
    padding: var(--dl-space-space-fourunits);
    align-items: center;
    flex-direction: column;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.8);
  }
  .showreel-video {
    width: 80%;
    height: 80%;
    max-width: var(--dl-size-size-maxwidth);
    border-color: var(--dl-color-gray-white);
    border-style: groove;
    border-width: 2px;
  }
  .showreel-root-class-name {
    height: auto;
  }
  @media(max-width: 1400px) {
    .showreel-container1 {
      padding: var(--dl-space-space-threeunits);
    }
  }
  @media(max-width: 991px) {
    .showreel-container1 {
      padding: var(--dl-space-space-twounits);
    }
  }
  @media(max-width: 767px) {
    .showreel-container1 {
      padding: var(--dl-space-space-unit);
    }
  }
  @media(max-width: 479px) {
    .showreel-container1 {
      padding: var(--dl-space-space-halfunit);
    }
  }
  
.virtural-game-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
.virtural-game-container01 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  flex-direction: column;
  background-size: contain;
  justify-content: center;
  background-image: url('https://iili.io/H78Yaob.png');
  background-position: center;
  background-attachment: fixed;
}
.virtural-game-container02 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.65);
}
.virtural-game-container03 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  flex-direction: column;
  background-size: cover;
  justify-content: center;
  /* background-image: url('https://iili.io/HEpn3rb.jpg'); */
  background-image: url('https://iili.io/HEpnD6g.jpg');
  background-repeat: no-repeat;
  background-position: top;
}
.virtural-game-container04 {
  flex: 0 0 auto;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.6);
  padding-top: 5%;
}
.virtural-game-container05 {
  flex: 0 0 auto;
  width: 100%;
  height: 100%;
  display: flex;
  max-width: 1600px;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.virtural-game-container06 {
  grid-gap: var(--dl-space-space-unit);
  gap: var(--dl-space-space-unit);
  flex: 0 0 auto;
  width: 100%;
  height: 50%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.virtural-game-container07 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.virtural-game-text {
  font-size: 145px;
  transform: rotate(-90deg);
  font-style: normal;
  transition: 0.3s;
  font-weight: 700;
  text-transform: uppercase;
}
.virtural-game-text:hover {
  color: var(--dl-color-colors-neongreen);
}
.virtural-game-container08 {
  width: auto;
  height: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.virtural-game-text01 {
  font-size: 115px;
  font-style: normal;
  transition: 0.3s;
  font-weight: 700;
}
.virtural-game-text01:hover {
  color: var(--dl-color-colors-neongreen);
}
.virtural-game-container09 {
  grid-gap: var(--dl-space-space-twounits);
  gap: var(--dl-space-space-twounits);
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.virtural-game-text02 {
  font-size: 115px;
  font-style: normal;
  transition: 0.3s;
  font-weight: 700;
}
.virtural-game-text02:hover {
  color: var(--dl-color-colors-neongreen);
}
.virtural-game-text03 {
  font-size: 115px;
  font-style: normal;
  font-weight: 700;
}
.virtural-game-text04 {
  font-size: 115px;
  font-style: normal;
  transition: 0.3s;
  font-weight: 700;
}
.virtural-game-text04:hover {
  color: var(--dl-color-colors-neongreen);
}
.virtural-game-text05 {
  font-size: 24px;
  font-style: normal;
  transition: 0.3s;
  font-weight: 400;
  background-size: cover;
  background-image: url('https://media.tenor.com/lveDtTE8Ek8AAAAM/rainbow-swirls-relaxing.gif');
}
.virtural-game-text05:hover {
  color: var(--dl-color-gray-white);
  font-style: normal;
  font-weight: 400;
}
.virtural-game-container10 {
  flex: 0 0 auto;
  width: 100%;
  height: 50%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.virtural-game-container11 {
  grid-gap: var(--dl-space-space-unit);
  gap: var(--dl-space-space-unit);
  width: 50%;
  height: 100%;
  display: flex;
  padding: var(--dl-space-space-twounits);
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.virtural-game-container12 {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.virtural-game-text06 {
  color: var(--dl-color-colors-orange);
  width: 100%;
  font-size: 52px;
  font-style: normal;
  font-weight: 700;
}
.virtural-game-text09 {
  width: 100%;
  font-size: 52px;
  font-style: normal;
  font-weight: 700;
}
.virtural-game-container13 {
  grid-gap: var(--dl-space-space-unit);
  gap: var(--dl-space-space-unit);
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.virtural-game-container14 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.virtural-game-text12 {
  font-size: 18px;
}
.virtural-game-container15 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.virtural-game-text13 {
  font-size: 18px;
}
.virtural-game-container16 {
  width: 50%;
  height: 100%;
  display: flex;
  padding: var(--dl-space-space-twounits);
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.virtural-game-container17 {
  grid-gap: var(--dl-space-space-unit);
  gap: var(--dl-space-space-unit);
  flex: 0 0 auto;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.virtural-game-container18 {
  grid-gap: var(--dl-space-space-unit);
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.virtural-game-container19 {
  grid-gap: var(--dl-space-space-unit);
  gap: var(--dl-space-space-unit);
  flex: 0 0 auto;
  width: 48%;
  height: 100%;
  display: flex;
  padding: var(--dl-space-space-twounits);
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-colors-orange);
  border-style: solid;
  border-width: 2px;
  border-radius: var(--dl-radius-radius-radius8);
  flex-direction: column;
  justify-content: flex-start;
  background-color: rgba(255, 171, 0, 0.2);
}
.virtural-game-container19:hover {
  box-shadow: 5px 5px 10px 0px #ffb400;
  background-color: rgba(0, 0, 0, 0.44);
}
.virtural-game-text14 {
  color: var(--dl-color-colors-orange);
  width: 100%;
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
}
.virtural-game-text15 {
  width: 100%;
  font-size: 20px;
  font-style: normal;
  font-weight: 300;
}
.virtural-game-container20 {
  grid-gap: var(--dl-space-space-unit);
  gap: var(--dl-space-space-unit);
  flex: 0 0 auto;
  width: 48%;
  height: 100%;
  display: flex;
  padding: var(--dl-space-space-twounits);
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-colors-orange);
  border-style: solid;
  border-width: 2px;
  border-radius: var(--dl-radius-radius-radius8);
  flex-direction: column;
  justify-content: flex-start;
  background-color: rgba(255, 171, 0, 0.2);
}
.virtural-game-container20:hover {
  box-shadow: 5px 5px 10px 0px #ffb400;
  background-color: rgba(0, 0, 0, 0.44);
}
.virtural-game-text16 {
  color: var(--dl-color-colors-orange);
  width: 100%;
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
}
.virtural-game-text17 {
  width: 100%;
  font-size: 20px;
  font-style: normal;
  font-weight: 300;
}
.virtural-game-container21 {
  grid-gap: var(--dl-space-space-unit);
  gap: var(--dl-space-space-unit);
  flex: 0 0 auto;
  width: 98%;
  height: auto;
  display: flex;
  padding: var(--dl-space-space-twounits);
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-colors-orange);
  border-style: solid;
  border-width: 2px;
  border-radius: var(--dl-radius-radius-radius8);
  flex-direction: column;
  justify-content: center;
  background-color: rgba(255, 171, 0, 0.2);
}
.virtural-game-container21:hover {
  box-shadow: 5px 5px 10px 0px #ffb400;
  background-color: rgba(0, 0, 0, 0.44);
}
.virtural-game-text18 {
  color: var(--dl-color-colors-orange);
  width: 100%;
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
}
.virtural-game-text19 {
  width: 100%;
  font-size: 20px;
  font-style: normal;
  font-weight: 300;
}
.virtural-game-container22 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  border-color: var(--dl-color-colors-neongreen);
  border-width: 1px;
  flex-direction: column;
  background-size: cover;
  justify-content: center;
  background-image: url('https://iili.io/H142Kn1.jpg');
  background-repeat: no-repeat;
  border-left-width: 0px;
  border-right-width: 0px;
  background-position: center;
  background-attachment: fixed;
}
.virtural-game-container23 {
  flex: 0 0 auto;
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.74);
}
.virtural-game-container24 {
  width: 100%;
  height: 100%;
  display: flex;
  max-width: 1600px;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.virtural-game-container25 {
  flex: 0 0 auto;
  width: 100%;
  height: 50%;
  display: flex;
  padding: var(--dl-space-space-twounits);
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.virtural-game-container26 {
  flex: 0 0 auto;
  width: 50%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.virtural-game-text20 {
  width: auto;
  font-size: 55px;
  font-style: normal;
  text-align: left;
  font-weight: 700;
}
.virtural-game-text21 {
  color: var(--dl-color-colors-pink);
  width: auto;
  font-size: 55px;
  font-style: normal;
  text-align: left;
  font-weight: 700;
}
.virtural-game-container27 {
  flex: 0 0 auto;
  width: 50%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.virtural-game-text25 {
  width: 100%;
  font-size: 22px;
  font-style: normal;
  text-align: left;
  font-weight: 300;
}
.virtural-game-text27 {
  font-style: normal;
  font-weight: 300;
}
.virtural-game-container28 {
  grid-gap: var(--dl-space-space-unit);
  gap: var(--dl-space-space-unit);
  width: 100%;
  height: 338px;
  display: flex;
  padding: var(--dl-space-space-twounits);
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-colors-pink);
  border-style: solid;
  border-width: 2px;
  flex-direction: column;
  justify-content: center;
  background-color: rgba(218, 15, 117, 0.2);
}
.virtural-game-container28:hover {
  background-color: rgba(0, 0, 0, 0.2);
}
.virtural-game-text30 {
  color: var(--dl-color-colors-pink);
  font-size: 55px;
  font-style: normal;
  font-weight: 600;
}
.virtural-game-container29 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  padding: var(--dl-space-space-halfunit);
  align-items: flex-start;
  border-color: var(--dl-color-colors-pink);
  border-width: 1px;
  border-radius: 50px;
  flex-direction: column;
}
.virtural-game-text31 {
  transition: 0.3s;
}
.virtural-game-text31:hover {
  color: var(--dl-color-colors-pink);
}
.virtural-game-container30 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-attachment: fixed;
}
.virtural-game-container31 {
  flex: 0 0 auto;
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.virtural-game-container32 {
  width: 100%;
  height: 100%;
  display: flex;
  max-width: 1600px;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.virtural-game-container33 {
  flex: 0 0 auto;
  width: auto;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.virtural-game-container34 {
  width: 50%;
  height: 100%;
  display: flex;
  padding: var(--dl-space-space-twounits);
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.virtural-game-container35 {
  grid-gap: var(--dl-space-space-unit);
  gap: var(--dl-space-space-unit);
  flex: 0 0 auto;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.virtural-game-container36 {
  grid-gap: var(--dl-space-space-unit);
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.virtural-game-container37 {
  grid-gap: var(--dl-space-space-unit);
  gap: var(--dl-space-space-unit);
  flex: 0 0 auto;
  width: 48%;
  height: 100%;
  display: flex;
  padding: var(--dl-space-space-twounits);
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-colors-cyan);
  border-style: solid;
  border-width: 2px;
  border-radius: var(--dl-radius-radius-radius8);
  flex-direction: column;
  justify-content: flex-start;
  background-color: rgba(0, 173, 181, 0.2);
}
.virtural-game-container37:hover {
  box-shadow: 5px 5px 10px 0px #00adb5;
  background-color: rgba(0, 0, 0, 0.44);
}
.virtural-game-text32 {
  color: var(--dl-color-colors-cyan);
  width: 100%;
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
}
.virtural-game-text33 {
  width: 100%;
  font-size: 20px;
  font-style: normal;
  font-weight: 300;
}
.virtural-game-container38 {
  grid-gap: var(--dl-space-space-unit);
  gap: var(--dl-space-space-unit);
  flex: 0 0 auto;
  width: 48%;
  height: 100%;
  display: flex;
  padding: var(--dl-space-space-twounits);
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-colors-cyan);
  border-style: solid;
  border-width: 2px;
  border-radius: var(--dl-radius-radius-radius8);
  flex-direction: column;
  justify-content: flex-start;
  background-color: rgba(0, 173, 181, 0.2);
}
.virtural-game-container38:hover {
  box-shadow: 5px 5px 10px 0px #00adb5;
  background-color: rgba(0, 0, 0, 0.44);
}
.virtural-game-text34 {
  color: var(--dl-color-colors-cyan);
  width: 100%;
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
}
.virtural-game-text35 {
  width: 100%;
  font-size: 20px;
  font-style: normal;
  font-weight: 300;
}
.virtural-game-container39 {
  grid-gap: var(--dl-space-space-unit);
  gap: var(--dl-space-space-unit);
  flex: 0 0 auto;
  width: 98%;
  height: auto;
  display: flex;
  padding: var(--dl-space-space-twounits);
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-colors-cyan);
  border-style: solid;
  border-width: 2px;
  border-radius: var(--dl-radius-radius-radius8);
  flex-direction: column;
  justify-content: center;
  background-color: rgba(0, 173, 181, 0.2);
}
.virtural-game-container39:hover {
  box-shadow: 5px 5px 10px 0px #00adb5;
  background-color: rgba(0, 0, 0, 0.44);
}
.virtural-game-text36 {
  color: var(--dl-color-colors-cyan);
  width: 100%;
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
}
.virtural-game-text37 {
  width: 100%;
  font-size: 20px;
  font-style: normal;
  font-weight: 300;
}
.virtural-game-container40 {
  grid-gap: var(--dl-space-space-unit);
  gap: var(--dl-space-space-unit);
  width: 50%;
  height: 100%;
  display: flex;
  padding: var(--dl-space-space-twounits);
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.virtural-game-container41 {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.virtural-game-text38 {
  color: var(--dl-color-colors-cyan);
  width: 100%;
  font-size: 52px;
  font-style: normal;
  font-weight: 700;
}
.virtural-game-text39 {
  width: 100%;
  font-size: 52px;
  font-style: normal;
  font-weight: 700;
}
.virtural-game-container42 {
  grid-gap: var(--dl-space-space-unit);
  gap: var(--dl-space-space-unit);
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.virtural-game-container43 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.virtural-game-text40 {
  font-size: 18px;
}
.virtural-game-container44 {
  grid-gap: var(--dl-space-space-threeunits);
  gap: var(--dl-space-space-threeunits);
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  padding: var(--dl-space-space-unit);
  position: relative;
  max-width: 1600px;
  align-items: center;
  margin-bottom: var(--dl-space-space-threeunits);
  flex-direction: column;
  justify-content: center;
}
.virtural-game-container45 {
  grid-gap: var(--dl-space-space-oneandhalfunits);
  gap: var(--dl-space-space-oneandhalfunits);
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.virtural-game-text41 {
  font-size: 75px;
  font-style: normal;
  text-align: left;
  transition: 0.3s;
  font-weight: 700;
  text-transform: capitalize;
}
.virtural-game-text41:hover {
  color: var(--dl-color-colors-pink);
}
.virtural-game-text42 {
  color: var(--dl-color-gray-white);
  width: 100%;
  font-size: 20px;
  align-self: center;
  font-style: normal;
  text-align: left;
  font-weight: 400;
}
.virtural-game-container46 {
  flex: 0 0 auto;
  height: auto;
  display: grid;
  max-width: 1600px;
  align-items: center;
  grid-row-gap: var(--dl-space-space-twounits);
  flex-direction: column;
  grid-column-gap: 110px;
  justify-content: center;
  grid-template-columns: auto auto auto auto;
}
.virtural-game-container47 {
  grid-gap: var(--dl-space-space-unit);
  gap: var(--dl-space-space-unit);
  flex: 0 0 auto;
  width: 300px;
  height: 50px;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
}
.virtural-game-container48 {
  width: 52px;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.virtural-game-icon {
  fill: var(--dl-color-colors-pink);
  width: 30px;
  height: 30px;
}
.virtural-game-text43 {
  height: auto;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
}
.virtural-game-container49 {
  grid-gap: var(--dl-space-space-unit);
  gap: var(--dl-space-space-unit);
  flex: 0 0 auto;
  width: 300px;
  height: 50px;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
}
.virtural-game-container50 {
  width: 52px;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.virtural-game-icon2 {
  fill: var(--dl-color-colors-neongreen);
  width: 30px;
  height: 30px;
}
.virtural-game-text44 {
  height: auto;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
}
.virtural-game-container51 {
  grid-gap: var(--dl-space-space-unit);
  gap: var(--dl-space-space-unit);
  flex: 0 0 auto;
  width: 300px;
  height: 50px;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
}
.virtural-game-container52 {
  width: 52px;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.virtural-game-icon4 {
  fill: var(--dl-color-colors-cyan);
  width: 30px;
  height: 30px;
}
.virtural-game-text45 {
  height: auto;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
}
@media(max-width: 1400px) {
  .virtural-game-text {
    font-size: 115px;
  }
  .virtural-game-text01 {
    font-size: 95px;
  }
  .virtural-game-container09 {
    grid-gap: 0;
    gap: 0;
    flex-direction: column;
  }
  .virtural-game-text02 {
    font-size: 95px;
  }
  .virtural-game-text03 {
    font-size: 95px;
  }
  .virtural-game-text04 {
    font-size: 95px;
  }
  .virtural-game-text05 {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .virtural-game-container46 {
    grid-template-columns: auto auto;
  }
}
@media(max-width: 991px) {
  .virtural-game-container03 {
    height: auto;
  }
  .virtural-game-container04 {
    height: auto;
  }
  .virtural-game-container05 {
    height: auto;
  }
  .virtural-game-container06 {
    height: 100vh;
  }
  .virtural-game-text05 {
    display: none;
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .virtural-game-container10 {
    height: auto;
    flex-direction: column;
  }
  .virtural-game-container11 {
    width: 100%;
  }
  .virtural-game-container16 {
    width: 100%;
  }
  .virtural-game-container17 {
    padding-top: var(--dl-space-space-unit);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-unit);
  }
  .virtural-game-container18 {
    padding: var(--dl-space-space-unit);
  }
  .virtural-game-container20 {
    padding: var(--dl-space-space-twounits);
  }
  .virtural-game-container22 {
    height: auto;
  }
  .virtural-game-container23 {
    height: auto;
  }
  .virtural-game-container24 {
    height: auto;
  }
  .virtural-game-container25 {
    flex-direction: column;
  }
  .virtural-game-container26 {
    width: 100%;
  }
  .virtural-game-container27 {
    width: 100%;
  }
  .virtural-game-container28 {
    border-left-width: 0px;
    border-right-width: 0px;
  }
  .virtural-game-container30 {
    height: auto;
  }
  .virtural-game-container33 {
    flex-direction: column;
  }
  .virtural-game-container34 {
    width: 100%;
  }
  .virtural-game-container40 {
    width: 100%;
  }
  .virtural-game-container46 {
    grid-template-columns: auto;
  }
}
@media(max-width: 767px) {
  .virtural-game-container06 {
    align-items: flex-start;
  }
  .virtural-game-container07 {
    width: 100%;
    flex-direction: column;
  }
  .virtural-game-text {
    font-size: 85px;
    transform: rotate(0);
    text-align: center;
  }
  .virtural-game-container08 {
    align-items: center;
    justify-content: center;
  }
  .virtural-game-text01 {
    font-size: 85px;
  }
  .virtural-game-text02 {
    width: 100%;
    font-size: 85px;
    text-align: center;
  }
  .virtural-game-text03 {
    font-size: 85px;
    font-style: normal;
    font-weight: 700;
  }
  .virtural-game-text04 {
    font-size: 85px;
  }
  .virtural-game-text06 {
    font-size: 45px;
  }
  .virtural-game-text09 {
    font-size: 45px;
  }
  .virtural-game-container18 {
    padding: 0px;
    flex-direction: column;
  }
  .virtural-game-container19 {
    width: 100%;
  }
  .virtural-game-container20 {
    width: 100%;
  }
  .virtural-game-container21 {
    width: 100%;
  }
  .virtural-game-container23 {
    padding-top: var(--dl-space-space-fourunits);
    padding-bottom: var(--dl-space-space-fourunits);
  }
  .virtural-game-text20 {
    font-size: 45px;
  }
  .virtural-game-text21 {
    font-size: 45px;
  }
  .virtural-game-container30 {
    padding-top: var(--dl-space-space-fourunits);
    padding-bottom: var(--dl-space-space-fourunits);
  }
  .virtural-game-container36 {
    flex-direction: column;
  }
  .virtural-game-container37 {
    width: 100%;
  }
  .virtural-game-container38 {
    width: 100%;
  }
  .virtural-game-container39 {
    width: 100%;
  }
  .virtural-game-text38 {
    font-size: 45px;
  }
  .virtural-game-text39 {
    font-size: 45px;
  }
  .virtural-game-text41 {
    font-size: 45px;
  }
}
@media(max-width: 479px) {
  .virtural-game-text {
    font-size: 75px;
  }
  .virtural-game-text01 {
    font-size: 75px;
  }
  .virtural-game-text02 {
    font-size: 75px;
  }
  .virtural-game-text03 {
    font-size: 75px;
  }
  .virtural-game-text04 {
    font-size: 75px;
  }
  .virtural-game-text06 {
    font-size: 35px;
  }
  .virtural-game-text09 {
    font-size: 35px;
  }
  .virtural-game-text14 {
    font-size: 30px;
  }
  .virtural-game-text15 {
    font-size: 16px;
  }
  .virtural-game-text16 {
    font-size: 30px;
  }
  .virtural-game-text17 {
    font-size: 16px;
  }
  .virtural-game-text18 {
    font-size: 30px;
  }
  .virtural-game-text19 {
    font-size: 16px;
  }
  .virtural-game-text20 {
    font-size: 35px;
  }
  .virtural-game-text21 {
    font-size: 35px;
  }
  .virtural-game-text25 {
    font-size: 18px;
  }
  .virtural-game-text30 {
    font-size: 35px;
  }
  .virtural-game-text32 {
    font-size: 30px;
  }
  .virtural-game-text33 {
    font-size: 16px;
  }
  .virtural-game-text34 {
    font-size: 30px;
  }
  .virtural-game-text35 {
    font-size: 16px;
  }
  .virtural-game-text36 {
    font-size: 30px;
  }
  .virtural-game-text37 {
    font-size: 16px;
  }
  .virtural-game-text38 {
    font-size: 35px;
  }
  .virtural-game-text39 {
    font-size: 35px;
  }
  .virtural-game-text41 {
    font-size: 35px;
  }
  .virtural-game-text42 {
    font-size: 16px;
  }
}
@media(max-width: 375px) {
  .virtural-game-container16 {
    padding: var(--dl-space-space-halfunit);
  }
  .virtural-game-container34 {
    padding: var(--dl-space-space-halfunit);
  }
}

.card-container {
    width: 350px;
    height: 350px;
    display: flex !important;
    /* overflow: hidden; */
    position: relative;
    align-items: flex-start;
    border-color: var(--dl-color-gray-white);
    border-style: solid;
    border-width: 2px;
    flex-direction: column;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    transition: 0.3s ease-in;
    /* background-image: url('https://images.unsplash.com/photo-1680079527283-0ff93b782407?ixid=Mnw5MTMyMXwwfDF8YWxsfDEzfHx8fHx8Mnx8MTY4MDE1Nzk0NA&ixlib=rb-4.0.3&h=1500'); */
  }
  .hoverDiv{
    display: flex;
    transition: 0.3s ease-in;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: flex-end;
    color: transparent;
    font-weight: 700;
    padding: 20px;
  }
  .card-container:hover > .hoverDiv{
    /* display: flex; */
    transition: 0.3s ease-out;
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
  }

  .card-container:hover > .icon{
    background-image: url('/playground_assets/Blog/google-play.png');
    transition: 0.3s ease-out;
  }
  .icon{
    /* background-color: black; */
    width: 100px;
    height: 100px;
    /* background-image: url('/playground_assets/Blog/google-play.png'); */
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    position: absolute;
    margin: auto;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    z-index: 5;
    transition: 0.3s ease-in;

  }
  @media(max-width: 1400px) {
    .card-container {
      background-size: cover;
      width: 25vw;
      height: 35vh;
    }
  }
  @media(max-width: 991px) {
    .card-container {
      height: 25vh;
    }
  }
  @media(max-width: 767px) {
    .card-container {
      background-size: contain;
      width: 250px;
      height: 250px;
    }
  }
  
.rope-container {
    width: 100%;
    /* height: 450px; */
    height: 380px;
    display: flex;
    position: relative;
    align-items: center;
    flex-direction: row;
    background-size: contain;
    justify-content: center;
    background-image: url(/playground_assets/Blog/rope1.png);
    background-repeat: no-repeat;
    background-position: top;
  }
  
  .rope-container1 {
    grid-gap: 100px;
    gap: 100px;
    width: 100%;
    display: grid;
    max-width: 1400px;
    align-self: center;
    align-items: center;
    flex-direction: row;
    justify-content: center;
    grid-template-columns: auto auto auto;
    margin-bottom: 30px;
  }
  .rope-container2 {
    flex: 0 0 auto;
    width: 350px;
    height: 350px;
    rotate: 4deg;
    display: flex;
    align-items: center;
    border-color: var(--dl-color-gray-white);
    border-style: solid;
    border-width: 2px;
    flex-direction: column;
    background-size: cover;
    justify-content: center;
    background-image: url('https://images.unsplash.com/photo-1680009084520-42528e128a0e?ixid=Mnw5MTMyMXwwfDF8YWxsfDJ8fHx8fHwyfHwxNjgwMDg0MzYw&ixlib=rb-4.0.3&h=1500');
  }
  .rope-container3 {
    flex: 0 0 auto;
    width: 350px;
    height: 350px;
    display: flex;
    margin-top: 30px;
    align-items: center;
    border-color: var(--dl-color-gray-white);
    border-style: solid;
    border-width: 2px;
    flex-direction: column;
    background-size: cover;
    justify-content: center;
    background-image: url('https://images.unsplash.com/photo-1680009084520-42528e128a0e?ixid=Mnw5MTMyMXwwfDF8YWxsfDJ8fHx8fHwyfHwxNjgwMDg0MzYw&ixlib=rb-4.0.3&h=1500');
  }
  .rope-container4 {
    flex: 0 0 auto;
    width: 350px;
    height: 350px;
    rotate: -4deg;
    display: flex;
    align-items: center;
    border-color: var(--dl-color-gray-white);
    border-style: solid;
    border-width: 2px;
    flex-direction: column;
    background-size: cover;
    justify-content: center;
    background-image: url('https://images.unsplash.com/photo-1680009084520-42528e128a0e?ixid=Mnw5MTMyMXwwfDF8YWxsfDJ8fHx8fHwyfHwxNjgwMDg0MzYw&ixlib=rb-4.0.3&h=1500');
  }
  .rope-container5 {
    grid-gap: 100px;
    gap: 100px;
    width: 100%;
    display: none;
    max-width: 1400px;
    align-self: center;
    align-items: center;
    flex-direction: row;
    justify-content: center;
    grid-template-columns: auto auto auto;
  }
  .rope-container6 {
    flex: 0 0 auto;
    width: 350px;
    height: 350px;
    rotate: 4deg;
    display: flex;
    align-items: center;
    border-color: var(--dl-color-gray-white);
    border-style: solid;
    border-width: 2px;
    flex-direction: column;
    background-size: cover;
    justify-content: center;
    background-image: url('https://images.unsplash.com/photo-1680009084520-42528e128a0e?ixid=Mnw5MTMyMXwwfDF8YWxsfDJ8fHx8fHwyfHwxNjgwMDg0MzYw&ixlib=rb-4.0.3&h=1500');
  }
  .rope-container7 {
    grid-gap: 100px;
    gap: 100px;
    width: 100%;
    display: none;
    max-width: 1400px;
    align-self: center;
    align-items: center;
    flex-direction: row;
    justify-content: center;
    grid-template-columns: auto auto auto;
  }
  .rope-container8 {
    flex: 0 0 auto;
    width: 350px;
    height: 350px;
    rotate: 4deg;
    display: flex;
    align-items: center;
    border-color: var(--dl-color-gray-white);
    border-style: solid;
    border-width: 2px;
    flex-direction: column;
    background-size: cover;
    justify-content: center;
    background-image: url('https://images.unsplash.com/photo-1680009084520-42528e128a0e?ixid=Mnw5MTMyMXwwfDF8YWxsfDJ8fHx8fHwyfHwxNjgwMDg0MzYw&ixlib=rb-4.0.3&h=1500');
  }
  @media(max-width: 1400px) {
    .rope-container1 {
      height: auto;
      align-self: flex-start;
    }
    .rope-container2 {
      width: 25vw;
      height: 35vh;
    }
    .rope-container3 {
      width: 25vw;
      height: 35vh;
    }
    .rope-container4 {
      width: 25vw;
      height: 35vh;
    }
    .rope-container5 {
      height: auto;
      align-self: flex-start;
    }
    .rope-container6 {
      width: 25vw;
      height: 35vh;
    }
    .rope-container7 {
      height: auto;
      align-self: flex-start;
    }
    .rope-container8 {
      width: 25vw;
      height: 35vh;
    }
  }
  @media(max-width: 991px) {
    .rope-container1 {
      grid-gap: 50px;
      gap: 50px;
      height: auto;
      align-self: flex-start;
    }
    .rope-container2 {
      width: 25vw;
      height: 25vh;
    }
    .rope-container3 {
      width: 25vw;
      height: 25vh;
    }
    .rope-container4 {
      width: 25vw;
      height: 25vh;
    }
    .rope-container5 {
      grid-gap: 50px;
      gap: 50px;
      height: auto;
      align-self: flex-start;
    }
    .rope-container6 {
      width: 25vw;
      height: 25vh;
    }
    .rope-container7 {
      grid-gap: 50px;
      gap: 50px;
      height: auto;
      align-self: flex-start;
    }
    .rope-container8 {
      width: 25vw;
      height: 25vh;
    }
  }
  @media(max-width: 767px) {
    .rope-container {
      grid-gap: 20px;
      gap: 20px;
      height: auto;
      flex-direction: column;
      background-position: top;
    }
    .rope-container1 {
      grid-template-columns: auto;
      padding: 10px;
    }
    .rope-container2 {
      width: 250px;
      height: 250px;
      rotate: 0deg;
    }
    .rope-container3 {
      width: 250px;
      height: 250px;
      display: none;
    }
    .rope-container4 {
      width: 250px;
      height: 250px;
      rotate: 0deg;
      display: none;
    }
    .rope-container5 {
      display: grid;
      grid-template-columns: auto;
    }
    .rope-container6 {
      width: 250px;
      height: 250px;
      rotate: 0deg;
    }
    .rope-container7 {
      display: grid;
      grid-template-columns: auto;
    }
    .rope-container8 {
      width: 250px;
      height: 250px;
      rotate: 0deg;
    }
  }
  
.s2 {
    display: none;
}

.s3 {
    display: none;
}

.slider {
    display: none;
    height: 100vh;
    /* max-width: 1400px; */
    width: 100%;
    /* background-color: black; */
    background-image: url('/playground_assets/Blog/bg11.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    /* padding-top: 100px; */
}

.opacity {
    width: 100%;
    height: 100%;
    /* background-color: black; */
    background-color: rgba(0, 0, 0, 0.4);
    background-attachment: fixed;
    /* background-image: url('/playground_assets/Blog/bg13.png'); */
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    padding-top: 100px;
}

.sliderInner {
    /* display: none !important; */
    /* border: 2px solid white; */
    width: 100%;
    height: 90%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
}

.showcase {
    height: auto;
    width: 100%;
    /* background-color: black; */
    /* background-image: url('/playground_assets/Blog/bg15.png'); */
    background-image: url('/playground_assets/Blog/bg11.png');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
    /* padding-top: 100px; */
}

.curtain {
    height: auto;
    width: 100%;
    /* background-color: black; */
    /* background-image: url('/playground_assets/Blog/bg13.png'); */
    background-color: rgba(0, 0, 0, 0.4);
    background-position: center top;
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
    /* padding-top: 100px; */
    padding-top: 160px;
    padding-bottom: 40px;
}

.mobile {
    /* display: flex; */
    position: absolute;
    background-image: url('/playground_assets/Blog/Portfolio_All_Screen/Mobile-Screen.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    width: 280px;
    height: 532px;
    /* border: 2px solid red; */
    margin: auto auto;
    /* top: 0; */
    /* bottom: 0; */
    left: 0;
    right: 0;
    z-index: 1;
}

/* ========================= */
.swiper {
    max-width: 1400px;
    width: 100%;
    height: 417px;
    /* border: 2px solid purple; */
    margin-left: auto;
    margin-right: auto;

}

.swiper-slide {
    /* border: 2px solid red; */
    text-align: center;
    font-size: 18px;
    /* background: #000000; */
    /* color: black; */
    /* border-radius: 40px; */
    overflow: hidden;
    /* Center slide text vertically */
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: grab;
    /* width: 280px !important; */
    /* height: 490px; */
}

.swiper-slide:active {
    cursor: grabbing;
}

.swiper-slide img {
    /* border: 2px solid green; */
    display: block;
    width: 230px;
    height: 100%;
    object-fit: fill;
}



/* ========================= */
.end {
    width: 100%;
    height: 20vh;
    background-image: url('/playground_assets/Blog/bg15.png');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
}

.end1 {
    width: 100%;
    background-image: url('/playground_assets/Blog/bg13.png');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
}

@media(max-width: 1400px) {
    .mobile {
        /* display: flex; */
        position: absolute;
        background-image: url('/playground_assets/Blog/Portfolio_All_Screen/Mobile-Screen.png');
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        width: 270px;
        height: 380px;
        /* border: 2px solid red; */
        margin: auto auto;
        /* top: 0; */
        /* bottom: 0; */
        left: 0;
        right: 0;
        z-index: 1;
    }

    .swiper {
        /* max-width: 1400px; */
        width: 100%;
        height: 304px;
        /* border: 2px solid purple; */
    }

    .swiper-slide img {
        /* border: 2px solid green; */
        width: 171px;
        height: 100%;
    }

    .swiper-slide {
        /* border: 2px solid red; */
        width: 172px;
    }
}

@media(max-width: 767px) {
    .slider {
        display: flex;
    }
}

@media(max-width: 991px) {
    .s1 {
        display: none;
    }

    .s2 {
        display: initial;
    }
}

@media(max-width: 480px) {
    .s2 {
        display: none;
    }

    .s3 {
        display: initial;
    }
}

/* Popup overlay background */
.popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #000;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    overflow: hidden;
    /* Prevent scrollbars from appearing */
}

/* Popup content */
.popup-content {
    position: relative;
    max-width: 100%;
    max-height: 100%;
    background-color: #000;
    padding: 10px;
    border-radius: 6px;
}

/* Close button styling */
.close-btn {
    position: fixed;
    top: 20px;
    right: 20px;
    background-color: #ffffff;
    color: #ff0000;
    border: none;
    padding: 10px;
    cursor: pointer;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    font-size: 20px;
}

.close-btn:hover {
    background-color: red;
    color: #ffffff;
}

.popup-content img {
    width: 100%;
    height: auto;
    object-fit: contain;
    /* This ensures the image maintains aspect ratio */
    transform: scale(1.3);
    /* Scale the image by 20% */
    transform-origin: center;
    /* Scale from the center */
    border: 3px solid white;
    /* Add white border */
    border-radius: 12px;
    /* Add border radius for rounded corners */
}


.about-us-container35 {
    grid-gap: var(--dl-space-space-unit);
    gap: var(--dl-space-space-unit);
    flex: 0 0 auto;
    width: 100%;
    /* height: 100vh; */
    height: auto;
    display: flex;
    padding: var(--dl-space-space-unit);
    max-width: 1600px;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}

.about-us-container54 {
    width: 279px;
    height: 398px;
    display: flex;
    overflow: hidden;
    align-items: flex-start;
    border-radius: var(--dl-radius-radius-radius8);
    flex-direction: column;
}

.about-us-container37 {
    flex: 0 0 auto;
    width: 100%;
    height: auto;
    display: grid;
    padding: var(--dl-space-space-twounits);
    align-items: center;
    grid-row-gap: var(--dl-space-space-unit);
    flex-direction: column;
    grid-column-gap: var(--dl-space-space-unit);
    justify-content: center;
    grid-template-columns: auto auto auto auto;
}

@media(max-width: 1400px) {
    .about-us-container35 {
        grid-gap: var(--dl-space-space-unit);
        gap: var(--dl-space-space-unit);
        height: auto;
        flex-direction: column;
    }

    .about-us-container37 {
        /* padding-bottom: 300px; */
        grid-template-columns: auto auto auto;
    }
}

@media(max-width: 767px) {

    .about-us-container37 {
        grid-template-columns: auto;
    }
}

@media(max-width: 991px) {

    .about-us-container37 {
        grid-template-columns: auto auto;
    }
}

@media(max-width: 479px) {
    .about-us-container54 {
        width: 100%;
        height: 550px;
    }
}

@media(max-width: 375px) {
    .about-us-container54 {
        height: 400px;
    }
}

.ourwork {
    flex: 0 0 auto;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    background-size: cover;
    /* background-image: url("/playground_assets/white-house-1500w.png"); */
    background-repeat: no-repeat;
    background-position: center;
}

.showcaseart {
    height: auto;
    width: 100%;
    /* background-color: black; */
    /* background-image: url('/playground_assets/Blog/bg15.png'); */
    background-image: url('/playground_assets/Blog/wall_2.png');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
    /* padding-top: 100px; */
}

.header {
    text-align: center;
    padding: 32px;
    background-color: #333;
    color: white;
}

.gallery {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    grid-gap: 20px;
    gap: 20px;
    padding: 20px;
}

.gallery img {
    cursor: pointer;
    width: auto;
    /* Allow images to maintain their original width */
    height: auto;
    /* Maintain the original height to preserve the aspect ratio */
    max-width: 100%;
    /* Prevent images from overflowing */
    object-fit: contain;
    border-radius: 8px;
}

/* @media screen and (max-width: 1024px) {
    .gallery {
      justify-content: space-between;
    }
  }

  @media screen and (max-width: 768px) {
    .gallery {
      justify-content: space-between;
    }
  } */

/* Responsive layout for extra small screen (phones in portrait mode) */
@media screen and (max-width: 480px) {
    .gallery {
        justify-content: center;
    }
}
.elem1 {
  width: 100%;
  height: 100vh;
  background-image: url("/playground_assets/Blog/1.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  /* margin-bottom: 100px; */
}

.transp {
  width: 100%;
  height: 100%;
}

.curtain {
  position: relative;
  /* background-image: url("/playground_assets/Blog/21.png"); */
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  /* z-index: 5; */
  width: 100%;
  height: 100%;
}

.table1 {
  width: 100%;
  height: 100%;
  /* background-image: url("/playground_assets/Blog/table (1).png"); */
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  /* overflow: hidden; */
  /* border: 2px solid white; */
  grid-gap: 20px;
  gap: 20px;
}

.table2 {
  height: auto;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  /* border: 2px solid white; */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  grid-gap: 20px;
  gap: 20px;
  padding: 60px;
  /* background-image: url("https://img.freepik.com/free-photo/empty-blackboard_53876-16241.jpg?w=1380&t=st=1681196602~exp=1681197202~hmac=6aeb2712a281c1344c5be103fe1670d558988927e484c4b5a6952202c3203893"); */
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}
/* ========================= */
.swiper {
    /* max-width: 1400px; */
    width: 100%;
    height: 410px;
    /* border: 2px solid purple; */
    margin-left: auto;
    margin-right: auto;

}

.swiper-slide {
    /* border: 2px solid red; */
    text-align: center;
    font-size: 18px;
    /* background: #000000; */
    /* color: black; */
    /* border-radius: 40px; */
    overflow: hidden;
    /* Center slide text vertically */
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: grab;
    /* width: 280px !important; */
    /* height: 490px; */
}

.swiper-slide:active {
    cursor: grabbing;
}

.swiper-slide img {
    /* border: 2px solid green; */
    display: block;
    width: 100%;
    height: 100%;
    object-fit: fill;
}

.swiper1{
    display: none;
}

.mySwiper3{
    display: none;
}
.mySwiper4{
    display: none;
}
/* ========================= */

@media(max-width:1300px) {
    .swiper{
        height: 350px;
    }
}

@media (max-width: 1200px){
    .mySwiper2{
        display: none;
    }
    .mySwiper3{
        display: flex;
    }
    .swiper{
        height: 550px;
    }
}

@media(max-width:950px){
    .swiper{
        height: 420px;
    }
}

@media(max-width:840px){
    .swiper{
        height: 310px;
    }
}
@media(max-width:726px){
    .mySwiper3{
        display: none;
    }
    .mySwiper4{
        display: flex;
        
    }
    .swiper{
        height: 100%;
        width: 50%;
    }
}
@media(max-width:480px){
    .swiper{
        width: 280px;
    }
}
*,
*::after,
*::before {
  box-sizing: border-box;
  margin: 0;
}

body {
  font-family: 'Montserrat', sans-serif;
}

button,
input {
  font-family: 'Montserrat', sans-serif;
}

.container {
  max-width: 1140px;
  width: 95%;
  margin: 0 auto;
  padding: 1rem 0;
}

.chip {
  font-size: 0.7rem;
  background: linear-gradient(to right, #6190e8, #a7bfe8);
  color: #fff;
  padding: 0.3rem 0.5rem;
  font-size: 14px;
  font-weight: bold;
  border-radius: 5px;
  /* height: 50px; */
  /* width: 250px; */
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  text-transform: capitalize;
}

/* Container for the divs */
.container {
  display: flex;
  flex-wrap: wrap; /* Allow the divs to wrap to the next row if needed */
  grid-gap: 20px;
  gap: 20px; /* Space between the divs */
  justify-content: space-between; /* Evenly distribute the divs */
}

/* Style for each div */
.box {
  width: calc(50% - 10px); /* Each div takes 50% width minus the gap */
  /* background-color: lightblue; */
  padding: 20px;
  text-align: center;
  /* border: 1px solid #ccc; */
  border-radius: 8px;
}

/* Adjust for mobile screens */
@media (max-width: 768px) {
  .box {
    width: 100%; /* On mobile, each div takes up 100% of the width */
  }
}

.emptyList-wrap {
  display: flex;
  justify-content: center;
}

.emptyList-wrap img {
  max-width: 250px;
  width: 100%;
}

.blogItem-wrap-skeleten {
  margin-top: 60px;
  margin-left: 5px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  grid-gap: 15px;
  gap: 15px;
}

.circle-skeletons {
  display: flex;
  grid-gap: 10px;
  gap: 10px;
}
.blog-wrap {
  max-width: 900px;
  margin: 0 auto;
}

.blog-goBack {
  text-decoration: none;
  font-size: 0.8rem;
  color: #a9a9a9;
  font-weight: 500;
  margin-bottom: 2rem;
  display: block;
}
.blog-wrap header {
  text-align: center;
}

.blog-date {
  font-size: 0.8rem;
  color: #a9a9a9;
  font-weight: 500;
}

.blog-wrap img {
  width: 100%;
}

.blog-subCategory {
  display: flex;
  justify-content: center;
}
.blog-subCategory > div {
  margin: 1rem;
}
.blog-desc {
  padding: 1rem;
  margin-top: 1.5rem;
}

/*Comments Section*/
.main {
  margin: auto;
  width: 80%;
  border: 1px solid grey;
  /* margin: 21px; */
  padding: 21px;
  border-radius: 5px;
}
* {
  box-sizing: border-box;
}

.full {
  float: left;
  width: 100%;
  padding: 10px;
}
/* Create two equal columns that floats next to each other */
.column {
  float: left;
  width: 33%;
  padding: 10px;
}

/* Clear floats after the columns */
.row:after {
  content: "";
  display: table;
  clear: both;
}

/* Responsive layout - makes the two columns stack on top of each other instead of next to each other */
@media screen and (max-width: 600px) {
  .column {
    width: 100%;
  }
}

input[type=text], textarea {
  width: 100%;
  padding: 12px 20px;
  margin: 0 0;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}
input[type=number] {
  width: 100%;
  padding: 12px 20px;
  margin: 0 0;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

.error {
  color: red;
  font-size: .875em;
  box-sizing: border-box;
}

.savebutton {
  background-color: blue; /* Green */
  border: none;
  color: white;
  padding: 12px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 14px;
}
.savebutton:hover,
.savebutton:focus,
.savebutton:active {
  cursor: pointer;
  /* color: */
  background-color: rgb(77, 66, 221);
}

button {
  -webkit-appearance: none;
  appearance: none;
  font-size: 14px;
  padding: 4px 8px;
  color: rgba(0, 0, 0, 0.85);
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 4px;
}
button:hover,
button:focus,
button:active {
  cursor: pointer;
  background-color: #ecf0f1;
}
.comment-thread {
  /* width: fit-content; */
  max-width: 100%;
  margin: auto;
  padding: 0 30px;
  background-color: #e6eefa;
  border: 1px solid transparent; /* Removes margin collapse */
}
.m-0 {
  margin: 0;
}
.sr-only {
  position: absolute;
  left: -10000px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
}

/* Comment */

.comment {
  position: relative;
  margin: 20px auto;
}
.comment-heading {
  display: flex;
  align-items: center;
  height: 50px;
  font-size: 14px;
}
.comment-voting {
  width: 20px;
  height: 32px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 4px;
}
.comment-voting button {
  display: block;
  width: 100%;
  height: 50%;
  padding: 0;
  border: 0;
  font-size: 10px;
}
.comment-info {
  color: rgba(0, 0, 0, 0.5);
  margin-left: 10px;
}
.comment-author {
  color: rgba(0, 0, 0, 0.85);
  font-weight: bold;
  text-decoration: none;
}
.comment-author:hover {
  text-decoration: underline;
}
.replies {
  margin-left: 20px;
}

/* Adjustments for the comment border links */

.comment-border-link {
  display: block;
  position: absolute;
  top: 50px;
  left: 0;
  width: 12px;
  height: calc(100% - 50px);
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  background-color: rgba(0, 0, 0, 0.1);
  background-clip: padding-box;
}
.comment-border-link:hover {
  background-color: rgba(0, 0, 0, 0.3);
}
.comment-body {
  padding: 0 20px;
  padding-left: 28px;
}
.replies {
  margin-left: 28px;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  display: none;
}

.emptyList-wrap {
  display: flex;
  justify-content: center;
}
.emptyList-wrap img {
  max-width: 250px;
  width: 100%;
}
/* Container for the divs */
.container {
  display: flex;
  flex-wrap: wrap; /* Allow the divs to wrap to the next row if needed */
  grid-gap: 20px;
  gap: 20px; /* Space between the divs */
  justify-content: space-between; /* Evenly distribute the divs */
}

/* Style for each div */
.box {
  width: calc(50% - 10px); /* Each div takes 50% width minus the gap */
  /* background-color: lightblue; */
  padding: 20px;
  text-align: center;
  /* border: 1px solid #ccc; */
  border-radius: 8px;
}

/* Adjust for mobile screens */
@media (max-width: 768px) {
  .box {
    width: 100%; /* On mobile, each div takes up 100% of the width */
  }
}

.emptyList-wrap {
  display: flex;
  justify-content: center;
}

.emptyList-wrap img {
  max-width: 250px;
  width: 100%;
}

.blogItem-wrap-skeleten {
  margin-top: 60px;
  margin-left: 5px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  grid-gap: 15px;
  gap: 15px;
}

.circle-skeletons {
  display: flex;
  grid-gap: 10px;
  gap: 10px;
}
.blogItem-wrap {
  display: flex;
  flex-direction: column;
  /* display:block;
  width:100%;
  height:700px; */
}

.blogItem-cover {
  width: 100%;
  /* height: 100%; */
  object-fit: cover;
  border-radius: 20px;
  margin-bottom: 1.5rem;
}

.blogItem-wrap h3 {
  margin: 0.5rem 0 1rem 0;
  flex: 1 1;
}

.blogItem-desc {
  position: relative;
  max-height: 50px;
  overflow: hidden;
  padding-right: 0.6rem;
  font-size: 0.8rem;
  color: #fff;
}

.blogItem-desc::before {
  position: absolute;
  content: '...';
  bottom: 0;
  right: 0;
}

.blogItem-wrap footer {
  display: flex;
  align-items: center;
  margin-top: 1rem;
  justify-content: space-between;
}

.blogItem-link {
  text-decoration: none;
  color: inherit;
}

.blogItem-author {
  display: flex;
  align-items: center;
}

.blogItem-author img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 0.5rem;
}

.blogItem-author p {
  font-size: 0.6rem;
  color: #a9a9a9;
  font-weight: 600;
}
.blogList-wrap {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 3rem;
}

@media (max-width: 768px) {
  .blogList-wrap {
    grid-template-columns: repeat(1, 1fr);
  }
}

@media (max-width: 600px) {
  .blogList-wrap {
    grid-template-columns: repeat(1, 1fr);
  }
}

.home-header {
  text-align: center;
}

.home-header h2 {
  color: #0080ff;
  font-size: 2rem;
}

.home-header h1 {
  color: #0080ff;
  font-size: 2rem;
  /* font-size: 3rem;
  color: #0f52ba;
  margin-bottom: 1rem; */
}

.home-header h1 span {
  color: #b0c4de;
}

.home-header p {
  color: #a9a9a9;
  font-weight: 500;
}

.searchBar-wrap {
  background-color: #f0f0f0;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  /* margin: 2.5rem auto 4rem auto; */
  margin: 1.5rem auto 3rem auto;
  /* padding: 0.5rem; */
  padding: 0px;
  border-radius: 5px;
}

.searchBar-wrap form {
  display: flex;
  align-items: center;
}

.searchBar-wrap input {
  background-color: #f0f0f0;
  outline: none;
  border: none;
}

.searchBar-wrap span {
  padding-right: 0.5rem;
  cursor: pointer;
}

.searchBar-wrap button {
  outline: none;
  border: none;
  padding: 0.3rem 1rem;
  border-radius: 5px;
  background-color: #0f52ba;
  color: #fff;
}

.element1{
    /* border: 2px solid white; */
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: url('https://i.pinimg.com/originals/c9/ef/4d/c9ef4d8eea3b9ae322c0163480be7c7c.gif');
    background-size: contain;
    /* background-repeat: no-repeat; */
}
.element2{
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.6);
    width: 100%;
    height: 100%;
}
.h1under{
    font-size: 8vw;
    font-weight: 700;
}
